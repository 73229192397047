import React from 'react'
import { ImageBackground, Text, View } from 'react-native'
import { Colors, TextStyles, Layout } from '../constants'
import { VenueStoriesConsumer } from '../context/VenueStoriesContext'
import { VenueProfileConsumer } from '../context/VenueProfileContext'
import { LinearGradient } from 'expo-linear-gradient'

import moment from 'moment'

export default class VenueRecentPostItem extends React.Component {
    state = {
        selectedVideoKey: '',
    }

    componentDidMount = async () => {
        await this.setState({
            selectedVideoKey: this.props.storyData.length - 1, // <-- Preview of most recent story
        })
    }

    render() {
        const playlist = this.props.storyData

        const selectedVideo =
            playlist[
                (playlist.length + this.state.selectedVideoKey) %
                    playlist.length
            ]

        const { videoThumbnail } = selectedVideo

        //console.log('selectedVideoKey', this.state.selectedVideoKey)

        const timestampLabel = moment(
            this.props.storyData[0].timestamp,
            'x'
        ).calendar(null, {
            sameDay: '[Today] h:mm A',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday] h:mm A',
            lastWeek: '[Last] dddd',
            sameElse: 'M/DD/YYYY',
        })

        return (
            <VenueProfileConsumer>
                {profileContext => (
                    <View
                        style={{ flex: 1 }}
                        ref={ref => {
                            this.profileContext = profileContext
                        }}
                    >
                        <VenueStoriesConsumer>
                            {context => (
                                <View
                                    style={{
                                        flex: 1,
                                    }}
                                    ref={ref => {
                                        this.context = context
                                    }}
                                >
                                    <View style={Layout.mockHeroContainer}>
                                        <ImageBackground
                                            source={{ uri: videoThumbnail }}
                                            style={Layout.mockHeroPoster}
                                            resizeMode={'cover'}
                                        >
                                            <LinearGradient
                                                colors={[
                                                    'transparent',
                                                    'rgba(0,0,0,.1)',
                                                    'rgba(0,0,0,.4)',
                                                ]}
                                                style={{ flex: 1 }}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        zIndex: 100,
                                                        padding:
                                                            Layout.paddingLarge,
                                                        justifyContent:
                                                            'flex-end',
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.CaptionInverse,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                            },
                                                        ]}
                                                        numberOfLines={1}
                                                    >
                                                        {timestampLabel}
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.OverlineInverse,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                            },
                                                        ]}
                                                    >
                                                        {profileContext.city},{' '}
                                                        {profileContext.state}
                                                    </Text>
                                                    {/* <Text
                                                        style={[
                                                            TextStyles.CaptionInverse,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                            },
                                                        ]}
                                                        numberOfLines={1}
                                                    >
                                                        {timestampLabel}
                                                    </Text> */}
                                                </View>
                                            </LinearGradient>
                                        </ImageBackground>
                                    </View>
                                </View>
                            )}
                        </VenueStoriesConsumer>
                    </View>
                )}
            </VenueProfileConsumer>
        )
    }
}
