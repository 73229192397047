import React from 'react'
import { ScrollView, Text, TextInput, View } from 'react-native'
import { Layout, Colors, Buttons, TextStyles, Icons } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import { WindowResizeConsumer } from '../context/WindowResizeContext'
import Button from './Button'
import ButtonIcon from './ButtonIcon'
import FormIcon from './FormIcon'

export default class RequestInfoComponent extends React.Component {
    state = {
        fullNameFocused: false,
        emailFocused: false,
        success: false,
    }

    onPressSubmitEmail = () => {
        const placeId = this.props.placeId
        this.context.onSubmitRequestForm(placeId)
        this.setState({ success: true })
    }

    renderInputFullName = ({ sectionTitle, fullName, fullNameValid }) => {
        return (
            <View
                style={{
                    width: '100%',
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderBottomColor: this.state.fullNameFocused
                            ? Colors.tintColor
                            : Colors.disabled,
                        borderBottomWidth: 1,
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                width: '100%',
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                //outline: 'none',
                                // borderBottomColor: this.state.fullNameFocused
                                //     ? Colors.tintColor
                                //     : Colors.disabled,
                                //     borderBottomWidth: 1,

                                paddingBottom: 16,
                            },
                        ]}
                        value={fullName}
                        placeholder="Full name"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.tintColor}
                        onChangeText={async text => {
                            await this.context.setFullName(text)
                            this.context.validateFullName(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: true,
                                emailFocused: false,
                            })
                        }}
                        onEndEditing={text => {
                            this.context.validateFullName(text)
                        }}
                        maxLength={56}
                        keyboardType="default"
                        autoCapitalize="words"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                        autoFocus={true}
                    />
                    <FormIcon
                        iconName={'check'}
                        iconSize={Icons.small}
                        disabled={!fullName.length || !fullNameValid}
                    />
                </View>
            </View>
        )
    }

    renderInputEmail = ({ emailNew, emailNewValid, buttonIcon }) => {
        return (
            <View
                style={{
                    //flexGrow: 0,
                    width: '100%',
                    //paddingTop:  Layout.isMediumDevice? 0 : this.state.success? 0:Layout.paddingXL, // visually balance and offset the email error notification
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Caption,
                        {
                            // display: Layout.isMediumDevice
                            //     ? this.state.success
                            //         ? 'flex'
                            //         : 'none'
                            //     : 'flex',
                            display: this.state.success ? 'flex' : 'none',
                            flex: 1,
                            paddingVertical: 16,
                            color: this.state.success
                                ? Colors.tintColor
                                : 'transparent',
                        },
                    ]}
                >
                    So glad you joined us.
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottomColor: this.state.emailFocused
                            ? Colors.tintColor
                            : Colors.disabled,
                        borderBottomWidth: 1,
                    }}
                >
                    <TextInput
                        style={[
                            TextStyles.Paragraph,
                            {
                                width: '100%',
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                //outline: 'none',
                                paddingVertical: 36,
                            },
                        ]}
                        value={emailNew}
                        placeholder="Email address"
                        placeholderTextColor={Colors.secondaryText}
                        selectionColor={Colors.tintColor}
                        onChangeText={async text => {
                            await this.context.setEmailNew(text)
                            this.context.validateEmailNew(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: false,
                                emailFocused: true,
                            })
                        }}
                        // onFocus={isFocused => {
                        //     if (isFocused) {
                        //         this.setState({ emailFocused: true })
                        //     } else {
                        //         this.setState({ emailFocused: false })
                        //     }
                        // }}
                        onEndEditing={text => {
                            this.context.validateEmailNew(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />
                    {buttonIcon ? (
                        <ButtonIcon
                            iconName={'arrow-right'}
                            iconSize={Icons.small}
                            disabled={!emailNewValid}
                            onPress={() => {
                                this.onPressSubmitEmail()
                            }}
                        />
                    ) : (
                        <FormIcon
                            iconName={'check'}
                            iconSize={Icons.small}
                            disabled={!emailNew.length || !emailNewValid}
                        />
                    )}
                </View>

                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Caption,
                        {
                            flex: 1,
                            paddingVertical: 16,
                            color:
                                emailNew.length && !emailNewValid
                                    ? Colors.accentRed
                                    : 'transparent',
                        },
                    ]}
                >
                    Email must be formatted correctly
                </Text>
            </View>
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {windowContext => (
                    <View
                        style={{ flex: 1 }}
                        ref={ref => {
                            this.windowContext = windowContext
                        }}
                    >
                        <UserProfileConsumer>
                            {context => {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                        ref={ref => {
                                            this.context = context
                                        }}
                                    >
                                        {this.props.title ? (
                                            <Text
                                                style={TextStyles.ParagraphBold}
                                            >
                                                {this.props.title}
                                            </Text>
                                        ) : (
                                            <View />
                                        )}

                                        {this.props.message ? (
                                            <Text style={TextStyles.Caption}>
                                                {this.props.message}
                                            </Text>
                                        ) : (
                                            <View />
                                        )}

                                        {/* {this.renderInputFullName({
                                                        sectionTitle:
                                                            'Your name',
                                                        fullName:
                                                            context.fullName,
                                                        fullNameValid:
                                                            context.fullNameValid,
                                                    })}*/}

                                        {this.renderInputEmail({
                                            emailNew: context.emailNew,
                                            emailNewValid:
                                                context.emailNewValid,
                                            buttonIcon: this.props.buttonIcon,
                                        })}

                                        {this.props.buttonIcon ? (
                                            <View />
                                        ) : (
                                            <Button
                                                buttonStyle={{
                                                    paddingTop:
                                                        Layout.paddingMedium,
                                                    //alignSelf: 'center',
                                                    minWidth: 160,
                                                }}
                                                title={'Get updates'}
                                                disabled={
                                                    !context.emailNewValid
                                                }
                                                onPress={() => {
                                                    this.onPressSubmitEmail()
                                                }}
                                            />
                                        )}
                                    </View>
                                )
                            }}
                        </UserProfileConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
