import React from 'react'
import { Text, View, Share, ActionSheetIOS } from 'react-native'
import { withNavigation } from 'react-navigation'
import moment from 'moment'
import { Asset } from 'expo-asset'
import * as Sharing from 'expo-sharing'
import { Linking } from 'expo'
import { Layout, Buttons, Colors, TextStyles } from '../constants'
import Button from './Button'

class EventBar extends React.Component {
    state = {
        shareVideo: '',
        loading: false,
        isDone: false,

        // From resource: https://github.com/expo/expo/blob/master/apps/native-component-list/src/screens/SharingScreen.tsx
        isAvailable: false,
    }

    // componentDidMount = async () => {
    //     Sharing.isAvailableAsync().then(isAvailable =>
    //         this.setState({ isAvailable, loading: false })
    //     )

    //     console.log('Sharing Available?', this.state.isAvailable)
    // }

    onUpdateEventNavigate = () => {
        this.props.onPress && this.props.onPress()

        this.props.navigation.navigate('EventDetails', {
            eventData: this.props.eventData,
            selectedVideo: this.props.selectedVideo,
            isArchive: this.props.isArchive,
            isCreator: this.props.isCreator,
        })
    }

    onShareEventPress = () => {
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: [
                    'Cancel',
                    'Share Event and video link',
                    'Upload video to Social',
                    //'Remove'
                ],
                //destructiveButtonIndex: 3,
                cancelButtonIndex: 0,
                //tintColor: Colors.deepsea,
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    this.onReactSharePress()
                }
                if (buttonIndex === 2) {
                    this.onExpoSharePress()
                }
                // if (buttonIndex === 2) {
                //     /* destructive action */
                // }
            }
        )
    }

    onExpoSharePress = async () => {
        this.props.onPress && this.props.onPress()

        const { eventData } = this.props

        const startDate = moment.utc(eventData.startDate.seconds * 1000)

        const monthLabel = moment.utc(startDate, 'x').format('MMMM')
        //.toUpperCase()

        const dayDateLabel = moment
            .utc(startDate, 'x')
            .format('D')
            .toUpperCase()

        const dayLabel = moment.utc(startDate, 'x').format('dddd')
        //.toUpperCase()

        const timeLabel = moment
            .utc(startDate, 'x')
            .format('h:mm a')
            .toUpperCase()

        // const image = require('../assets/images/WholeLobsterTempura.jpg')
        // const asset = Asset.fromModule(image)

        const asset = Asset.fromURI(this.props.selectedVideo)

        await asset.downloadAsync()

        try {
            await Sharing.shareAsync(asset.localUri, {
                dialogTitle:
                    'Hey there! \n' +
                    'Inviting you to check out our event —\n\n' +
                    eventData.title +
                    ' by ' +
                    eventData.venueName +
                    ' on ' +
                    dayLabel +
                    ', ' +
                    monthLabel +
                    ' ' +
                    dayDateLabel +
                    ', at ' +
                    timeLabel +
                    '.\n\n',
            })
        } catch (e) {
            console.error(e)
        }
    }

    onReactSharePress = async () => {
        this.props.onPress && this.props.onPress()

        //console.log(this.props.eventData)

        const { eventData, storyData } = this.props

        const startDate = moment.utc(eventData.startDate.seconds * 1000)

        const monthLabel = moment.utc(startDate, 'x').format('MMMM')
        //.toUpperCase()

        const dayDateLabel = moment
            .utc(startDate, 'x')
            .format('D')
            .toUpperCase()

        const dayLabel = moment.utc(startDate, 'x').format('dddd')
        //.toUpperCase()

        const timeLabel = moment
            .utc(startDate, 'x')
            .format('h:mm a')
            .toUpperCase()

        //let videoURL = this.props.selectedVideo
        //let encodedURL = decodeURIComponent(videoURL)

        try {
            const result = await Share.share({
                title:
                    'Events on Indvstry | ' +
                    eventData.title +
                    ' by ' +
                    eventData.venueName +
                    ' on ' +
                    dayLabel +
                    ', ' +
                    monthLabel +
                    ' ' +
                    dayDateLabel,

                message:
                    'Hey there! \n' +
                    //'This is ' +
                    // userData.fullName is a placeholder.
                    //userData.fullName +
                    //' inviting you to check out our event -\n\n' +
                    'Inviting you to check out our event —\n\n' +
                    eventData.title +
                    ' by ' +
                    eventData.venueName +
                    ' on ' +
                    dayLabel +
                    ', ' +
                    monthLabel +
                    ' ' +
                    dayDateLabel +
                    ', at ' +
                    timeLabel +
                    '.\n\n' +
                    'Sent from Indvstry.app ✶ \n',

                // iOS
                url: this.props.selectedVideo,
                subject:
                    'Events on Indvstry | ' +
                    eventData.title +
                    ' by ' +
                    eventData.venueName +
                    ' on ' +
                    dayLabel +
                    ', ' +
                    monthLabel +
                    ' ' +
                    dayDateLabel,

                // Android
                dialogTitle:
                    'Events on Indvstry | ' +
                    eventData.title +
                    ' by ' +
                    eventData.venueName +
                    ' on ' +
                    dayLabel +
                    ', ' +
                    monthLabel +
                    ' ' +
                    dayDateLabel,
            })

            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            alert(error.message)
        }
    }

    render() {
        const startDate = moment.utc(
            this.props.eventData.startDate.seconds * 1000
        )

        const dayLabel = moment
            .utc(startDate, 'x')
            .format('dddd')
            .toUpperCase()

        const timeLabel = moment
            .utc(startDate, 'x')
            .format('h:mm a')
            .toUpperCase()

        return (
            <View
                style={[
                    Layout.columnContainer,
                    { paddingTop: Layout.paddingSmall },
                ]}
            >
                <View
                    style={{
                        flex: 1,
                    }}
                >
                    <Text
                        style={[TextStyles.Overline, { paddingBottom: 4 }]}
                        numberOfLines={1}
                    >
                        {dayLabel}・{timeLabel}
                    </Text>
                    <Text
                        style={[
                            TextStyles.ParagraphBold,
                            { lineHeight: 24, paddingBottom: 4 },
                        ]}
                        numberOfLines={1}
                    >
                        {this.props.title}
                    </Text>
                    <Text
                        style={[TextStyles.Caption, { paddingBottom: 4 }]}
                        numberOfLines={1}
                    >
                        by {this.props.venueName}
                    </Text>
                </View>

                {this.props.eventData.bookingUrl ? (
                    <Button
                        iconRight
                        iconName={'external-link'}
                        iconColor={Colors.white}
                        navigation={this.props.navigation}
                        onPress={() =>
                            Linking.openURL(
                                `${this.props.eventData.bookingUrl}`
                            )
                        }
                        //onPress={this.onReactSharePress}
                        //onPress={this.onExpoSharePress}
                        // onPress={
                        //     this.props.isCreator
                        //         ? Platform.OS === 'ios'
                        //             ? this.onShareEventPress
                        //             : this.onExpoSharePress
                        //         : this.onUpdateEventNavigate
                        // }
                        buttonStyle={[
                            Buttons.buttonMiniInverse,
                            {
                                alignSelf: 'center',
                                backgroundColor: Colors.tintColor,
                            },
                        ]}
                        textColor={Colors.white}
                        textStyle={TextStyles.Button}
                        title={'Book'}
                    />
                ) : (
                    <View />
                )}
            </View>
        )
    }
}

export default withNavigation(EventBar)
