import React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { Layout, Colors, TextStyles } from '../../constants'
import moment from 'moment'

export default class UserAgreementScreen extends React.Component {
    render() {
        const timestampLabel = moment().format('dddd, MMMM D, YYYY')

        return (
            <ScrollView style={{ flex: 1 }}>
                <View
                    style={[
                        Layout.projectContainer,
                        {
                            backgroundColor: Colors.gray0,
                            paddingVertical: 0,
                        },
                    ]}
                >
                    <View
                        style={[
                            Layout.columnOne,
                            {
                                maxWidth: 720,
                                paddingVertical: Layout.topNavHeightDesktop,
                            },
                        ]}
                    >
                        <View style={Layout.cardContent}>
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                Non-Disclosure Agreement (NDA)
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                I. The Parties.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Unilateral Non-Disclosure Agreement,
                                hereinafter referred to as the “Agreement”,
                                effective as of January 1, 2020, hereinafter
                                referred to as the “Effective Date”, is by and
                                between: Releasor described as a business entity
                                known as Indvstry Inc. ("Releasor") AND
                                Recipient described as 1 individual(s) known as
                                Guest ("Recipient").
                            </Text>

                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                II. Confidential Information.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                The term "Confidential Information" includes,
                                but is not limited to, all information owned by
                                the Releasor and not generally known to the
                                public or in the relevant trade or industry that
                                is communicated orally, written, printed,
                                electronically or any other form or medium, or
                                which was learned, discovered, developed,
                                conceived, originated, or prepared by the
                                Recipient in the scope and course of their
                                relationship with the Releasor, relating
                                directly or indirectly to business processes,
                                technical data, trade secrets, know-how, advice,
                                consultations, proprietary information, client
                                lists, client instructions, assets, business
                                operations, specifications, designs, plans,
                                drawings, hardware, software, data, prototypes
                                or other business and technical information
                                belonging to any client of the Releasor,
                                operational methods, economic and business
                                analyses, models, strategies, and projections,
                                promotion methods, trade show information and
                                contacts, and other proprietary information
                                relating to the business of the Releasor and any
                                and all other concepts, as such Confidential
                                Information pertains personally to principals or
                                other information that has independent economic
                                value.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                III. Non-Disclosure.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                The Recipient agrees that it shall have the
                                obligation to: (a) hold the Confidential
                                Information in the strictest of confidence; (b)
                                not use the Confidential Information for any
                                personal gain or detrimentally to the Releasor;
                                (c) take all steps necessary to protect the
                                Confidential Information from disclosure and to
                                implement internal procedures to guard against
                                such disclosure; (d) not disclose the fact that
                                the Confidential Information has been made
                                available or that discussions and negotiations
                                are taking place or have taken place or any of
                                its terms, conditions or other facts with
                                respect to the transaction; and (e) not disclose
                                or make available all or any part of the
                                Confidential Information to any person, firm,
                                corporation, association, or any other entity
                                for any reason or purpose whatsoever, directly
                                or indirectly, unless and until such
                                Confidential Information becomes publicly
                                available other than as a consequence of the
                                breach by the Recipient of their confidentiality
                                obligations hereunder.
                                {'\n\n'}
                                This Section shall survive and continue after
                                any expiration or termination of this Agreement
                                and shall bind Recipient, its employees, agents,
                                representatives, successors, heirs and assigns.
                            </Text>

                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                IV. Exceptions to Confidential Information.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                The Recipient shall not be restricted from
                                disclosing or using Confidential Information
                                that: (a) was freely available in the public
                                domain at the time it was communicated to the
                                Recipient by the Releasor; (b) subsequently came
                                to the public domain through no fault of the
                                Recipient; (c) is in the Recipient's possession
                                free of any obligation of confidence at the time
                                it was communicated to the Recipient by the
                                Releasor; (d) is independently developed by the
                                Recipient or its representatives without
                                reference to any information communicated to the
                                Recipient by the Releasor; (e) is provided by
                                Recipient in response to a valid order by a
                                court or other governmental body, as otherwise
                                required by law; or (f) is approved for release
                                by written authorization of an officer or
                                representative of the Releasor;
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                V. Use or Disclosure of Confidential
                                Information.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Recipient shall only use the Confidential
                                Information as directed by the Releasor and not
                                for its own purposes or the purposes of any
                                other party. Recipient shall disclose the
                                Confidential Information received under this
                                Agreement to any person within its organization
                                only if such persons are on a "need to know"
                                basis. Recipient shall advise each person to
                                whom disclosure is permitted that such
                                information is the confidential and proprietary
                                property of the Releasor and may not be
                                disclosed to others or used for their own
                                purpose. This Section shall survive and continue
                                after any expiration or termination of this
                                Agreement and shall bind Recipient, its
                                employees, agents, representatives, successors,
                                heirs and assigns.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                VI. Notice of Disclosure.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                In the event that the Recipient receives a
                                request or is required (by deposition,
                                interrogatory, request for documents, subpoena,
                                civil investigative demand or similar process)
                                to disclose all or any part of the Confidential
                                Information, the Recipient agrees, if legally
                                permissible, to (a) promptly notify the Releasor
                                of the existence, terms and circumstances
                                surrounding such request or requirement, (b)
                                consult with the Releasor on the advisability of
                                taking legally available steps to resist or
                                narrow such request or requirement and (c)
                                assist the Releasor in seeking a protective
                                order or other appropriate remedy; provided,
                                however, that the Recipient shall not be
                                required to take any action in violation of
                                applicable laws. In the event that such
                                protective order or other remedy is not obtained
                                or that the Releasor waives compliance with the
                                provisions hereof, the Recipient shall not be
                                liable for such disclosure unless disclosure to
                                any such tribunal was caused by or resulted from
                                a previous disclosure by the Recipient not
                                permitted by this Agreement.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                VII. Term.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Agreement, with respect to Confidential
                                Information, will remain in effect for
                                perpetuity.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                VIII. Return of Confidential Information.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Upon request from the Releasor or upon the
                                termination of negotiations and evaluations
                                between the Parties, Recipient will promptly
                                deliver to Releasor all originals and copies of
                                all documents, records, software programs, media
                                and other materials containing any Confidential
                                Information. Recipient shall also return to
                                Releasor all equipment, files, and other
                                personal property belonging to Releasor.
                                Recipient shall not be permitted to make,
                                retain, or distribute copies of any Confidential
                                Information and shall not create any other
                                documents, records, or materials in any form
                                whatsoever that includes the Confidential
                                Information.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                IX. Indemnification.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                The Parties agree to indemnify and keep each
                                other, at all times, fully and effectively
                                indemnified in respect of all claims, demands,
                                losses, damages, liabilities, costs and or
                                expenses of any kind whatsoever incurred by the
                                Releasor which arise out of or in connection
                                with any breach of this Agreement by the
                                Recipient.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                X. Notice.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Any notice provided in this Agreement must be in
                                writing and must be either personally delivered,
                                mailed by first class mail (postage prepaid and
                                return receipt requested) or sent by reputable
                                overnight courier service (charges prepaid) to
                                the Parties at the addresses below indicated:
                                Releasor’s Address: 251 Little Falls Dr.,
                                Wilmington, Delaware, 19808-1674 If the
                                above-stated addresses should change the Parties
                                shall specify by certified mail, with return
                                receipt, to one another.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XI. Covenants.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                The parties hereto agree that the covenants,
                                agreements, and restrictions (hereinafter "this
                                covenant") contained herein are necessary to
                                protect the business goodwill, business
                                interests and proprietary rights of the Releasor
                                and that the parties hereto have independently
                                discussed, reviewed and had the opportunity of
                                legal counsel to consider this Agreement.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XII. Authority.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Agreement sets forth the entire Agreement
                                and understanding between the Parties and
                                supersedes all prior oral or written agreements
                                and understandings relating to the subject
                                matter of this Agreement. This Agreement may not
                                be modified or discharged, in whole or part,
                                except by consent in writing signed by the
                                Parties.{' '}
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XIII. Assignment.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Agreement may not be assigned or otherwise
                                transferred by either party without the prior
                                written consent of the non-transferring party.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XIV. Binding Arrangement.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Agreement will be binding upon and inure to
                                the benefit of the parties hereto and each
                                Party’s respective successors and assigns.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XV. Severability.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                In the event that any provision of this
                                Agreement is held by a court of competent
                                jurisdiction to be unenforceable because it is
                                invalid or in conflict with any law of any
                                relevant jurisdiction, the validity of the
                                remaining provisions shall not be affected, and
                                the rights and obligations of the parties hereto
                                shall be construed and enforced as if the
                                Agreement did not contain the particular
                                provision(s) held to be unenforceable.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XVI. Governing Law.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Agreement shall be governed by and
                                construed in accordance with the laws in the
                                State of California.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XVII. Authority.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                Each party hereto represents and warrants that
                                it has the full power and authority to enter
                                into and perform this Agreement, and each party
                                knows of no law, rule, regulations, order,
                                agreement, promise, undertaking or other fact or
                                circumstance which would prevent its full
                                execution and performance of this Agreement.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XVIII. Counterparts.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                This Agreement may be executed in any number of
                                counterparts, each of which shall be an
                                original, but all of which together shall
                                constitute one and the same agreement.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Overline,
                                    {
                                        paddingBottom: Layout.paddingMedium,
                                    },
                                ]}
                            >
                                XIX. Execution.
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        paddingBottom: Layout.paddingLarge,
                                    },
                                ]}
                            >
                                IN WITNESS WHEREOF, the Parties hereto have
                                executed this Agreement as of{'  '}
                                {timestampLabel}.
                            </Text>
                        </View>
                    </View>
                </View>
            </ScrollView>
        )
    }
}
