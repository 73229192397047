import React from 'react'
import { ScrollView, View } from 'react-native'
import { DrawerActions } from 'react-navigation-drawer'
import { Layout, Colors, Buttons } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import ButtonIcon from './ButtonIcon'
import DrawerNavigationTabs from './DrawerNavigationTabs'

export default class DrawerNavigation extends React.Component {
    componentDidMount = () => {
        this.context.initializeUserAccount()
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <View style={Buttons.drawerStickyButtonContainer}>
                                <ButtonIcon
                                    iconName={'x'}
                                    iconColor={Colors.tintColor}
                                    buttonStyle={Buttons.drawerStickyButton}
                                    loading={false}
                                    disabled={false}
                                    onPress={() =>
                                        this.props.navigation.dispatch(
                                            DrawerActions.toggleDrawer()
                                        )
                                    }
                                />
                            </View>
                            <ScrollView
                                style={{ flex: 1 }}
                                scrollEnabled={false}
                                showsVerticalScrollIndicator={false}
                            >
                                <DrawerNavigationTabs
                                    userExists={context.userExists}
                                    navigation={this.props.navigation}
                                    onPress={context.userSignOut}
                                />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}
