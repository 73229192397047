import React from 'react'
import { ScrollView, Text, TextInput, View } from 'react-native'
import { Layout, Colors, Buttons, TextStyles, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'
import Button from '../../components/Button'
import FormIcon from '../../components/FormIcon'

export default class RequestInfo extends React.Component {
    state = {
        fullNameFocused: false,
        emailFocused: false,
        success: false,
    }

    onPressSubmitEmail = () => {
        const placeId = this.props.placeId
        this.context.onSubmitRequestForm(placeId)
        this.setState({ success: true })
    }

    renderInputFullName = ({ sectionTitle, fullName, fullNameValid }) => {
        return (
            <View
                style={{
                    width: '100%',
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderBottomColor: this.state.fullNameFocused
                            ? Colors.tintColor
                            : Colors.disabled,
                        borderBottomWidth: 1,
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                width: '100%',
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                outline: 'none',
                                // borderBottomColor: this.state.fullNameFocused
                                //     ? Colors.tintColor
                                //     : Colors.disabled,
                                //     borderBottomWidth: 1,

                                paddingBottom: 16,
                            },
                        ]}
                        value={fullName}
                        placeholder="Full name"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.tintColor}
                        onChangeText={async text => {
                            await this.context.setFullName(text)
                            this.context.validateFullName(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: true,
                                emailFocused: false,
                            })
                        }}
                        onEndEditing={text => {
                            this.context.validateFullName(text)
                        }}
                        maxLength={56}
                        keyboardType="default"
                        autoCapitalize="words"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                        autoFocus={true}
                    />
                    <FormIcon
                        iconName={'check'}
                        iconSize={Icons.small}
                        disabled={!fullName.length || !fullNameValid}
                    />
                </View>
            </View>
        )
    }

    renderInputEmail = ({ sectionTitle, emailNew, emailNewValid }) => {
        return (
            <View
                style={{
                    width: '100%',
                    //paddingBottom: Layout.paddingLarge,
                }}
            >
                {/* <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text> */}
                {/* <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Caption,
                        {
                            // display: Layout.isMediumDevice
                            //     ? this.state.success
                            //         ? 'flex'
                            //         : 'none'
                            //     : 'flex',
                            display: this.state.success ? 'flex' : 'none',
                            flex: 1,
                            paddingVertical: 16,
                            color: this.state.success
                                ? Colors.tintColor
                                : 'transparent',
                        },
                    ]}
                >
                    So glad you joined us.
                </Text> */}
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottomColor: this.state.emailFocused
                            ? Colors.tintColor
                            : Colors.disabled,
                        borderBottomWidth: 1,
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                width: '100%',
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                                outline: 'none',
                                paddingVertical: 16,
                                // borderBottomColor: this.state.emailFocused
                                //     ? Colors.tintColor
                                //     : Colors.disabled,
                                //     borderBottomWidth: 1,
                            },
                        ]}
                        value={emailNew}
                        placeholder="Email address"
                        placeholderTextColor={Colors.secondaryText}
                        selectionColor={Colors.tintColor}
                        onChangeText={async text => {
                            await this.context.setEmailNew(text)
                            this.context.validateEmailNew(text)
                        }}
                        onFocus={() => {
                            this.setState({
                                fullNameFocused: false,
                                emailFocused: true,
                            })
                        }}
                        onEndEditing={text => {
                            this.context.validateEmailNew(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />
                    <FormIcon
                        iconName={'check'}
                        iconSize={Icons.small}
                        disabled={!emailNew.length || !emailNewValid}
                    />
                </View>
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Caption,
                        {
                            flex: 1,
                            paddingVertical: 16,
                            color:
                                emailNew.length && !emailNewValid
                                    ? Colors.accentRed
                                    : 'transparent',
                        },
                    ]}
                >
                    Email must be formatted correctly
                </Text>
            </View>
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {windowContext => (
                    <View
                        style={{ flex: 1 }}
                        ref={ref => {
                            this.windowContext = windowContext
                        }}
                    >
                        <UserProfileConsumer>
                            {context => {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                        ref={ref => {
                                            this.context = context
                                        }}
                                    >
                                        <ScrollView
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <View
                                                style={[
                                                    Layout.sectionContainer,
                                                    {
                                                        backgroundColor:
                                                            Colors.gray0,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={[
                                                        Layout.columnOne,
                                                        {
                                                            maxWidth: 720,
                                                            paddingVertical:
                                                                Layout.topNavHeightDesktop,
                                                            alignItems:
                                                                'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading2,
                                                            {
                                                                paddingBottom:
                                                                    Layout.paddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Stay in touch
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                paddingBottom:
                                                                    Layout.paddingLarge,
                                                            },
                                                        ]}
                                                    >
                                                        Sign up to receive the
                                                        latest news about
                                                        product launches,
                                                        collaborations and more.
                                                    </Text>
                                                    {/* {this.renderInputFullName({
                                                        sectionTitle:
                                                            'Your name',
                                                        fullName:
                                                            context.fullName,
                                                        fullNameValid:
                                                            context.fullNameValid,
                                                    })} */}
                                                    {this.renderInputEmail({
                                                        sectionTitle: 'Email',
                                                        emailNew:
                                                            context.emailNew,
                                                        emailNewValid:
                                                            context.emailNewValid,
                                                    })}
                                                    {/* <Button
                                                        style={{
                                                            alignSelf: 'center',
                                                        }}
                                                        title={'Submit request'}
                                                        disabled={
                                                            !context.emailNewValid ||
                                                            !context.fullNameValid
                                                        }
                                                        onPress={() => {
                                                            this.context.onSubmitRequestForm()
                                                            this.props.navigation.navigate(
                                                                'RequestConfirmation'
                                                            )
                                                        }}
                                                    /> */}
                                                    <Button
                                                        buttonStyle={[
                                                            // Buttons.button,
                                                            {
                                                                // alignSelf:
                                                                //     'center',
                                                                minWidth: 160,
                                                                width: Layout.isSmallDevice
                                                                    ? '100%'
                                                                    : null,
                                                            },
                                                        ]}
                                                        title={'Sign up'}
                                                        disabled={
                                                            !context.emailNewValid
                                                        }
                                                        onPress={() => {
                                                            this.context.onSubmitRequestForm()
                                                            this.props.navigation.navigate(
                                                                'RequestConfirmation'
                                                            )
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </ScrollView>
                                    </View>
                                )
                            }}
                        </UserProfileConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
