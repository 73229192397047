import React from 'react'
import { Text, View } from 'react-native'
import moment from 'moment'
import { Layout, TextStyles } from '../constants'

class EventDateWidget extends React.Component {
    render() {
        const startDate = this.props.startDate

        const monthLabel = moment
            .utc(startDate, 'x')
            .format('MMM')
            .toUpperCase()

        const dayDateLabel = moment
            .utc(startDate, 'x')
            .format('D')
            .toUpperCase()

        return (
            <View style={Layout.eventDateWidget}>
                <Text style={[TextStyles.OverlineLight, { lineHeight: 18 }]}>
                    {monthLabel}
                </Text>
                <Text style={[TextStyles.ParagraphBold, { lineHeight: 20 }]}>
                    {dayDateLabel}
                </Text>
            </View>
        )
    }
}

export default EventDateWidget
