import { createBrowserApp } from '@react-navigation/web'
import { createSwitchNavigator } from 'react-navigation'

import AuthLoadingScreen from '../screens/auth/AuthLoadingScreen'
import PublicDrawerNavigator from './PublicDrawerNavigator'
import PrivateDrawerNavigator from './PrivateDrawerNavigator'

const Authenticate = AuthLoadingScreen
const Public = PublicDrawerNavigator
//const Private = PrivateDrawerNavigator

const navigationRoutes = {
    Authenticate,
    Public,
    //Private,
}

const options = {
    initialRouteName: 'Authenticate',
}

const switchNavigator = createSwitchNavigator(navigationRoutes, options)
switchNavigator.path = ''

export default createBrowserApp(switchNavigator)

//export default createBrowserApp(switchNavigator, { history: 'hash' })
