import React from 'react'
import { Alert, ActionSheetIOS } from 'react-native'
import { Asset } from 'expo-asset'
import * as Sharing from 'expo-sharing'
import moment from 'moment'
import Event from '../api/Event'
import User from '../api/User'
import Venue from '../api/Venue'

export const EventContext = React.createContext()
export const EventConsumer = EventContext.Consumer

//const pageSize = 14

export class EventProvider extends React.Component {
    state = {
        // Firebase mount remote data
        loading: false,
        agendaDataGrouped: {},
        agendaEventsGrouped: [],
        agendaCursor: '',

        // Agenda data, item objects organized within arrays
        items: [],
        // To provide Events Modal with date for empty items
        itemDate: '',

        // To provide Events Modal with data for existing items
        selectedEvent: '',

        // Selected Event data
        eventId: '',
        title: '',
        about: '',
        startDate: '',
        endDate: '',
        createdBy: '',

        // Set UserVenueActive or Users and Venue
        placeId: '',
        venueName: '',
        venueAddress: '',
        avatarImage: 'https://',
        fullName: '',
        visibility: {},
        emailVerified: false,

        // Event creation and editing
        showStartDatePicker: false,
        showEndDatePicker: false,
        startDateNew: '',
        endDateNew: '',
        titleNew: '',
        aboutNew: '',

        /* Managing state updates between routes */
        isDeleted: false,
        isUpdated: false,

        contributors: [],
        bookingUrl: '',
    }

    /**
     *
     *  SPECIAL BATCH FUNCTION:
     *  Updates all documents in collection
     *
     **/

    // componentDidMount = () => {
    //     Event._batchEventUpdate()
    // }

    /**
     *
     *  EVENTS FUNCTIONS
     *
     */

    // initializeEventForm = async () => {
    //     await this.setState({
    //         showStartDatePicker: false,
    //         showEndDatePicker: false,
    //         startDateNew: '',
    //         endDateNew: '',
    //         titleNew: '',
    //         aboutNew: '',
    //         contributors: [],
    //         //isDeleted: false,
    //         //isUpdated: false,
    //     })
    // }

    initializeEventForm = async () => {
        await this.setState({
            showStartDatePicker: false,
            showEndDatePicker: false,
            startDateNew: this.state.startDate,
            endDateNew: this.state.endDate,
            titleNew: this.state.title,
            aboutNew: this.state.about,
            contributors: [],
            //isDeleted: false,
            //isUpdated: false,
        })
    }

    setIsDeleted = isDeleted => {
        this.setState({ isDeleted: isDeleted })
    }

    setIsUpdated = isUpdated => {
        this.setState({ isUpdated: isUpdated })
    }

    setUserVenueActive = async () => {
        const {
            placeId,
            venueName,
            venueAddress,
            visibility,
        } = await User._getUserVenueActive()
        this.setState(placeId, venueName, venueAddress, visibility)
    }

    setUserAccount = async () => {
        const {
            fullName,
            photoUrl,
            emailVerified,
        } = await User._getUserAccount()

        this.setState({
            fullName,
            avatarImage: photoUrl,
            emailVerified,
        })
    }

    setLoading = loading => {
        this.setState({ loading: loading })
        //console.log('Event Context ---> loading:', this.state.loading);
    }

    setShowStartDatePicker = () => {
        {
            this.state.showStartDatePicker
                ? this.setState({
                      showStartDatePicker: false,
                  })
                : this.setState({
                      showStartDatePicker: true,
                  })
        }
    }

    setShowEndDatePicker = () => {
        {
            this.state.showEndDatePicker
                ? this.setState({
                      showEndDatePicker: false,
                  })
                : this.setState({
                      showEndDatePicker: true,
                  })
        }
    }

    // setEventId = async () => {
    //     await Object.values(this.state.selectedEvent).map(
    //         value => (eventId = value.eventId)
    //     )

    //     this.setState(() => ({
    //         eventId,
    //     }))
    // }

    /* REFACTOR FUNCTIONS */

    setEventId = eventId => {
        this.setState({ eventId: eventId })
    }

    setFullName = fullName => {
        this.setState({ fullName: fullName })
    }

    setAvatarImage = avatarImage => {
        this.setState({ avatarImage: avatarImage })
    }

    setTitle = title => {
        this.setState({ title: title })
    }

    setTitleNew = titleNew => {
        this.setState({ titleNew: titleNew })
    }

    setAbout = about => {
        this.setState({ about: about })
    }

    setAboutNew = aboutNew => {
        this.setState({ aboutNew: aboutNew })
    }

    setStartDate = startDate => {
        this.setState({
            startDate: startDate,
        })
    }

    setEndDate = endDate => {
        this.setState({
            endDate: endDate,
        })
    }

    setStartDateNew = startDateNew => {
        this.setState({
            startDateNew: startDateNew,
        })
    }

    setEndDateNew = endDateNew => {
        this.setState({
            endDateNew: endDateNew,
        })
    }

    setEventInfo = async eventId => {
        const { selectedEventData } = await Event._getEventInfo({ eventId })
        await this.setSelectedEvent(selectedEventData)
    }

    onUpdateEventPress = async () => {
        this.setState({ loading: true })

        if (this.state.titleNew === '')
            await this.setState({ titleNew: 'Event name' })

        const startDate = await new Date(this.state.startDateNew)
        const endDate = await new Date(this.state.endDateNew)
        const title = this.state.titleNew
        const about = this.state.aboutNew
        const eventId = this.state.eventId

        await Event._updateEvent({
            eventId,
            startDate,
            endDate,
            title,
            about,
            visibility: this.state.visibility,
        })

        // Added back in for refreshing page
        await this.agendaEventsRemoteRequest()

        //await this.setEventsModal()
        // I would need to get this specific event based on eventId
        const { selectedEventData } = await Event._getEventInfo({ eventId })

        //console.log('selectedEventData >>>>>>>>>', selectedEventData)

        await this.setSelectedEvent(selectedEventData)

        this.setState({ loading: false })
    }

    onCreateEventPress = async () => {
        this.setState({
            loading: true,
        })

        if (this.state.titleNew === '')
            await this.setState({
                titleNew: 'Event name',
            })

        const startDate = await new Date(this.state.startDateNew)
        const endDate = await new Date(this.state.endDateNew)
        const title = this.state.titleNew
        const about = this.state.aboutNew

        await Event._createEvent({
            startDate,
            endDate,
            title,
            about,
            placeId: this.state.placeId,
            venueName: this.state.venueName,
            venueAddress: this.state.venueAddress,
            visibility: this.state.visibility,
            bookingUrl: this.state.bookingUrl,
        })

        // Added back in for refreshing page

        await this.agendaEventsRemoteRequest()

        // Since an eventId does not yet exist in the DB, this flow is slightly different from the Update flow.
        // This navigates to EventsAgenda screen, where the updated data and new event exists. Then user can select their new event.

        //('EVENT-CONTEXT: ON CREATE PRESS', this.state)

        this.setState({
            loading: false,
        })
    }

    onAddNewEvent = async () => {
        const itemDate = await moment()
            .startOf('day')
            .format('YYYY[-]MM[-]DD')

        await this.setItemDate(itemDate)
        await this.setStartDate(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(18, 'hours')
            )
        )
        await this.setEndDate(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(21, 'hours')
            )
        )

        await this.setStartDateNew(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(18, 'hours')
            )
        )
        await this.setEndDateNew(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(21, 'hours')
            )
        )

        await this.setState({
            selectedEvent: '',
            eventId: '',
            title: '',
            about: '',

            avatarImage: this.state.avatarImage,
            fullName: this.state.fullName,

            placeId: this.state.placeId,
            venueName: this.state.venueName,
            venueAddress: this.state.venueAddress,
            visibility: this.state.visibility,
        })
    }

    onAddAgendaEvent = async itemTime => {
        const itemDate = await moment
            .utc(itemTime)
            .startOf('day')
            .format('YYYY[-]MM[-]DD')

        await this.setItemDate(itemDate)

        await this.setStartDate(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(18, 'hours')
            )
        )
        await this.setEndDate(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(21, 'hours')
            )
        )

        await this.setStartDateNew(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(18, 'hours')
            )
        )
        await this.setEndDateNew(
            new Date(
                moment
                    .utc(itemDate)
                    .startOf('day')
                    .add(21, 'hours')
            )
        )

        // await this.setStartDate({ startDate })
        // await this.setEndDate({ endDate })

        await this.setState({
            selectedEvent: '',
            eventId: '',
            title: '',
            about: '',

            avatarImage: this.state.avatarImage,
            fullName: this.state.fullName,

            placeId: this.state.placeId,
            venueName: this.state.venueName,
            venueAddress: this.state.venueAddress,
            visibility: this.state.visibility,
        })

        // figure this out::: this.setStartDate({})
    }

    onEventOptionsPress = eventId => {
        console.log('eventId:::::', eventId)
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: ['Cancel', 'Remove event'],
                destructiveButtonIndex: 1,
                cancelButtonIndex: 0,
                title: 'Team Mode',
                message:
                    'Events are shared with other team members at your venue.',
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    /* destructive action */
                    // Event._disableEvent({
                    //     eventId: this.state.eventId,
                    // })
                    Alert.alert(
                        '😅 Remove this event?',

                        'Once you remove this event, you will lose access to any related stories you created. If team members have already contributed to this event, you will not be able to remove it at this time.',
                        [
                            {
                                text: 'Yes, remove event',
                                onPress: () =>
                                    Event._disableEvent(eventId).then(() =>
                                        this.setState({
                                            //isDeleted: true,
                                            isUpdated: true,
                                        })
                                    ),
                                style: 'destructive',
                            },

                            {
                                text: 'Cancel',
                                onPress: () => {
                                    console.log('Cancel Pressed')
                                },
                                style: 'cancel',
                            },
                        ]
                    )
                }
            }
        )
    }

    onEventSharePress = async () => {
        //this.props.onPress && this.props.onPress()

        //    const startDate = moment.utc(
        //        this.state.startDate.seconds * 1000
        //    )

        //    const monthLabel = moment
        //        .utc(startDate, 'x')
        //        .format('MMMM')
        //    //.toUpperCase()

        //    const dayDateLabel = moment
        //        .utc(startDate, 'x')
        //        .format('D')
        //        .toUpperCase()

        //    const dayLabel = moment.utc(startDate, 'x').format('dddd')
        //    //.toUpperCase()

        //    const timeLabel = moment
        //        .utc(startDate, 'x')
        //        .format('h:mm a')
        //        .toUpperCase()

        // const image = require('../assets/images/WholeLobsterTempura.jpg')
        // const asset = Asset.fromModule(image)

        const asset = Asset.fromURI(this.props.selectedVideo)

        await asset.downloadAsync()

        try {
            // await Sharing.shareAsync(asset.localUri, {
            //     dialogTitle: 'Is it a snake or a hat?',
            // })
            await Sharing.shareAsync(asset.localUri, {
                //dialogTitle: 'Created on indvstry.io',
                //UTI: 'public.jpeg',
                //    dialogTitle:
                //        'Hi there!' +
                //        //'This is ' +
                //        // userData.fullName is a placeholder.
                //        //userData.fullName +
                //        //' inviting you to check out our event -\n\n' +
                //        'Inviting you to check out our event —\n\n' +
                //        this.state.title +
                //        ' by ' +
                //        eventData.venueName +
                //        ' on ' +
                //        dayLabel +
                //        ', ' +
                //        monthLabel +
                //        ' ' +
                //        dayDateLabel +
                //        ', at ' +
                //        timeLabel +
                //        '.\n\n',
            })
        } catch (e) {
            console.error(e)
        }
    }

    /* REFACTOR END */

    setItemDate = itemDate => {
        this.setState({ itemDate: itemDate })
        //console.log('Event Context ---> itemDate:', itemDate)
    }

    // setSelectedEvent = selectedEvent => {
    //     this.setState({ selectedEvent: selectedEvent })
    //     //this.setEventId()
    //     console.log('EVENT-CONTEXT: SELECTED-EVENT', selectedEvent)
    // }

    setSelectedEvent = async selectedEvent => {
        const startDate = await new Date(selectedEvent.startDate)
        const endDate = await new Date(selectedEvent.endDate)
        const titleNew = selectedEvent.title
        const aboutNew = selectedEvent.about
        const placeId = selectedEvent.placeId
            ? selectedEvent.placeId
            : this.state.placeId
        // const avatarImage = selectedEvent.avatarImage
        // const fullName = selectedEvent.fullName

        await this.setState({
            startDate,
            endDate,
            titleNew,
            aboutNew,
            placeId,
            startDateNew: startDate,
            endDateNew: endDate,
            eventId: selectedEvent.eventId,
            title: selectedEvent.title,
            about: selectedEvent.about,
            //placeId: selectedEvent.placeId,
            venueName: selectedEvent.venueName,
            venueAddress: selectedEvent.venueAddress,
            createdBy: selectedEvent.createdBy,
            contributors: selectedEvent.contributors,
            // avatarImage,
            // fullName,
            visibility: selectedEvent.visibility,
        })

        this.setState({ selectedEvent: selectedEvent })
        //this.setEventId()
        //console.log('EVENT-CONTEXT: SELECTED-EVENT', selectedEvent, this.state)
    }

    /* THIS IS THE VERSION THAT REPLACES STATE */

    setItems = items => {
        this.setState({ items: items })
        //console.log('Event Context ---> setItems:', items);
    }

    /* THIS VERSION ITERATIVELY ADDS, NON-DESTRUCTIVELY */
    addAgendaItems = agendaEventsGrouped => {
        this.setState(previousState => {
            let agendaDataGrouped = {
                ...previousState.agendaDataGrouped,
                ...agendaEventsGrouped,
            }

            // console.log(
            //     'EVENT-CONTEXT',
            //     'ADD-AGENDA-EVENTS',
            //     'AGENDA-DATA :::',
            //     //agendaDataGrouped,
            //     'AGENDA-EVENTS :::'
            //     //agendaEventsGrouped
            //     // Object.values(agendaDataGrouped).sort(
            //     //     (a, b) => a.startDate > b.startDate
            //     // )
            // )

            return {
                agendaDataGrouped,
                items: agendaEventsGrouped,
            }
        })
    }

    setEventsModal = async agendaEventsGrouped => {
        // This function successfully puts out the first, single selectedDate
        const selectedEventArray = await Object.values(
            agendaEventsGrouped
        ).reduce(function(acc, cur, idx, arr) {
            //const value = 0
            return acc //[value]
        })

        const selectedEvent = selectedEventArray[0]

        // This must be sent as an object to be read by Events Agenda Screen.
        this.setSelectedEvent(selectedEvent)
    }

    __original_setEventsModal = async agendaEventsGrouped => {
        if (!this.state.selectedEvent) {
            // This function successfully puts out the first, single selectedDate
            const selectedEventArray = await Object.values(
                agendaEventsGrouped
            ).reduce(function(acc, cur, idx, arr) {
                //const value = 0
                return acc //[value]
            })

            const selectedEvent = selectedEventArray[0]

            // This must be sent as an object to be read by Events Agenda Screen.

            //const selectedEvent = this.state.items
            console.log(selectedEvent)
            this.setSelectedEvent(selectedEvent)

            /* An attempt to refresh state after delete */

            //this.setSelectedEvent(selectedEvent)

            //this.setEventId(selectedEvent.eventId)

            // console.log(
            //     'EVENT-CONTEXT',
            //     'SET-SELECTED-DATE :::',
            //     this.state.selectedEvent,
            //     'SET STATE EVENT ID :::',
            //     this.state.eventId
            // )
        } else {
            return
        }
    }

    agendaEventsRemoteRequest = async size => {
        // If we are currently getting events, then bail out..
        // if (this.state.loading) {
        //     return
        // }

        this.setState({
            loading: true,
        })

        const TODAY = await new Date(moment().startOf('day'))
        // await this.setState({
        //     agendaCursor: TODAY,
        // })

        // if (!this.state.agendaCursor) {
        //     const TODAY = new Date(moment().startOf('day'))
        //     await this.setState({
        //         agendaCursor: TODAY,
        //     })
        // }

        try {
            //const pageSize = 14

            const {
                agendaEvents,
                agendaCursor,
            } = await Event._agendaEventsPaged({
                size: 100,
                //size: size || 100,
                end: TODAY,
            })

            // await this.setState({
            //     agendaCursor,
            // })

            /* START: THIS SET OF OPERATIONS POPULTATES THE AGENDA SCREEN */
            /* This groups by date, and sorts by startDate */

            function groupBy(objectArray, property) {
                return objectArray.reduce(function(acc, obj) {
                    let key = obj[property]
                    if (!acc[key]) {
                        acc[key] = []
                    }
                    acc[key].push(obj)
                    return acc
                }, {})
            }

            const agendaEventsGrouped = groupBy(agendaEvents, 'calendarDate')

            //console.log('agendaEventsGrouped', agendaEventsGrouped)
            await this.addAgendaItems(agendaEventsGrouped)
            /* END: THIS SET OF OPERATIONS POPULTATES THE AGENDA SCREEN */

            /* THIS SET OF OPERATIONS POPULATES THE MODAL BUTTON */
            if (!agendaEvents.length) {
                // This function successfully puts out an empty itemDate
                const itemDate = await moment()
                    .startOf('day')
                    .format('YYYY[-]MM[-]DD')

                await this.setItemDate(itemDate)

                this.setStartDate(
                    new Date(
                        moment
                            .utc(itemDate)
                            .startOf('day')
                            .add(18, 'hours')
                    )
                )
                this.setEndDate(
                    new Date(
                        moment
                            .utc(itemDate)
                            .startOf('day')
                            .add(20, 'hours')
                    )
                )

                await this.setStartDateNew(
                    new Date(
                        moment
                            .utc(itemDate)
                            .startOf('day')
                            .add(18, 'hours')
                    )
                )
                await this.setEndDateNew(
                    new Date(
                        moment
                            .utc(itemDate)
                            .startOf('day')
                            .add(21, 'hours')
                    )
                )

                await this.setState({
                    eventId: '',
                    title: '',
                    about: '',
                })
            } else {
                await this.setEventsModal(agendaEventsGrouped)
            }

            // Finish loading, this will stop the refreshing animation.
            this.setState({
                loading: false,
            })
        } catch ({ message }) {
            console.log(message)
        }
    }

    render() {
        return (
            <EventContext.Provider
                value={{
                    loading: this.state.loading,
                    setLoading: this.setLoading,

                    showStartDatePicker: this.state.showStartDatePicker,
                    setShowStartDatePicker: this.setShowStartDatePicker,

                    showEndDatePicker: this.state.showEndDatePicker,
                    setShowEndDatePicker: this.setShowEndDatePicker,

                    /* REFACTOR START */
                    setEventId: this.setEventId,
                    eventId: this.state.eventId,

                    setStartDate: this.setStartDate,
                    startDate: this.state.startDate,
                    setStartDateNew: this.setStartDateNew,
                    startDateNew: this.state.startDateNew,

                    setEndDate: this.setEndDate,
                    endDate: this.state.endDate,
                    setEndDateNew: this.setEndDateNew,
                    endDateNew: this.state.endDateNew,

                    onAddAgendaEvent: this.onAddAgendaEvent,
                    onAddNewEvent: this.onAddNewEvent,
                    onEventOptionsPress: this.onEventOptionsPress,
                    onEventSharePress: this.onEventSharePress,

                    onUpdateEventPress: this.onUpdateEventPress,
                    onCreateEventPress: this.onCreateEventPress,

                    title: this.state.title,
                    setTitle: this.setTitle,

                    titleNew: this.state.titleNew,
                    setTitleNew: this.setTitleNew,

                    about: this.state.about,
                    setAbout: this.setAbout,

                    aboutNew: this.state.aboutNew,
                    setAboutNew: this.setAboutNew,

                    visibility: this.state.visibility,
                    emailVerified: this.state.emailVerified,
                    avatarImage: this.state.avatarImage,
                    fullName: this.state.fullName,

                    setAvatarImage: this.setAvatarImage,
                    setFullName: this.setFullName,
                    /* REFACTOR END*/

                    setUserVenueActive: this.setUserVenueActive,
                    setUserAccount: this.setUserAccount,

                    venueAddress: this.state.venueAddress,
                    venueName: this.state.venueName,

                    selectedEvent: this.state.selectedEvent,
                    setSelectedEvent: this.setSelectedEvent,

                    itemDate: this.state.itemDate,
                    setItemDate: this.setItemDate,

                    agendaEventsRemoteRequest: this.agendaEventsRemoteRequest,

                    items: this.state.items,
                    setItems: this.setItems,

                    addAgendaItems: this.addAgendaItems,

                    /* NEWEST */
                    initializeEventForm: this.initializeEventForm,

                    isDeleted: this.state.isDeleted,
                    setIsDeleted: this.setIsDeleted,
                    isUpdated: this.state.isUpdated,
                    setIsUpdated: this.setIsUpdated,

                    contributors: this.state.contributors,
                    setEventInfo: this.setEventInfo,
                }}
            >
                {this.props.children}
            </EventContext.Provider>
        )
    }
}
