import React from 'react'
import PropTypes from 'prop-types'
import {
    ActivityIndicator,
    ScrollView,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    LayoutAnimation,
} from 'react-native'

import { Feather, FontAwesome } from '@expo/vector-icons'

import Slider from 'react-native-simple-slider'
import { Layout, Icons, Buttons, Colors } from '../constants'

// From AV VideoPlayer component
//  https://github.com/expo/expo/tree/master/apps/native-component-list/src/screens
//  https://github.com/expo/expo/blob/master/apps/native-component-list/screens/AV/VideoPlayer.js

export default class VideoPlayer extends React.Component {
    static propTypes = {
        header: PropTypes.node,
        children: PropTypes.node,
        extraButtons: PropTypes.arrayOf(
            PropTypes.shape({
                iconName: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                onPress: PropTypes.func.isRequired,
                active: PropTypes.bool.isRequired,
            })
        ),

        // Functions
        playAsync: PropTypes.func.isRequired,
        pauseAsync: PropTypes.func.isRequired,
        setRateAsync: PropTypes.func.isRequired,
        setIsMutedAsync: PropTypes.func.isRequired,
        setPositionAsync: PropTypes.func.isRequired,
        setIsLoopingAsync: PropTypes.func.isRequired,

        // Note: added by maurice
        //overrideFileExtensionAndroid: 'video/mp4',
        //ignoreSilentSwitch: PropTypes.func.isRequired,
        //androidImplementation: 'MediaPlayer',
        //downloadFirst: true,
        //playbackStatusToSet: PropTypes.bool.isRequired,

        backwardAsync: PropTypes.func.isRequired,
        forwardAsync: PropTypes.func.isRequired,

        // Status
        isLoaded: PropTypes.bool.isRequired,
        isLooping: PropTypes.bool.isRequired,
        rate: PropTypes.number.isRequired,
        positionMillis: PropTypes.number.isRequired,
        durationMillis: PropTypes.number.isRequired,
        shouldCorrectPitch: PropTypes.bool.isRequired,
        isPlaying: PropTypes.bool.isRequired,

        // Note: added by maurice
        didJustFinish: PropTypes.bool.isRequired,

        posterSource: PropTypes.string,
        usePoster: PropTypes.bool,

        // Error
        errorMessage: PropTypes.string,
    }

    state = {
        userIsDraggingSlider: false,
    }

    _play = () => this.props.playAsync()

    _pause = () => this.props.pauseAsync()

    _backward = () => this.props.backwardAsync()

    _forward = () => this.props.forwardAsync()

    //_setPosition = () => this.props.setPositionAsync();

    _playFromPosition = position =>
        this.props
            .setPositionAsync(position)
            .then(() => this.setState({ userIsDraggingSlider: false }))

    _toggleIsMuted = () => this.props.setIsMutedAsync(!this.props.isMuted)

    _toggleSlowRate = () =>
        this.props.setRateAsync(
            this.props.rate < 1 ? 1 : 0.5,
            this.props.shouldCorrectPitch
        )

    _toggleFastRate = () =>
        this.props.setRateAsync(
            this.props.rate > 1 ? 1 : 2,
            this.props.shouldCorrectPitch
        )

    _renderPlayPauseButton = () => {
        let onPress = this._pause
        let iconName = 'pause'

        if (!this.props.isPlaying) {
            onPress = this._play
            iconName = 'play'
        }

        return (
            <TouchableOpacity
                style={[styles.controlButton, { width: 96 }]}
                onPress={onPress}
                disabled={!this.props.isLoaded}
            >
                {this.props.isLoaded ? (
                    <View
                        style={[
                            styles.playButtonBorder,
                            {
                                opacity:
                                    this.props.isPlaying || this.props.isImage
                                        ? 0
                                        : 1,
                            },
                        ]}
                    >
                        <FontAwesome
                            name={iconName}
                            style={[
                                styles.controlIcon,
                                {
                                    paddingLeft: 4,
                                },
                            ]}
                            size={Icons.XL}
                        />
                    </View>
                ) : (
                    <ActivityIndicator color={'white'} size={'small'} />
                )}
            </TouchableOpacity>
        )
    }

    _renderSkipForwardButton = () => {
        let onPress = this._forward
        let iconName = 'skip-forward'

        // if (!this.props.isPlaying) {
        //   onPress = this._skipForward;
        //   //iconName = 'skip-forward'; // feather
        //   iconName = 'forward'; // fontawesome
        // }

        return (
            <TouchableOpacity
                style={[styles.controlButton, { width: 56 }]}
                onPress={onPress}
                disabled={!this.props.isLoaded}
            >
                {this.props.isLoaded ? (
                    <Feather
                        name={iconName}
                        style={[
                            styles.controlIcon,
                            {
                                opacity:
                                    this.props.isPlaying || this.props.isImage
                                        ? 0
                                        : 1,
                            },
                        ]}
                        size={Icons.large}
                    />
                ) : (
                    <View />
                )}
            </TouchableOpacity>
        )
    }

    _renderSkipBackwardButton = () => {
        let onPress = this._backward
        let iconName = 'skip-back'

        // if (!this.props.isPlaying) {
        //   onPress = this._skipForward;
        //   //iconName = 'skip-forward'; // feather
        //   iconName = 'forward'; // fontawesome
        // }

        return (
            <TouchableOpacity
                style={[styles.controlButton, { width: 56 }]}
                onPress={onPress}
                disabled={!this.props.isLoaded}
            >
                {this.props.isLoaded ? (
                    <Feather
                        name={iconName}
                        style={[
                            styles.controlIcon,
                            {
                                opacity:
                                    this.props.isPlaying || this.props.isImage
                                        ? 0
                                        : 1,
                            },
                        ]}
                        size={Icons.large}
                    />
                ) : (
                    <View />
                )}
            </TouchableOpacity>
        )
    }

    _maybeRenderErrorOverlay = () => {
        if (this.props.errorMessage) {
            return (
                <ScrollView style={styles.errorMessage}>
                    <Text style={styles.errorText}>
                        {this.props.errorMessage}
                    </Text>
                </ScrollView>
            )
        }
        return null
    }

    _renderAuxiliaryButton = ({ iconName, title, onPress, active }) => (
        <TouchableOpacity
            key={title}
            style={[
                styles.button,
                //active && styles.activeButton,
                // {
                //   //opacity: this.props.isPlaying ? 0 : 1,
                // },
            ]}
            disabled={!this.props.isLoaded || this.props.isPlaying}
            onPress={onPress}
        >
            {this.props.isLoaded ? (
                <Feather
                    title={title}
                    name={iconName}
                    size={Icons.large}
                    style={[
                        styles.icon,
                        //styles.buttonIcon,
                        active && styles.activeButtonText,
                        {
                            opacity: this.props.isPlaying ? 0 : 1,
                        },
                    ]}
                />
            ) : (
                <View />
            )}
        </TouchableOpacity>
    )

    _renderAuxiliaryTextButton = ({ iconName, title, onPress, active }) => (
        <TouchableOpacity
            key={title}
            style={[
                styles.button,
                //active && styles.activeButton,
                // {
                //   //opacity: this.props.isPlaying ? 0 : 1,
                // },
            ]}
            disabled={
                !this.props.isLoaded ||
                this.props.isPlaying ||
                this.props.isImage
            }
            onPress={onPress}
        >
            {this.props.isLoaded ? (
                <Text
                    style={[
                        styles.buttonText,
                        active && styles.activeButtonText,
                        {
                            opacity:
                                this.props.isPlaying || this.props.isImage
                                    ? 0
                                    : 1,
                        },
                    ]}
                >
                    {title}
                </Text>
            ) : (
                <View />
            )}
        </TouchableOpacity>
    )

    alt_renderAuxiliaryButton = ({ iconName, title, onPress, active }) => (
        <TouchableOpacity
            key={title}
            style={[
                styles.button,
                active && styles.activeButton,
                // This "auto-centers" bottom controls. I can play around with layout on this one.
                // {
                //   alignSelf:
                //     this.props.isPlaying && active ? 'center' : 'center',
                // },
            ]}
            disabled={!this.props.isLoaded}
            onPress={onPress}
        >
            {this.props.isLoaded ? (
                <Feather
                    name={iconName}
                    size={Icons.large}
                    style={[
                        styles.icon,
                        //styles.buttonIcon,
                        active && styles.activeButtonText,
                        {
                            opacity:
                                this.props.isPlaying || this.props.isImage
                                    ? 0
                                    : 1,
                        },
                    ]}
                />
            ) : (
                <View />
            )}
        </TouchableOpacity>
    )

    render() {
        LayoutAnimation.easeInEaseOut()

        return (
            <View style={this.props.style}>
                {this.props.header}
                <View
                    style={[
                        styles.wrapper,
                        {
                            //opacity: this.props.isPlaying ? 1 : 0,
                            backgroundColor:
                                this.props.isPlaying || this.props.isImage
                                    ? 'transparent'
                                    : 'rgba(0, 0, 0, 0.64)',
                        },
                    ]}
                >
                    <View style={styles.sliderContainer}>
                        <Slider
                            sliderWidth={
                                Layout.isMediumDevice
                                    ? Layout.SCREEN_WIDTH
                                    : Layout.SCREEN_WIDTH * 0.5
                            }
                            thumbButtonSize={0}
                            sliderHeight={4}
                            sliderBorderRadius={0}
                            minimumTrackTintColor={'rgba(256, 256, 256, .72)'}
                            //minimumTrackTintColor={Colors.accentBlue}
                            maximumTrackTintColor={'rgba(256, 256, 256, .08)'}
                            //thumbTintColor={Colors.accentBlue}
                            value={
                                this.props.positionMillis
                                    ? this.props.positionMillis
                                    : 0
                            }
                            maximumValue={
                                this.props.durationMillis
                                    ? this.props.durationMillis
                                    : null
                            }
                            disabled={!this.props.isLoaded}
                            //onSlidingComplete={this._playFromPosition}
                            // onResponderGrant={() =>
                            //     this.setState({
                            //         userIsDraggingSlider: true,
                            //         positionMillisWhenStartedDragging: this
                            //             .props.positionMillis,
                            //     })
                            // }
                        />
                    </View>
                    <View style={styles.playerControls}>
                        {this._renderSkipBackwardButton()}
                        {this._renderAuxiliaryTextButton({
                            iconName: 'minus-circle',
                            title: '0.5x',
                            onPress: this._toggleSlowRate,
                            active: this.props.rate < 1,
                        })}
                        {this._renderPlayPauseButton()}
                        {this._renderAuxiliaryTextButton({
                            iconName: 'plus-circle',
                            title: '2.0x',
                            onPress: this._toggleFastRate,
                            active: this.props.rate > 1,
                        })}
                        {this._renderSkipForwardButton()}
                    </View>
                </View>

                {this._maybeRenderErrorOverlay()}
            </View>
        )
    }
}

const _formatTime = duration => {
    const paddedSecs = _leftPad(`${Math.floor(duration % 60)}`, '0', 2)
    const paddedMins = _leftPad(`${Math.floor(duration / 60)}`, '0', 2)
    if (duration > 3600) {
        return `${Math.floor(duration / 3600)}:${paddedMins}:${paddedSecs}`
    }
    return `${paddedMins}:${paddedSecs}`
}

const _leftPad = (string, padWith, expectedMinimumSize) => {
    if (string.length >= expectedMinimumSize) {
        return string
    }
    return _leftPad(`${padWith}${string}`, padWith, expectedMinimumSize)
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    slider: {
        flex: 1,
        marginHorizontal: 10,
    },
    errorMessage: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Colors.errorBackground,
    },
    errorText: {
        margin: 8,
        fontWeight: 'bold',
        color: Colors.errorText,
    },

    button: {
        width: 64,
        height: 64,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8,
    },

    buttonText: {
        fontFamily: 'neuzeit-s-bold',
        fontSize: 16,
        textAlign: 'center',
        justifyContent: 'center',
        color: Colors.inverseTextDisabled,
    },

    icon: {
        //fontSize: Icons.large,
        color: Colors.inverseTextDisabled,
    },

    activeButton: {
        backgroundColor: Colors.darkBackground,
    },

    activeButtonText: {
        color: 'white',
    },

    wrapper: {
        position: 'absolute',
        height: '100%',
        width: Layout.isMediumDevice
            ? Layout.SCREEN_WIDTH
            : Layout.SCREEN_WIDTH * 0.5,
    },

    sliderContainer: {
        position: 'absolute',
        top: 0,
        alignSelf: 'center',
        width: Layout.isMediumDevice
            ? Layout.SCREEN_WIDTH
            : Layout.SCREEN_WIDTH * 0.5,
    },

    playerControls: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        paddingBottom: Layout.isMediumDevice
            ? Layout.paddingXL
            : Layout.paddingLarge,
    },

    auxControls: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    playButtonBorder: {
        borderRadius: 40,
        borderWidth: 3,
        borderColor: Colors.white,
        justifyContent: 'center',
        alignSelf: 'center',
        width: 80,
        height: 80,
    },

    controlButton: {
        flex: 1,
        height: '100%',
        width: 64,
        zIndex: 100,
        // width: Layout.isMediumDevice
        //     ? Layout.SCREEN_WIDTH * 0.2
        //     : Layout.SCREEN_WIDTH * 0.5 * 0.2,
        justifyContent: 'center',
        //alignSelf: 'center',
    },

    controlIcon: {
        alignSelf: 'center',
        color: Colors.white,
    },
})
