import React from 'react'
import {
    ImageBackground,
    StyleSheet,
    LayoutAnimation,
    View,
    RefreshControl,
} from 'react-native'
import {
    StackActions,
    NavigationActions,
    withNavigation,
} from 'react-navigation'
import { Layout, Buttons, Colors, TextStyles, Icons } from '../constants'
import { Video } from 'expo-av'
import { BlurView } from 'expo-blur'
import { VenueStoriesConsumer } from '../context/VenueStoriesContext'
import { EventConsumer } from '../context/EventContext'
import VideoPlayer from './VideoPlayer'
import StoriesPlaylist from './StoriesPlaylist'
import ButtonIcon from './ButtonIcon'

// Navigation Actions
const resetAction = StackActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: 'VenueProfile' })],
})

class VenueStoriesPlayer extends React.Component {
    state = {
        /* Playback */
        isLoaded: false,
        isLooping: false,
        isPlaying: false,
        errorMessage: null,
        positionMillis: 0,
        durationMillis: 0,
        rate: 1,
        shouldCorrectPitch: false,
        useNativeControls: false,
        resizeMode: Video.RESIZE_MODE_COVER,
        didJustFinish: false,
        showPoster: true,

        /* State and Selection */
        playlistActive: false,
        selectedVideo: null, // Must be an empty object, or causes Android player to crash from empty string
        selectedVideoKey: '',
        selectedStoryId: '',
        selectedTheme: '',

        /* Video content states */
        isImage: false,
        isVideoUpload: false,
        selectedImage: null,
        videoThumbnail: null,
    }

    componentDidMount = async () => {
        this.setState({
            selectedVideoKey: 0,
            selectedTheme: '',
            selectedStoryId: '',
        })

        const isArchive = this.props.isArchive
        const recentPostData = this.props.recentPostData

        if (recentPostData) {
            const selectedStoryId = await recentPostData[0].storyId
            const isImage = await recentPostData[0].isImage
            const isVideoUpload = await recentPostData[0].isVideoUpload
            const videoThumbnail = await recentPostData[0].videoThumbnail
            const selectedImage = await recentPostData[0].image
            const selectedTheme = await recentPostData[0].theme
            const selectedVideo = await recentPostData[0].video

            this._setSelectedVideo({
                selectedVideo,
                selectedTheme,
                selectedStoryId,
                isImage,
                selectedImage,
                isVideoUpload,
                videoThumbnail,
            })
        } else if (isArchive === true) {
            await this.setArchiveStoryData()
        } else {
            await this.setPostData()
        }

        const eventId = this.props.eventData.eventId
        const storyData = isArchive
            ? this.context.archiveStoryData[eventId]
            : this.context.storyData[eventId]

        if (storyData) {
            const selectedStoryId = await storyData[0].storyId
            const isImage = await storyData[0].isImage
            const isVideoUpload = await storyData[0].isVideoUpload
            const videoThumbnail = await storyData[0].videoThumbnail
            const selectedImage = await storyData[0].image
            const selectedTheme = await storyData[0].theme
            const selectedVideo = await storyData[0].video

            this._setSelectedVideo({
                selectedVideo,
                selectedTheme,
                selectedStoryId,
                isImage,
                selectedImage,
                isVideoUpload,
                videoThumbnail,
            })
        } else {
            return
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const isDeleted = await this.context.isDeleted

        if (isDeleted === true) {
            await this.props.navigation.dispatch(resetAction)
            await this.context.setIsDeleted({ isDeleted: false })
        }
        // if (prevState.isDeleted !== this.context.isDeleted) {
        //     //console.log('yo mama', this.context.isDeleted)
        //     const isDeleted = this.context.isDeleted

        //     if (isDeleted === true) {
        //         this.props.navigation.dispatch(resetAction)
        //     }
        // }
    }

    setPostData = async () => {
        const eventId = await this.props.eventData.eventId
        const storyData = await this.context.storyData[eventId]

        if (storyData) {
            const videoArrayPosition = storyData.length - 1
            const end = storyData[videoArrayPosition].timestamp
            const size = await 3
            await this.context.createPostData(eventId, size, end)
        } else {
            const size = await 3
            await this.context.createPostData(eventId, size)
        }
    }

    setArchiveStoryData = async () => {
        const eventId = await this.props.eventData.eventId
        const storyData = await this.context.archiveStoryData[eventId]

        const videoArrayPosition = storyData.length - 1
        const end = storyData[videoArrayPosition].timestamp

        const size = await 3

        await this.context.createArchiveStoryData(eventId, size, end)
    }

    onPressFooter = async () => {
        const isArchive = await this.props.isArchive

        if (isArchive === true) {
            await this.setArchiveStoryData()
        } else {
            await this.setPostData()
        }
    }

    _setSelectedVideo = async ({
        selectedVideo,
        selectedTheme,
        selectedStoryId,
        isImage,
        selectedImage,
        isVideoUpload,
        videoThumbnail,
    }) => {
        this.setState({
            selectedVideo,
            selectedTheme,
            selectedStoryId,
            isImage,
            selectedImage,
            isVideoUpload,
            videoThumbnail,
        })

        this._setPositionAsync(0)
    }

    // From AV VideoPlayer
    // https://github.com/expo/expo/blob/master/apps/native-component-list/screens/AV/VideoPlayer.js

    _handleError = errorMessage => {
        this.setState({ errorMessage })
        console.log('video error message ›››', errorMessage)
    }

    _handleVideoMount = ref => {
        this._video = ref
        console.log('video ref', ref)
    }

    //_updateStateToStatus = status => this.setState(status);

    _updateStateToStatus = async status => {
        this.setState(status)
        if (status.didJustFinish) {
            await this._skipForward(status)
        }
    }

    _playAsync = async () => await this._video.playAsync()

    _pauseAsync = async () => await this._video.pauseAsync()

    _backwardAsync = async () => await this._skipBackward()

    _forwardAsync = async () => await this._skipForward()

    _setPositionAsync = async position =>
        await this._video.setPositionAsync(position)

    _setIsLoopingAsync = async isLooping =>
        await this._video.setIsLoopingAsync(isLooping)

    _setIsMutedAsync = async isMuted =>
        await this._video.setIsMutedAsync(isMuted)

    _setRateAsync = async (rate, shouldCorrectPitch) =>
        await this._video.setRateAsync(rate, shouldCorrectPitch)

    _onPlayPausePressed = () => {
        if (this.playbackInstance != null) {
            if (this.state.isPlaying) {
                this.playbackInstance.pauseAsync()
            } else {
                this.playbackInstance.playAsync()
            }
        }
    }

    _skipBackward = async () => {
        const eventId = await this.props.eventData.eventId
        const isArchive = await this.props.isArchive
        const storyData = isArchive
            ? await this.context.archiveStoryData[eventId]
            : await this.context.storyData[eventId]

        const storyDataLength = await storyData.length

        await this.setState({
            selectedVideoKey:
                (storyDataLength + this.state.selectedVideoKey - 1) %
                storyDataLength,
            // Normal direction
        })

        this._nextSelectedVideo(eventId)
    }

    _skipForward = async () => {
        const eventId = await this.props.eventData.eventId
        const isArchive = this.props.isArchive
        const storyData = isArchive
            ? this.context.archiveStoryData[eventId]
            : this.context.storyData[eventId]

        const storyDataLength = await storyData.length

        await this.setState({
            selectedVideoKey:
                (storyDataLength + this.state.selectedVideoKey + 1) %
                storyDataLength,
            // Normal direction
        })

        this._nextSelectedVideo(eventId)
    }

    _nextSelectedVideo = async eventId => {
        await this._setPositionAsync(0)

        const selectedVideoKey = this.state.selectedVideoKey

        const isArchive = this.props.isArchive
        const storyData = isArchive
            ? this.context.archiveStoryData[eventId]
            : this.context.storyData[eventId]

        const nextVideo = await storyData[selectedVideoKey].video
        const nextTheme = await storyData[selectedVideoKey].theme
        const nextStoryId = await storyData[selectedVideoKey].storyId

        const nextIsImage = await storyData[selectedVideoKey].isImage
        const nextImage = await storyData[selectedVideoKey].image
        const nextIsVideoUpload = await storyData[selectedVideoKey]
            .isVideoUpload
        const nextVideoThumbnail = await storyData[selectedVideoKey]
            .videoThumbnail

        await this._setSelectedVideo({
            selectedVideo: nextVideo,
            selectedTheme: nextTheme,
            selectedStoryId: nextStoryId,
            isImage: nextIsImage,
            selectedImage: nextImage,
            isVideoUpload: nextIsVideoUpload,
            videoThumbnail: nextVideoThumbnail,
        })

        this._playAsync(true)
    }

    _onTogglePlaylist = () => {
        {
            this.state.playlistActive
                ? this.setState({
                      playlistActive: false,
                  })
                : this.setState({
                      playlistActive: true,
                  })
        }
    }

    _renderVideo = ({
        selectedVideo,
        selectedImage,
        isImage,
        videoThumbnail,
    }) => {
        if (isImage === true) {
            return (
                <ImageBackground
                    source={{
                        uri: videoThumbnail,
                    }}
                    style={styles.video}
                    resizeMode={'cover'}
                >
                    <BlurView
                        style={styles.video}
                        intensity={100}
                        tint={'default'}
                    >
                        <ImageBackground
                            source={{
                                uri: selectedImage,
                            }}
                            style={[styles.video, { zIndex: 1000 }]}
                            resizeMode={'contain'}
                        />
                        <Video
                            style={{ display: 'none' }}
                            useNativeControls={this.state.useNativeControls}
                            ref={this._handleVideoMount}
                            source={{
                                uri:
                                    'https://firebasestorage.googleapis.com/v0/b/indvstry-io.appspot.com/o/stories%2FtrpbJ95ccoPU627p8Ki3sUNmOos1%2F2773f85c-40c6-48b7-963f-6dd40dfc27fa.mp4?alt=media&token=1d423d89-a66a-45be-a94c-73bd2d0663b0',
                            }}
                            resizeMode={this.state.resizeMode}
                            isMuted
                            onError={this._handleError}
                            progressUpdateIntervalMillis={100}
                            onPlaybackStatusUpdate={this._updateStateToStatus}
                            shouldPlay
                            //isLooping
                        />
                    </BlurView>
                </ImageBackground>
            )
        } else {
            return (
                <Video
                    //androidImplementation={'MediaPlayer'}
                    style={styles.video}
                    useNativeControls={this.state.useNativeControls}
                    ref={this._handleVideoMount}
                    source={{
                        uri: selectedVideo,
                    }}
                    resizeMode="cover"
                    rate={1.0}
                    onError={this._handleError}
                    progressUpdateIntervalMillis={100}
                    onPlaybackStatusUpdate={this._updateStateToStatus}
                    isMuted={false}
                    posterSource={{ uri: videoThumbnail }}
                    posterStyle={[
                        styles.video,
                        {
                            zIndex: -100,
                        },
                    ]}
                    usePoster={true}
                    shouldPlay
                    isLooping={false}
                />
            )
        }

        // else if (isVideoUpload === true) {
        //     return (
        //         <ImageBackground
        //             source={{
        //                 uri: videoThumbnail,
        //             }}
        //             style={styles.video}
        //             resizeMode={'cover'}
        //         >
        //             <BlurView
        //                 style={[
        //                     styles.video,
        //                     { alignItems: 'center', justifyContent: 'center' },
        //                 ]}
        //                 intensity={100}
        //                 tint={'default'}
        //             >
        //                 <Video
        //                     //style={styles.video}
        //                     style={{
        //                         width: Layout.isMediumDevice
        //                             ? Layout.SCREEN_WIDTH - Layout.paddingXL
        //                             : Layout.SCREEN_WIDTH * 0.5 -
        //                               Layout.topNavHeightDesktop * 2,

        //                         height: Layout.isMediumDevice
        //                             ? Layout.SCREEN_HEIGHT - Layout.paddingXL
        //                             : Layout.SCREEN_HEIGHT -
        //                               Layout.topNavHeightDesktop * 2,
        //                     }}
        //                     useNativeControls={this.state.useNativeControls}
        //                     ref={this._handleVideoMount}
        //                     source={{ uri: selectedVideo }}
        //                     resizeMode={'contain'}
        //                     onError={this._handleError}
        //                     progressUpdateIntervalMillis={100}
        //                     onPlaybackStatusUpdate={this._updateStateToStatus}
        //                     isMuted={false}
        //                     shouldPlay
        //                     isLooping
        //                 />
        //             </BlurView>
        //         </ImageBackground>
        //     )
        // }
    }

    render() {
        const { eventData, isArchive, recentPostData } = this.props

        return (
            <EventConsumer>
                {eventContext => (
                    <View
                        style={{
                            flex: 1,
                        }}
                        ref={ref => {
                            this.eventContext = eventContext
                        }}
                    >
                        <VenueStoriesConsumer>
                            {context => {
                                /* Temporary solution. Recent should behave like an Archive in terms of limited on screen options. */
                                const storyData = isArchive
                                    ? recentPostData
                                        ? recentPostData
                                        : context.archiveStoryData[
                                              eventData.eventId
                                          ]
                                    : context.storyData[eventData.eventId]

                                return (
                                    <View
                                        style={{ flex: 1 }}
                                        ref={ref => {
                                            this.context = context
                                        }}
                                    >
                                        <ButtonIcon
                                            onPress={this._onTogglePlaylist}
                                            iconName={
                                                this.state.playlistActive
                                                    ? 'chevron-down'
                                                    : 'layers'
                                            }
                                            buttonStyle={[
                                                Buttons.menuButtonTransparentBG,
                                                {
                                                    position: 'absolute',
                                                    zIndex: 1000,
                                                    left: Layout.paddingMedium,
                                                    bottom:
                                                        Layout.paddingMedium,
                                                },
                                            ]}
                                            iconColor={Colors.white}
                                        />
                                        <StoriesPlaylist
                                            style={{ zIndex: 100 }}
                                            isInverted={true} // is normally true
                                            setSelectedVideo={
                                                this._setSelectedVideo
                                            }
                                            storyData={storyData}
                                            eventData={eventData}
                                            onPressFooter={this.onPressFooter}
                                            refreshControl={
                                                <RefreshControl
                                                    //refreshing={this.props.loading}
                                                    refreshing={false}
                                                    onRefresh={
                                                        this.onPressFooter
                                                    }
                                                />
                                            }
                                            disabled={
                                                !this.state.playlistActive
                                            }
                                        />

                                        <VideoPlayer
                                            header={this._renderVideo({
                                                selectedVideo: this.state
                                                    .selectedVideo,
                                                selectedImage: this.state
                                                    .selectedImage,
                                                isImage: this.state.isImage,
                                                isVideoUpload: this.state
                                                    .isVideoUpload,
                                                videoThumbnail: this.state
                                                    .videoThumbnail,
                                            })}
                                            style={this.props.style}
                                            playAsync={this._playAsync}
                                            pauseAsync={this._pauseAsync}
                                            backwardAsync={this._backwardAsync}
                                            forwardAsync={this._forwardAsync}
                                            setPositionAsync={
                                                this._setPositionAsync
                                            }
                                            setIsLoopingAsync={
                                                this._setIsLoopingAsync
                                            }
                                            setIsMutedAsync={
                                                this._setIsMutedAsync
                                            }
                                            setRateAsync={this._setRateAsync}
                                            durationMillis={
                                                this.state.durationMillis
                                            }
                                            shouldCorrectPitch={
                                                this.state.shouldCorrectPitch
                                            }
                                            isPlaying={this.state.isPlaying}
                                            isLoaded={this.state.isLoaded}
                                            didJustFinish={
                                                this.state.didJustFinish
                                            }
                                            isLooping={this.state.isLooping}
                                            positionMillis={
                                                this.state.positionMillis
                                            }
                                            rate={this.state.rate}
                                            isImage={this.state.isImage}
                                        />

                                        {/* <View
                                            style={{
                                                position: 'absolute',
                                                bottom: 100,
                                                zIndex: 100,
                                                height: 72,
                                                width: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingHorizontal:
                                                    Layout.paddingLarge,
                                            }}
                                        >
                                            <StoryThemesModal
                                                noticeTitle={'Themes'}
                                                modalButtonText={
                                                    this.state.selectedTheme
                                                }
                                                navigation={
                                                    this.props.navigation
                                                }
                                                modalButtonIcon={'hash'}
                                                modalVisible={modalVisible}
                                                isPlayer={true}
                                            />
                                        </View> */}
                                    </View>
                                )
                            }}
                        </VenueStoriesConsumer>
                    </View>
                )}
            </EventConsumer>
        )
    }
}

export default withNavigation(VenueStoriesPlayer)

const styles = StyleSheet.create({
    video: {
        width: Layout.isMediumDevice
            ? Layout.SCREEN_WIDTH
            : Layout.SCREEN_WIDTH * 0.5,
        height: Layout.SCREEN_HEIGHT,
        backgroundColor: 'transparent',
    },
})
