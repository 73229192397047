import React from 'react'
import { ScrollView, View } from 'react-native'
import { VenueStoriesConsumer } from '../../context/VenueStoriesContext'
import { Layout, Colors } from '../../constants'
import VenueStoriesPlayer from '../../components/VenueStoriesPlayer'
import EventDetails from '../../components/EventDetails'

export default class VenueStories extends React.Component {
    componentDidMount = async () => {
        this.clearUrlToken()
    }

    clearUrlToken = () => {
        const { origin, pathname } = window.location
        window.history.replaceState(
            history.state,
            window.document.title,
            `${origin}${pathname}`
        )
    }

    render() {
        const eventData = this.props.navigation.getParam('eventData')
        const isArchive = this.props.navigation.getParam('isArchive')
        //const recentPostData = this.props.navigation.getParam('storyData')
        const isCreator = this.props.navigation.getParam('isCreator')

        return (
            <VenueStoriesConsumer>
                {venueStoriesContext => {
                    return (
                        <ScrollView
                            style={{
                                flex: 1,
                                backgroundColor: Colors.black,
                            }}
                            ref={ref => {
                                this.venueStoriesContext = venueStoriesContext
                            }}
                        >
                            <View style={Layout.columnContainer}>
                                <View
                                    style={[
                                        Layout.columnTwoImages,
                                        {
                                            height: Layout.isMediumDevice
                                                ? Layout.SCREEN_HEIGHT * 0.9
                                                : Layout.SCREEN_HEIGHT,
                                        },
                                    ]}
                                >
                                    <VenueStoriesPlayer
                                        loading={venueStoriesContext.loading}
                                        eventData={eventData}
                                        isArchive={isArchive}
                                        isCreator={false}
                                        navigation={this.props.navigation}
                                        viewabilityConfig={
                                            this.viewabilityConfig
                                        }
                                        //recentPostData={recentPostData}
                                    />
                                </View>

                                <View style={Layout.columnTwoImages}>
                                    <EventDetails
                                        loading={venueStoriesContext.loading}
                                        eventData={eventData}
                                        isArchive={isArchive}
                                        isCreator={false}
                                        navigation={this.props.navigation}
                                    />
                                </View>
                            </View>
                        </ScrollView>
                    )
                }}
            </VenueStoriesConsumer>
        )
    }
}
