import React from 'react'
import { ActionSheetIOS } from 'react-native'
import Venue from '../api/Venue'

export const VenueProfileContext = React.createContext()
export const VenueProfileConsumer = VenueProfileContext.Consumer

export class VenueProfileProvider extends React.Component {
    state = {
        // Firebase mount remote data
        loading: false,
        editEnabled: false,
        placeId: '',
        indvstryUrl: '',
        name: '',
        street: '',
        city: '',
        state: '',
        streetNumber: '',
        postalCode: '',
        formattedAddress: '',
        structuredAddress: '',
        openingHours: '',
        groupName: '',
        mapsUrl: '',
        members: [],
        phoneIntl: '',
        plusCode: '',
        priceLevel: '',
        types: [],
        visibility: {},
        website: '',
        about: '',
        aboutNew: '',
        social: {},
        email: '',
    }

    initializeVenueProfile = async placeId => {
        //const placeId = await Venue._getUserProfile()
        console.log('initializeVenueProfile --> placeId:', placeId)

        await this.setState({ loading: true })

        const {
            indvstryUrl,
            name,
            street,
            city,
            state,
            streetNumber,
            postalCode,
            formattedAddress,
            structuredAddress,
            openingHours,
            plusCode,
            types,
            mapsUrl,
            phoneIntl,
            website,
            priceLevel,
            about,
            social,
            email,
            members,
        } = await Venue._getVenueProfile(placeId)
        this.setState({
            placeId,
            indvstryUrl,
            name,
            street,
            city,
            state,
            streetNumber,
            postalCode,
            formattedAddress,
            structuredAddress,
            openingHours,
            plusCode,
            types,
            mapsUrl,
            phoneIntl,
            website,
            priceLevel,
            about,
            social,
            email,
            members,
        })
        console.log('VENUE-PROFILE-CONTEXT', 'INITIALIZE VENUE', this.state)

        this.setState({ loading: false })
    }

    initializeVenueProfileForm = async () => {
        await this.setState({
            editEnabled: false,
            aboutNew: this.state.about,
        })
    }

    setAbout = about => {
        this.setState({ about: about })
    }

    setAboutNew = aboutNew => {
        this.setState({ aboutNew: aboutNew })
    }

    saveAbout = async () => {
        await this.setState({ loading: true })

        const placeId = await Venue._getUserProfile()

        await Venue._addAbout({
            placeId: placeId,
            aboutNew: this.state.aboutNew,
        })
        await this.setState({ loading: false })
    }

    setEditEnabled = editEnabled => {
        this.setState({ editEnabled: editEnabled })
    }

    // onSubmitRequestForm = async () => {
    //     this.setState({ loading: true })

    //     await User._createRequestEntry({
    //         fullName: this.state.fullName,
    //         email: this.state.emailNew,
    //         placeId: this.state.placeId,
    //     })

    //     this.resetUserForms()
    //     this.setState({ loading: false })
    // }

    /* MORE OPTIONS */

    onVenueStoryOptionsPress = () => {
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: ['Cancel', 'Flag video'],
                destructiveButtonIndex: 1,
                cancelButtonIndex: 0,
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    /* destructive action */
                    // Event._deleteEvent({
                    //     eventId: this.state.eventId,
                    // })
                }
            }
        )
    }

    render() {
        return (
            <VenueProfileContext.Provider
                value={{
                    //* STATE */
                    placeId: this.state.placeId,
                    loading: this.state.loading,
                    indvstryUrl: this.state.indvstryUrl,
                    street: this.state.street,
                    city: this.state.city,
                    state: this.state.state,
                    streetNumber: this.state.streetNumber,
                    postalCode: this.state.postalCode,
                    formattedAddress: this.state.formattedAddress,
                    structuredAddress: this.state.structuredAddress,
                    openingHours: this.state.openingHours,
                    formattedAddress: this.state.formattedAddress,
                    groupName: this.state.groupName,
                    mapsUrl: this.state.mapsUrl,
                    members: this.state.members,
                    name: this.state.name,
                    phoneIntl: this.state.phoneIntl,
                    plusCode: this.state.plusCode,
                    priceLevel: this.state.priceLevel,
                    types: this.state.types,
                    visibility: this.state.visibility,
                    website: this.state.website,
                    about: this.state.about,
                    aboutNew: this.state.aboutNew,
                    editEnabled: this.state.editEnabled,

                    social: this.state.social,
                    email: this.state.email,

                    /* FUNCTIONS */
                    initializeVenueProfile: this.initializeVenueProfile,
                    initializeVenueProfileForm: this.initializeVenueProfileForm,
                    setAbout: this.setAbout,
                    setAboutNew: this.setAboutNew,
                    saveAbout: this.saveAbout,
                    setEditEnabled: this.setEditEnabled,
                }}
            >
                {this.props.children}
            </VenueProfileContext.Provider>
        )
    }
}
