import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { withNavigation } from 'react-navigation'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../constants'

class VenueStoryEmptyItem extends React.Component {
    render() {
        return (
            <View style={styles.emptyItemContainer}>
                <View style={styles.emptyItem}>
                    <View
                        style={{
                            height: Icons.XL * 2,
                            width: Icons.XL * 2,
                            borderRadius: Icons.XL,
                            borderWidth: 2,
                            borderColor: Colors.border,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            marginBottom: Layout.paddingMedium,
                        }}
                    >
                        <Feather
                            style={{
                                alignSelf: 'center',
                            }}
                            color={Colors.border}
                            size={Icons.medium}
                            name={'calendar'}
                        />
                    </View>
                    <Text
                        style={[
                            TextStyles.CaptionInverse,
                            { color: Colors.inverseTextDisabled },
                        ]}
                    >
                        No events
                    </Text>
                </View>
            </View>
        )
    }
}

export default withNavigation(VenueStoryEmptyItem)

const styles = StyleSheet.create({
    emptyItemContainer: {
        flex: 1,
        paddingBottom: Layout.TAB_BAR_HEIGHT,
    },

    emptyItem: {
        height: Layout.SCREEN_HEIGHT * 0.382,
        width: '100%',
        maxWidth: 360,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: Layout.borderRadiusMedium,
        backgroundColor: Colors.lightBackground,
    },
})
