import * as firebase from 'firebase'

/* The original from Evan Bacon, with his recommended update below */
// function uploadVideo(uri, uploadUri) {
//     return new Promise(async (res, rej) => {
//         const response = await fetch(uri)

//         const blob = await response.blob()

//         const ref = firebase.storage().ref(uploadUri)

//         const unsubscribe = ref.put(blob).on(
//             'state_changed',
//             state => {},
//             err => {
//                 unsubscribe()
//                 rej(err)
//             },
//             async () => {
//                 unsubscribe()
//                 const url = await ref.getDownloadURL()
//                 res(url)
//             }
//         )
//     })
// }

/* The Blob update */
// https://github.com/expo/expo/issues/2402#issuecomment-443726662

async function uploadVideo(uri, uploadUri) {
    // console.log('blob › uri and uploadUri', uri, uploadUri)

    const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function() {
            resolve(xhr.response)
        }
        xhr.onerror = function() {
            reject(new TypeError('Network request failed'))
        }
        xhr.responseType = 'blob'
        xhr.open('GET', uri, true)
        xhr.send(null)
    })

    // do something with the blob, eg. upload it to firebase (API v5.6.0 below)
    const ref = firebase.storage().ref(uploadUri)

    const snapshot = await ref.put(blob)
    const remoteUri = await snapshot.ref.getDownloadURL()

    // when we're done sending it, close and release the blob
    /* Note: in Chrome, blob.close() is not recognized. By disabling, function runs as intended */
    //blob.close()

    // return the result, eg. remote URI to the image
    return remoteUri
}

export default uploadVideo
