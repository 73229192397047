import React from 'react'
import { View, FlatList } from 'react-native'
import { VenueStoriesConsumer } from '../context/VenueStoriesContext'
import VenueRecentPostItem from './VenueRecentPostItem'
import { Layout } from '../constants'

export default class VenueRecentPostList extends React.Component {
    renderVenueRecentPostItem = ({ item }) => {
        return (
            <VenueRecentPostItem
                navigation={item.navigation}
                storyCursor={item.storyCursor}
                eventData={item.selectedEvent}
                storyData={item.venueStory}
            />
        )
    }

    keyExtractor = item => item.storyCursor.toString()

    render() {
        return (
            <VenueStoriesConsumer>
                {context => (
                    <View
                        ref={ref => {
                            this.context = context
                        }}
                        style={{ flex: 1 }}
                    >
                        <FlatList
                            horizontal
                            style={{
                                flex: 1,
                            }}
                            contentContainerStyle={{
                                padding: Layout.paddingLarge,
                                width: Layout.isMediumDevice
                                    ? Layout.SCREEN_WIDTH
                                    : '100%',
                            }}
                            data={context.venueRecentEventPost}
                            renderItem={this.renderVenueRecentPostItem}
                            keyExtractor={this.keyExtractor}
                            showsVerticalScrollIndicator={false}
                            navigation={this.props.navigation}
                            initialNumToRender={3}
                        />
                    </View>
                )}
            </VenueStoriesConsumer>
        )
    }
}
