import React from 'react'
import { Alert, ActionSheetIOS } from 'react-native'
import Story from '../api/Story'
import Event from '../api/Event'
import moment from 'moment'
import Venue from '../api/Venue'

export const VenueStoriesContext = React.createContext()
export const VenueStoriesConsumer = VenueStoriesContext.Consumer

export class VenueStoriesProvider extends React.Component {
    state = {
        // Firebase mount remote data
        loading: false,

        setEventIds: '',
        eventsCursor: '',
        venueEvents: [],
        storyCursor: '',
        data: {},
        setPostIds: [],
        storyData: [],
        venueStory: [],

        // MOST RECENT
        venueRecentEvents: [],
        recentEventId: '',

        venueRecentEventPost: [],

        /* Most recent stories */
        venueMostRecentStories: [],

        /* Venue Archive */
        setArchiveEventIds: '',
        archiveEventsCursor: '',
        venueArchiveEvents: [],
        archiveStoryCursor: '',
        archiveData: '',
        setArchivePostIds: '',
        archiveStoryData: [],
        venueArchiveStory: [],

        /* Venue Team Members */
        placeId: '',

        /* Managing state updates between routes */
        isFlagged: false,
        isDeleted: false,
        isUpdated: false,
    }

    initializeVenueStories = async () => {
        await this.setState({
            setEventIds: '',
            eventsCursor: '',
            venueData: {},
            data: {},
            venueEvents: [],
            storyCursor: '',
            setPostIds: [],
            storyData: [],
            venueStory: [],
            venueMostRecentStories: [],
            setArchiveEventIds: '',
            archiveEventsCursor: '',
            venueArchiveEvents: [],
            archiveStoryCursor: '',
            archiveData: '',
            setArchivePostIds: '',
            archiveStoryData: [],
            venueArchiveStory: [],
            isFlagged: false,
            isDeleted: false,
            isUpdated: false,
        })
    }

    /* For web public view */
    setVenuePlaceId = placeId => {
        this.setState({ placeId: placeId })
    }

    setIsFlagged = isFlagged => {
        this.setState({ isFlagged: isFlagged })
    }

    setIsDeleted = isDeleted => {
        this.setState({ isDeleted: isDeleted })
    }

    setIsUpdated = isUpdated => {
        this.setState({ isUpdated: isUpdated })
    }
    /**
     *
     *  STORIES FUNCTIONS
     *
     */

    setLoading = loading => {
        this.setState({ loading: loading })
    }

    setPostData = storyData => {
        this.setState({ storyData: storyData })
    }

    setSelectedVideo = selectedVideo => {
        this.setState({ selectedVideo: selectedVideo })
    }

    setSelectedVideoKey = selectedVideoKey => {
        this.setState({ selectedVideoKey: selectedVideoKey + 1 })
    }

    setEventsCursor = eventsCursor => {
        this.setState({ eventsCursor: eventsCursor })
    }

    /* FUNCTION TO RESOLVE HARD REFRESH OF PAGE */
    setVenueEvents = venueEvents => {
        this.setState({ venueEvents: venueEvents })
    }

    addVenueStory = async (groupedVideos, storyCursor) => {
        await this.setState(previousState => {
            let data = {
                ...previousState.data,
                ...previousState.storyCursor,
                ...storyCursor,
                ...groupedVideos,
            }

            return {
                data,
                storyData: data,
            }
        })

        //console.log('VENUE-CONTEXT: ADD-VENUE-STORY:', this.state.storyData)
    }

    /* This adds VenueStory for the overarching vertical scroll list */
    createPostData = async (eventId, size, end) => {
        const {
            setPostIds,
            venueStory,
            storyCursor,
        } = await Story.venueStoryPaged({
            selectedEventId: eventId,
            size,
            end,
            placeId: this.state.placeId,
        })

        /**
         *
         * This updated function checks for duplicates and prevents them from being added.
         *
         * */
        // for (const selectedPostId of setPostIds) {
        //     if (this.state.setPostIds.includes(selectedPostId) === false) {
        //         /* Iteratively add to array of eventIds. This is needed to query Firebase for event-specific story. */
        //         await this.setState(prevState => ({
        //             setPostIds: [...prevState.setPostIds, ...setPostIds],
        //         }))

        //         console.log(
        //             selectedPostId,
        //             '✓✓✓✓✓✓✓✓✓✓✓✓✓✓✓ PASS: NO DUPLICATE story-IDS ✓✓✓✓✓✓✓✓✓✓✓✓✓✓✓'
        //         )
        //     } else {
        //         console.log(
        //             '@@@@@@@@@@@@@@@ FAIL: DUPLICATE story-IDS @@@@@@@@@@@@@@@'
        //         )
        //         return
        //     }
        // }

        this.setState(prevState => ({
            venueStory: [...prevState.venueStory, ...venueStory],
        }))

        function groupBy(objectArray, property) {
            return objectArray.reduce(function(acc, obj) {
                var key = obj[property]
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(obj)
                return acc
            }, {})
        }

        const groupedVideos = await groupBy(this.state.venueStory, 'eventId')

        this.addVenueStory(groupedVideos, storyCursor)
    }

    //** ADD VENUE EVENTS **//
    addVenueEvents = venueEvents => {
        this.setState(previousState => {
            let venueData = {
                ...previousState.venueData,
                ...venueEvents,
            }

            return {
                venueData,
                // Sort the venueEvents by timestamp
                venueEvents: Object.values(venueData).sort(
                    (a, b) => a.startDate > b.startDate
                ),
            }
        })
    }

    /**
     *
     *  Primary function to get events
     *
     **/

    venueEventsRemoteRequest = async (size, placeId) => {
        // If we are currently getting events, then bail out..
        // if (this.state.loading) {
        //     return
        // }
        // this.setState({ loading: true })

        /* Function to clear existing data and refresh for each venue */

        //this.setState({ venueEvents: [] })

        if (!this.state.eventsCursor) {
            const TODAY = new Date(moment().startOf('day'))
            await this.setState({
                eventsCursor: TODAY,
            })
        }

        // The data prop will be an array of events, the eventsCursor will be used for pagination.
        const {
            setEventIds,
            venueEvents,
            eventsCursor,
        } = await Event._venueEventsPaged({
            size, //: 1, // currently equals 1 = EVENTS_PAGE_SIZE,
            //placeId: this.state.placeId,
            placeId,
            end: this.state.eventsCursor,
        })

        //console.log('VENUE-CONTEXT: VENUE-EVENTS ›››', venueEvents)

        /**
         *
         * EventsCursor is what sets the query range. The cursor moves forward across event dates. When it gets to the end of a query, it sets the new cursor.
         * For instance, if the only existing event at the time of query is 3 months from now, it would miss events before that date. It basically creates a reset.
         *
         */

        if (eventsCursor.length === 0 || setEventIds.length === 0) {
            const TODAY = new Date(moment().startOf('day'))
            await this.setState({ eventsCursor: TODAY })
        } else {
            await this.setState({
                eventsCursor,
            })
        }

        /* Original way */
        // await this.setState({
        //     eventsCursor,
        // })

        /**
         *
         * This updated function checks for duplicates and prevents them from being added.
         * For now, context is the best place to do this check, not in Event.js, since the array of eventIds is being gathered here in state.
         *
         * */
        for (const selectedEventId of setEventIds) {
            if (this.state.setEventIds.includes(selectedEventId) === false) {
                /* Iteratively add to array of eventIds. This is needed to query Firebase for event-specific story. */
                await this.setState(prevState => ({
                    setEventIds: [...prevState.setEventIds, ...setEventIds],
                }))

                console.log(
                    selectedEventId,
                    '✓✓✓✓✓✓✓✓✓✓✓✓✓✓✓ PASS: NO DUPLICATE EVENT-IDS ✓✓✓✓✓✓✓✓✓✓✓✓✓✓✓'
                )
            } else {
                console.log(
                    '@@@@@@@@@@@@@@@ FAIL: DUPLICATE EVENT-IDS @@@@@@@@@@@@@@@'
                )
                this.setState({
                    loading: false,
                })
                return
            }
        }

        /**
         * This correctly increases the size of the array and adds new story.
         **/
        this.setState(prevState => ({
            venueEvents: [...prevState.venueEvents, ...venueEvents],
        }))

        // Finish loading, this will stop the refreshing animation.
        this.setState({ loading: false })
    }

    /**
     *
     *
     *  MOST RECENT
     *
     *
     **/
    venueRecentEventPostRemoteRequest = async placeId => {
        // If we are currently getting events, then bail out.

        if (this.state.loading) {
            return
        }

        this.setState({ loading: true })

        const venueRecentEventPost = []

        const {
            venueStory,
            storyCursor,
            storyEventId,
        } = await Story.venueRecentPostPaged({
            //selectedEventId: this.state.recentEventId,
            //placeId: this.state.placeId,
            placeId,
            size: 1, // size, //STORY_PAGE_SIZE,
            end: '', //selectedStoryCursor,
        })

        // console.log(
        //     '::::::::::::::::::::: VENUE-RECENT-RR ::::::::',
        //     storyEventId
        // )

        const {
            //setEventIds,
            venueRecentEvent,
            //eventsCursor,
        } = await Event._venueRecentEventsPaged({
            //size: 1//EVENTS_PAGE_SIZE,
            //eventId: this.state.recentEventId,
            eventId: storyEventId,
            //placeId: this.state.placeId,

            //end: eventsCursor,
            //end: this.state.eventsCursor,
        })

        if (venueStory.length) {
            venueRecentEventPost.push({
                selectedEvent:
                    venueRecentEvent[
                        (venueRecentEvent.length - 1) % venueRecentEvent.length
                    ],
                // This downloads last story
                // venueStory:
                //   venueStory[(venueStory.length - 1) % venueStory.length],

                // 2. This downloads all stories
                venueStory,
                storyCursor,
            })

            // console.log(
            //     'VENUE-CONTEXT',
            //     'VENUE-RECENT-EVENT-STORY',
            //     ': ✓✓✓ :',
            //     venueRecentEventPost
            // )
        }

        this.setState({ venueRecentEventPost })

        // Finish loading, this will stop the refreshing animation.
        this.setState({ loading: false })
    }

    venueMostRecentStoriesRemoteRequest = async (placeId, size) => {
        // If we are currently getting events, then bail out.

        if (this.state.loading) {
            return
        }

        this.setState({ loading: true })

        //const venueMostRecentStories = []

        const {
            venueStory,
            storyCursor,
            storyEventId,
        } = await Story.venueRecentPostPaged({
            placeId,
            size,
        })

        // console.log(
        //     '::::::::::::::::::::: VENUE-RECENT-RR ::::::::',
        //     storyEventId
        // )

        /* The above query gives me the most recent stories, based on query size */

        /* This provide event data for the given story */
        // const {
        //     //setEventIds,
        //     venueRecentEvent,
        //     //eventsCursor,
        // } = await Event._venueRecentEventsPaged({
        //     //size: 1//EVENTS_PAGE_SIZE,
        //     //eventId: this.state.recentEventId,
        //     eventId: storyEventId,
        //     //placeId: this.state.placeId,

        //     //end: eventsCursor,
        //     //end: this.state.eventsCursor,
        // })

        /* This isolates the most recent story with event data */

        // if (venueStory.length) {
        //     venueMostRecentStories.push({
        //         selectedEvent:
        //             venueRecentEvent[
        //                 (venueRecentEvent.length - 1) % venueRecentEvent.length
        //             ],
        //         // This downloads last story
        //         // venueStory:
        //         //   venueStory[(venueStory.length - 1) % venueStory.length],

        //         // 2. This downloads all stories
        //         venueStory,
        //         storyCursor,
        //         storyEventId
        //     })

        //     // console.log(
        //     //     'VENUE-CONTEXT',
        //     //     'VENUE-RECENT-EVENT-STORY',
        //     //     ': ✓✓✓ :',
        //     //     venueRecentEventPost
        //     // )
        // }

        //this.setState({ venueRecentEventPostGroup })

        this.setState(prevState => ({
            venueMostRecentStories: [
                ...prevState.venueMostRecentStories,
                ...venueStory,
            ],
        }))

        console.log(
            'VENUE-STORIES-CONTEXT: MOST RECENT STORIES',
            this.state.venueMostRecentStories
        )

        // Finish loading, this will stop the refreshing animation.
        this.setState({ loading: false })
    }

    /**
     *
     *
     *  ARCHIVE
     *
     *
     **/

    addVenueArchiveStory = async (groupedVideos, archiveStoryCursor) => {
        await this.setState(previousState => {
            let archiveData = {
                ...previousState.archiveData,
                ...previousState.archiveStoryCursor,
                ...archiveStoryCursor,
                ...groupedVideos,
            }

            return {
                archiveData,
                archiveStoryData: archiveData,
            }
        })
    }

    createArchiveStoryData = async (eventId, size, end) => {
        const {
            //setArchivePostIds,
            venueArchiveStory,
            archiveStoryCursor,
        } = await Story.venueArchiveStoryPaged({
            selectedEventId: eventId,
            size,
            end,
        })

        this.setState(prevState => ({
            venueArchiveStory: [
                ...prevState.venueArchiveStory,
                ...venueArchiveStory,
            ],
        }))

        function groupBy(objectArray, property) {
            return objectArray.reduce(function(acc, obj) {
                var key = obj[property]
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(obj)
                return acc
            }, {})
        }

        const groupedVideos = await groupBy(
            this.state.venueArchiveStory,
            'eventId'
        )

        this.addVenueArchiveStory(groupedVideos, archiveStoryCursor)
    }

    venueArchiveRemoteRequest = async size => {
        // If we are currently getting events, then bail out..
        // if (this.state.loading) {
        //     return
        // }
        this.setState({ loading: true })

        if (!this.state.archiveEventsCursor) {
            const TODAY = new Date(moment().startOf('day'))
            await this.setState({
                archiveEventsCursor: TODAY,
            })
        }

        // The data prop will be an array of events, the archiveEventsCursor will be used for pagination.
        const {
            setArchiveEventIds,
            venueArchiveEvents,
            archiveEventsCursor,
        } = await Event._venueArchiveEventsPaged({
            size,
            end: this.state.archiveEventsCursor,
        })

        /**
         *
         * EventsCursor is what sets the query range. The cursor moves forward across event dates. When it gets to the end of a query, it sets the new cursor.
         * For instance, if the only existing event at the time of query is 3 months from now, it would miss events before that date. It basically creates a reset.
         *
         */

        if (
            archiveEventsCursor.length === 0 ||
            setArchiveEventIds.length === 0
        ) {
            const TODAY = new Date(moment().startOf('day'))
            await this.setState({
                archiveEventsCursor: TODAY,
            })
        } else {
            await this.setState({
                archiveEventsCursor,
            })
        }

        /**
         *
         * This updated function checks for duplicates and prevents them from being added.
         * For now, context is the best place to do this check, not in Event.js, since the array of eventIds is being gathered here in state.
         *
         * */

        for (const selectedEventId of setArchiveEventIds) {
            if (
                this.state.setArchiveEventIds.includes(selectedEventId) ===
                false
            ) {
                /* Iteratively add to array of eventIds. This is needed to query Firebase for event-specific story. */
                await this.setState(prevState => ({
                    setArchiveEventIds: [
                        ...prevState.setArchiveEventIds,
                        ...setArchiveEventIds,
                    ],
                }))

                console.log(
                    selectedEventId,
                    '✓✓✓✓✓✓✓✓✓✓✓✓✓✓✓ PASS: NO DUPLICATE EVENT-IDS ✓✓✓✓✓✓✓✓✓✓✓✓✓✓✓'
                )
            } else {
                console.log(
                    '@@@@@@@@@@@@@@@ FAIL: DUPLICATE EVENT-IDS @@@@@@@@@@@@@@@'
                )
                this.setState({
                    loading: false,
                })
                return
            }
        }

        /* Original way */

        /* Iteratively add to array of eventIds. This is needed to query Firebase for event-specific story. */
        // await this.setState(prevState => ({
        //     setArchiveEventIds: [...prevState.setArchiveEventIds, ...setArchiveEventIds],
        // }))

        /**
         * This correctly increases the size of the array and adds new story.
         **/
        this.setState(prevState => ({
            venueArchiveEvents: [
                ...prevState.venueArchiveEvents,
                ...venueArchiveEvents,
            ],
        }))

        // Finish loading, this will stop the refreshing animation.
        this.setState({
            loading: false,
        })
    }

    /* MORE OPTIONS */

    onVenueStoryOptionsPress = selectedVideoToFlag => {
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: ['Cancel', 'Flag current video'],
                destructiveButtonIndex: 1,
                cancelButtonIndex: 0,
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    /* destructive action */
                    Alert.alert(
                        '🚫 Is this content abusive or inappropriate?',

                        'Flagging this video will alert our team about misuse. This content will be subject to immediate review.',
                        [
                            {
                                text: 'Yes, flag this content',
                                onPress: () =>
                                    Story._flagSelectedVideo(
                                        selectedVideoToFlag
                                    ).then(() =>
                                        this.setState({
                                            isFlagged: true,
                                        })
                                    ),
                                style: 'destructive',
                            },

                            {
                                text: 'Cancel',
                                onPress: () => {
                                    console.log('Cancel Pressed')
                                },
                                style: 'cancel',
                            },
                        ]
                    )
                }
            }
        )
    }

    render() {
        return (
            <VenueStoriesContext.Provider
                value={{
                    /* PlaceId for web */
                    placeId: this.state.placeId,
                    setVenuePlaceId: this.setVenuePlaceId,

                    // Global states
                    loading: this.state.loading,
                    setLoading: this.setLoading,

                    // Most recent
                    venueRecentEventPostRemoteRequest: this
                        .venueRecentEventPostRemoteRequest,

                    venueRecentEventPost: this.state.venueRecentEventPost,

                    /* new */
                    venueMostRecentStories: this.state.venueMostRecentStories,
                    venueMostRecentStoriesRemoteRequest: this
                        .venueMostRecentStoriesRemoteRequest,

                    // reset cursor
                    eventsCursor: this.state.eventsCursor,
                    setEventsCursor: this.setEventsCursor,

                    getVenueStoryCursor: this.getVenueStoryCursor,

                    selectedVideoKey: this.state.selectedVideoKey,
                    setSelectedVideoKey: this.setSelectedVideoKey,

                    selectedVideo: this.state.selectedVideo,
                    setSelectedVideo: this.setSelectedVideo,

                    // Just venueEvents data
                    venueEventsRemoteRequest: this.venueEventsRemoteRequest,
                    venueEvents: this.state.venueEvents,
                    setVenueEvents: this.setVenueEvents,
                    storyData: this.state.storyData,
                    venueStory: this.state.venueStory,

                    onVenueStoryOptionsPress: this.onVenueStoryOptionsPress,
                    createPostData: this.createPostData,
                    setPostData: this.setPostData,
                    initializeVenueStories: this.initializeVenueStories,

                    /* Venue Archive */
                    archiveEventsCursor: this.state.archiveEventsCursor,
                    venueArchiveEvents: this.state.venueArchiveEvents,
                    venueArchiveRemoteRequest: this.venueArchiveRemoteRequest,
                    venueArchiveStory: this.state.venueArchiveStory,
                    createArchiveStoryData: this.createArchiveStoryData,
                    archiveStoryData: this.state.archiveStoryData,

                    isFlagged: this.state.isFlagged,
                    setIsFlagged: this.setIsFlagged,
                    isDeleted: this.state.isDeleted,
                    setIsDeleted: this.setIsDeleted,
                    isUpdated: this.state.isUpdated,
                    setIsUpdated: this.setIsUpdated,
                }}
            >
                {this.props.children}
            </VenueStoriesContext.Provider>
        )
    }
}
