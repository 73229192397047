import React from 'react'
import {
    ImageBackground,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native'
import { withNavigation } from 'react-navigation'
import moment from 'moment'
import { Colors, TextStyles, Layout, Icons } from '../constants'
import { VenueStoriesConsumer } from '../context/VenueStoriesContext'
import EventBar from './EventBar'
import EventDateWidget from './EventDateWidget'

class VenueStoryItem extends React.Component {
    state = {
        selectedVideoThumbnail: 'https://',
    }

    // componentDidMount = async () => {
    //     const eventId = await this.props.eventData.eventId
    //     const size = await 1
    //     this.context.createPostData(eventId, size)

    //     const storyData = await this.context.storyData[eventId]

    //     if (storyData) {
    //         const selectedVideoThumbnail = await storyData[0].videoThumbnail
    //         this.setState({ selectedVideoThumbnail })
    //     } else {
    //         return
    //     }
    // }

    componentDidMount = async () => {
        const eventId = this.props.eventData.eventId
        const size = await 1
        await this.context.createPostData(eventId, size)

        const storyData = await this.context.storyData[eventId]

        console.log(storyData)

        if (storyData) {
            const selectedVideoThumbnail = await storyData[0].videoThumbnail
            await this.setState({ selectedVideoThumbnail })
        } else {
            return
        }
    }

    render() {
        const eventData = this.props.eventData

        const { title, venueName } = this.props.eventData

        const startDate = moment.utc(
            this.props.eventData.startDate.seconds * 1000
        )

        return (
            <VenueStoriesConsumer>
                {context => {
                    const storyData = context.storyData[eventData.eventId]

                    return (
                        <View
                            style={{ flex: 1 }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            {storyData ? (
                                <View style={styles.container}>
                                    <TouchableOpacity
                                        underlayColor={Colors.white}
                                        style={{
                                            flex: 1,
                                        }}
                                        onPress={async () => {
                                            await this.props.navigation.navigate(
                                                'VenueStories',
                                                {
                                                    isArchive: false,
                                                    navigation: this.props
                                                        .navigation,
                                                    eventData: this.props
                                                        .eventData,
                                                    isCreator: this.props
                                                        .isCreator,
                                                }
                                            )
                                        }}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <ImageBackground
                                                source={{
                                                    uri: this.state
                                                        .selectedVideoThumbnail,
                                                }}
                                                style={styles.video}
                                                resizeMode={'cover'}
                                            >
                                                <View
                                                    style={{
                                                        //flex: 1,
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'space-between',
                                                        zIndex: 100,
                                                        padding:
                                                            Layout.paddingMedium,
                                                    }}
                                                >
                                                    <EventDateWidget
                                                        startDate={startDate}
                                                    />
                                                    {/* <AddStoryButton
                                                        navigation={
                                                            this.props
                                                                .navigation
                                                        }
                                                        eventData={eventData}
                                                    /> */}
                                                </View>
                                            </ImageBackground>

                                            <EventBar
                                                navigation={
                                                    this.props.navigation
                                                }
                                                eventData={eventData}
                                                title={title}
                                                venueName={venueName}
                                            />
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            ) : (
                                <View />
                            )}
                        </View>
                    )
                }}
            </VenueStoriesConsumer>
        )
    }
}

export default withNavigation(VenueStoryItem)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        minHeight: 360,
        height: Layout.isMediumDevice
            ? Layout.SCREEN_HEIGHT * 0.618
            : Layout.SCREEN_HEIGHT * 0.5,
        maxWidth: Layout.isMediumDevice ? Layout.SCREEN_WIDTH : 360,
        minWidth: Layout.isMediumDevice ? '100%' : 360,
        overflow: 'hidden',
        paddingBottom: Layout.paddingLarge,
    },

    video: {
        flex: 1,
        backgroundColor: Colors.lightBackground,
        borderRadius: Layout.borderRadiusMedium,
        overflow: 'hidden',
    },
})
