import React from 'react'
import { TouchableOpacity, StyleSheet, Image, View } from 'react-native'
import { Colors, Icons, Layout } from '../constants'
import { BlurView } from 'expo-blur'

import User from '../api/User'

export default class Avatar extends React.Component {
    state = {
        avatar: 'https://',
    }

    componentDidMount = async () => {
        const avatar = await User._getAvatar(this.props.userId)

        this.setState({ avatar })
    }

    onPress = () => {
        this.props.onPress &&
            this.props.onPress({
                userProfile: this.props.userProfile,
            })
    }

    render() {
        let style = [styles.container]
        if (this.props.disabled) {
            style.push(styles.disabledButton)
        }

        return (
            <View style={[styles.container, this.props.style]}>
                <TouchableOpacity
                    style={[style, this.props.buttonStyle]}
                    //disabled={this.props.disabled}
                    //onPressIn={this.props.onPressIn}
                    onPress={this.props.onPress}
                    //underlayColor={Colors.highlightColor}
                    disabled
                >
                    <View style={{ flex: 1 }}>
                        <BlurView intensity={100} style={styles.blur}>
                            <Image
                                source={{
                                    uri: this.state.avatar,
                                }}
                                style={styles.avatar}
                            />
                        </BlurView>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    blur: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: Icons.XL,
        opacity: 1,
        padding: 4,
        zIndex: 10,
    },

    avatar: {
        //alignSelf: 'center',
        height: Icons.XL,
        width: Icons.XL,
        borderRadius: Icons.XL * 0.5,
        resizeMode: 'cover',
    },

    disabledButton: {
        backgroundColor: Colors.disabled,
    },
})
