const neighborhoods = [
    {
        image:
            'https://firebasestorage.googleapis.com/v0/b/indvstry-io.appspot.com/o/stories%2FtrpbJ95ccoPU627p8Ki3sUNmOos1%2Ff92bdee5-a458-4c15-a6b1-64a3a978dc3b.jpg?alt=media&token=4283405f-4fc7-4602-9af3-08af83b883b5',
        title: 'North Beach',
        placeId: 'ChIJWXgajx-BhYAR7LnSp9kfSzg',
        screen: 'FamilyProfile',
        heroImage: require('../assets/images/slider_one.jpg'),
    },
    {
        image:
            'https://firebasestorage.googleapis.com/v0/b/indvstry-io.appspot.com/o/stories%2FtrpbJ95ccoPU627p8Ki3sUNmOos1%2F77e8aea2-6945-4769-99cd-f68a993b6659.jpg?alt=media&token=d5b3c377-d0fb-4cc1-b2d4-b756d043f689',
        title: 'Mission District',
        placeId: 'ChIJr-EMFjd-j4ARHKaF3ehlmgY',
        screen: 'CentralProfile',
        heroImage: require('../assets/images/shangyou-shi-VdRex9AiBOc-unsplash.jpg'),
    },
    {
        image:
            'https://firebasestorage.googleapis.com/v0/b/indvstry-io.appspot.com/o/stories%2FtrpbJ95ccoPU627p8Ki3sUNmOos1%2F2d701071-156c-4b8a-8438-85b1f3aa3823.jpg?alt=media&token=671be75e-4c40-49b5-8a8e-4ed28d7daa80',
        title: 'Cow Hollow',
        placeId: 'ChIJL66ohdCAhYARO3o0IhzIc8U',
        screen: 'CrennProfile',
        heroImage: require('../assets/images/sasha-stories-XVTTK3wcYME-unsplash.jpg'),
    },
]

export default {
    neighborhoods,
}
