import React from 'react'
import { FlatList, Image, ImageBackground, Text, View } from 'react-native'
import { Colors, TextStyles, Layout, Buttons } from '../constants'
import { VenueProfileConsumer } from '../context/VenueProfileContext'
import Button from './Button'
import ButtonIcon from './ButtonIcon'

function Item({ image, title, placeId, screen, navigation }) {
    return (
        // <Image
        //     source={image}
        //     style={{
        //         backgroundColor: Colors.gray0,
        //         width: Layout.isTabletDevice
        //             ? Layout.SCREEN_WIDTH
        //             : Layout.SCREEN_WIDTH / 3,
        //         height: Layout.isTabletDevice
        //             ? Layout.SCREEN_WIDTH
        //             : Layout.SCREEN_WIDTH / 3,
        //     }}
        //     resizeMode={'cover'}
        // />

        <ImageBackground
            source={image}
            style={{
                backgroundColor: Colors.gray0,
                width: Layout.isTabletDevice
                    ? Layout.SCREEN_WIDTH
                    : Layout.SCREEN_WIDTH / 3 - Layout.paddingXL / 3,
                height: Layout.isTabletDevice
                    ? Layout.SCREEN_HEIGHT * 0.382
                    : Layout.SCREEN_WIDTH * 0.236,
            }}
            resizeMode={'cover'}
        >
            {/* <View style={Layout.contentOverlayImage}> */}
            {/* <Text
                    style={[
                        TextStyles.Heading2Inverse,
                        {
                            textAlign: 'center',
                        },
                    ]}
                >
                    {title}
                </Text>
                <Button
                    navigation={navigation}
                    onPress={() => {
                        navigation.navigate(screen, {
                            indvstryUrl: ':indvstryUrl',
                            placeId: placeId,
                            active: false,
                        })
                    }}
                    // onPress={async () =>
                    //     await venueProfileContext
                    //         .setVenuePlaceId(placeId)
                    //         .then(
                    //             navigation.navigate(
                    //                 'VenueProfile',
                    //                 {
                    //                     indvstryUrl: ':indvstryUrl',
                    //                     //placeId: placeId,
                    //                     active: false,
                    //                 }
                    //             )
                    //         )
                    // }
                    buttonStyle={[
                        Buttons.buttonUnderline,
                        {
                            alignSelf: 'center',
                        },
                    ]}
                    textColor={Colors.inverseText}
                    textStyle={{
                        textDecorationLine: 'underline',
                    }}
                    title={'Explore'}
                /> */}
            {/* </View> */}
        </ImageBackground>
    )
}

export default class ImageCarouselHero extends React.Component {
    state = {
        currentIndex: 0,
    }

    onScroll = async event => {
        const pageXOffset = await event.nativeEvent.contentOffset.x

        const scrollIndex = pageXOffset / Layout.SCREEN_WIDTH

        this.setState({ currentIndex: Math.floor(scrollIndex) })
    }

    scrollToPrevious = async () => {
        const carouselLength = Layout.isMediumDevice
            ? this.props.imageData.length
            : Math.ceil(this.props.imageData.length / 3)

        await this.setState({
            currentIndex:
                (carouselLength + this.state.currentIndex - 1) % carouselLength,
        })

        // console.log('currentIndex', this.state.currentIndex)
        // console.log(
        //     'scrollPreviousIndex',
        //     (carouselLength + this.state.currentIndex - 1) % carouselLength
        // )

        this.flatListRef.scrollToOffset({
            animated: true,
            offset: Layout.SCREEN_WIDTH * this.state.currentIndex,
        })
    }

    scrollToNext = async () => {
        const carouselLength = Layout.isMediumDevice
            ? this.props.imageData.length
            : Math.ceil(this.props.imageData.length / 3)

        await this.setState({
            currentIndex:
                (carouselLength + this.state.currentIndex + 1) % carouselLength,
        })

        // console.log('currentIndex', this.state.currentIndex)

        // console.log(
        //     'scrollNextIndex',
        //     (carouselLength + this.state.currentIndex + 1) % carouselLength
        // )
        this.flatListRef.scrollToOffset({
            animated: true,
            offset: Layout.SCREEN_WIDTH * this.state.currentIndex,
        })
    }

    render() {
        return (
            <View style={Layout.bannerHeaderContainer}>
                <FlatList
                    ref={ref => {
                        this.flatListRef = ref
                    }}
                    data={this.props.imageData}
                    keyExtractor={item => item.timestamp.toString()}
                    renderItem={({ item }) => (
                        <Item
                            image={item.videoThumbnail}
                            eventId={item.eventId}
                            placeId={item.placeId}
                            //screen={item.screen}
                            navigation={this.props.navigation}
                        />
                    )}
                    ItemSeparatorComponent={() => (
                        <View
                            style={{
                                width: Layout.isMediumDevice ? 0 : 1,
                            }}
                        />
                    )}
                    //onScroll={event => this.onScroll(event)}
                    decelerationRate="fast"
                    style={{
                        width: Layout.isMediumDevice
                            ? Layout.SCREEN_WIDTH
                            : Layout.SCREEN_WIDTH - Layout.paddingXL,
                        borderRadius: Layout.isMediumDevice
                            ? 0
                            : Layout.borderRadiusSmall,
                    }}
                    contentContainerStyle={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    horizontal
                    pagingEnabled
                    showsHorizontalScrollIndicator={false}
                />

                {/* Temporary solution since only 3 total neighborhoods */}
                {/* {Layout.isMediumDevice ? (
                        <View style={Buttons.carouselButtonContainer}>
                            <ButtonIcon
                                iconName={'chevron-left'}
                                buttonStyle={[
                                    Buttons.carouselButton,
                                    {
                                        borderRightWidth: Layout.isMediumDevice
                                            ? 1
                                            : 0,
                                        borderColor: Colors.gray0,
                                    },
                                ]}
                                onPress={this.scrollToPrevious}
                            />
                            <ButtonIcon
                                iconName={'chevron-right'}
                                buttonStyle={Buttons.carouselButton}
                                onPress={this.scrollToNext}
                            />
                        </View>
                    ) : (
                        <View />
                    )} */}
            </View>
        )
    }
}
