import React from 'react'
import { ImageBackground, StyleSheet, Text, View } from 'react-native'
import { withNavigation } from 'react-navigation'
import { Colors, TextStyles, Layout, Icons } from '../constants'
import { Feather } from '@expo/vector-icons'

import User from '../api/User'

class VenueMemberItem extends React.Component {
    state = {
        avatar: 'https://',
        username: '',
    }

    componentDidMount = async () => {
        const avatar = await User._getAvatar(this.props.userId)
        const username = await User._getUsername(this.props.userId)

        this.setState({ avatar: avatar, username: username })
    }

    onPress = () => {
        this.props.onPress &&
            this.props.onPress({
                selectedUser: this.props.selectedUser,
            })
    }

    render() {
        return (
            <View style={[styles.container, this.props.containerStyle]}>
                <View style={styles.wrapper}>
                    <ImageBackground
                        source={{
                            uri: this.state.avatar,
                        }}
                        style={styles.avatar}
                        resizeMode={'cover'}
                    >
                        {this.state.avatar ? (
                            <View />
                        ) : (
                            <Feather
                                style={{
                                    position: 'absolute',
                                }}
                                color={Colors.gray3}
                                size={Icons.medium}
                                name={'user'}
                            />
                        )}
                    </ImageBackground>
                    <View style={styles.userInfo}>
                        <Text
                            style={TextStyles.Button}
                            numberOfLines={1}
                            ellipsizeMode
                            maxLength={3}
                        >
                            @{this.state.username}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
}

export default withNavigation(VenueMemberItem)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: Layout.paddingMedium,
        borderBottomWidth: Layout.isMediumDevice ? 0 : 1,
        borderBottomColor: Layout.isMediumDevice
            ? 'transparent'
            : Colors.border,
        //marginRight: Layout.isMediumDevice ? Layout.paddingSmall : 0,
    },
    wrapper: {
        flex: 1,
        flexDirection: Layout.isMediumDevice ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: Layout.isMediumDevice ? 124 : null,
    },

    avatar: {
        height: Icons.XXL,
        width: Icons.XXL,
        borderRadius: Icons.XXL * 0.5,
        overflow: 'hidden',
        backgroundColor: Colors.gray1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    userInfo: {
        flex: 1,
        paddingTop: Layout.isMediumDevice ? Layout.paddingSmall : 0,
        paddingHorizontal: Layout.paddingMedium,
        maxWidth: Layout.isMediumDevice ? 124 : null,
    },
})
