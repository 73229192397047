import * as firebase from 'firebase'

// function uploadPhoto(uri, uploadUri) {
//   return new Promise(async (res, rej) => {
//     const response = await fetch(uri);
//     const blob = await response.blob();

//     const ref = firebase.storage().ref(uploadUri);
//     const unsubscribe = ref.put(blob).on(
//       'state_changed',
//       state => {},
//       err => {
//         unsubscribe();
//         rej(err);
//       },
//       async () => {
//         unsubscribe();
//         const url = await ref.getDownloadURL();
//         res(url);
//       },
//     );
//   });
// }

async function uploadPhoto(uri, uploadUri) {
    const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function() {
            resolve(xhr.response)
        }
        xhr.onerror = function() {
            reject(new TypeError('Network request failed'))
        }
        xhr.responseType = 'blob'
        xhr.open('GET', uri, true)
        xhr.send(null)
    })

    // do something with the blob, eg. upload it to firebase (API v5.6.0 below)
    const ref = firebase.storage().ref(uploadUri)

    const snapshot = await ref.put(blob)
    const remoteUri = await snapshot.ref.getDownloadURL()

    // when we're done sending it, close and release the blob
    /* Disabling for Chrome */
    //blob.close()

    // return the result, eg. remote URI to the image
    return remoteUri
}

export default uploadPhoto
