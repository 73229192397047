// Reference: https://davidwalsh.name/query-string-javascript
export default (name = '') => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(location.search)

    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
