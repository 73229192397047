const small = 18
const medium = 22
const large = 26
const XL = 38
const XXL = 64

export default {
    small,
    medium,
    large,
    XL,
    XXL,
}
