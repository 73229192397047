import React from 'react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { Layout } from '../constants'

export const WindowResizeContext = React.createContext({ width: 0, height: 0 })
export const WindowResizeConsumer = WindowResizeContext.Consumer

export class WindowResizeProvider extends React.Component {
    state = this.getDimensions()
    // state = {
    //     getDimensions: this.getDimensions(),
    //     preventResize: false,
    // }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate = prevState => {
        if (prevState !== this.state) {
            this.refreshPage()
            //  console.log('Window size changed')
        }
    }

    // refreshPage = async () => {
    //     if (this.state.preventResize === false) {
    //         const refresh = window.location.reload(false)
    //         const refreshDebounced = await AwesomeDebouncePromise(refresh, 1000)
    //         return refreshDebounced
    //     } else {
    //         return
    //     }
    // }

    refreshPage = async () => {
        const deviceSize = Layout.isMediumDevice ? true : false

        if (deviceSize === true) {
            return
        } else {
            const refresh = window.location.reload(false)
            const refreshDebounced = await AwesomeDebouncePromise(refresh, 3000)
            return refreshDebounced
        }
    }

    // refreshPage = async () => {
    //     const refresh = window.location.reload(false)

    //     const refreshDebounced = await AwesomeDebouncePromise(refresh, 3000)

    //     return refreshDebounced
    // }

    getDimensions() {
        const w = window
        const d = document
        const documentElement = d.documentElement
        const body = d.getElementsByTagName('body')[0]
        const width =
            w.innerWidth || documentElement.clientWidth || body.clientWidth
        const height =
            w.innerHeight || documentElement.clientHeight || body.clientHeight

        return { width, height }
    }

    updateDimensions = () => {
        this.setState(this.getDimensions())
    }

    // setPreventResize = () => {
    //     this.setState({ preventResize: Layout.isMediumDevice ? true : false })
    // }

    // resetPreventResize = () => {
    //     this.setState({ preventResize: false })
    // }

    render() {
        return (
            <WindowResizeContext.Provider
                value={this.state}
                // value={{
                //     getDimensions: this.state.getDimensions,
                //     preventResize: this.state.preventResize,
                //     setPreventResize: this.setPreventResize,
                //     resetPreventResize: this.resetPreventResize,
                // }}
            >
                {this.props.children}
            </WindowResizeContext.Provider>
        )
    }
}
