import React from 'react'
import {
    ImageBackground,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import moment from 'moment'

import { Colors, TextStyles, Layout } from '../constants'

import Avatar from './Avatar'

export default class StoriesItem extends React.Component {
    onPress = () => {
        this.props.onPress &&
            this.props.onPress({
                selectedVideo: this.props.video,
                selectedTheme: this.props.theme,
                selectedStoryId: this.props.storyId,
            })
    }

    render() {
        const { videoThumbnail, timestamp, userId, theme } = this.props

        const timestampLabel = moment(timestamp, 'x').calendar(null, {
            sameDay: '[Today] h:mm A',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday] h:mm A',
            lastWeek: '[Last] dddd',
            sameElse: 'M/DD/YYYY',
        })

        return (
            <TouchableOpacity style={{ flex: 1 }} onPress={this.onPress}>
                <ImageBackground
                    source={{ uri: videoThumbnail }}
                    style={styles.wrapper}
                    resizeMode={'cover'}
                >
                    <Avatar style={styles.avatar} userId={userId} />
                    <Text
                        style={[
                            TextStyles.CaptionInverse,
                            styles.timestamp,
                            { lineHeight: 16 },
                        ]}
                        numberOfLines={1}
                    >
                        {timestampLabel}
                    </Text>
                </ImageBackground>
            </TouchableOpacity>
        )
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: Layout.isMediumDevice
            ? Layout.SCREEN_WIDTH * 0.382
            : Layout.SCREEN_WIDTH * 0.166,
        height: Layout.isMediumDevice
            ? Layout.SCREEN_WIDTH * 0.5
            : Layout.SCREEN_HEIGHT * 0.382,
        borderRadius: Layout.borderRadiusSmall,
        overflow: 'hidden',
    },

    avatar: {
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 100,
    },

    timestamp: {
        position: 'absolute',
        bottom: 0,
        padding: 8,
        zIndex: 100,
    },
})
