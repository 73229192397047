import React from 'react'
import { Alert } from 'react-native'
import * as FileSystem from 'expo-file-system'
import uuid from 'uuid'
import * as firebase from 'firebase'
import 'firebase/firestore'
import '../firebase.config'
import * as VideoThumbnails from 'expo-video-thumbnails'

import uploadVideo from '../utils/uploadVideo'
import uploadPhoto from '../utils/uploadPhoto'

const DB = firebase.firestore()
const STORY_COLLECTION = 'stories'

class Story extends React.Component {
    /* SPECIAL BATCH FUNCTION: TO UPDATE EVENTS DB WITH PLACEID AND RENAME */
    _batchEventUpdate = async () => {
        console.log('›››› BATCH OPERATION IN ACTION ››››')

        const storyRef = await DB.collection('stories').doc(
            'krkhC1kDwpnwNkn606ly'
        )

        try {
            await storyRef.get().then(async doc => {
                if (doc.exists) {
                    const video = await doc.data().video
                    console.log('video', video)

                    const localPhotoUri = await VideoThumbnails.getThumbnailAsync(
                        video,
                        {
                            time: 0,
                            compress: 0.5,
                        }
                    )

                    //console.log('localPhotoUri', localPhotoUri.uri)

                    const uri = localPhotoUri.uri

                    console.log('uri', uri)

                    const path = `${STORY_COLLECTION}/${
                        this.uid
                    }/${uuid.v4()}.jpg`

                    const remoteImageUri = await uploadVideo(uri, path)
                    console.log('remoteImageUri', remoteImageUri)

                    storyRef.update({
                        videoThumbnail: remoteImageUri,
                    })
                }
            })
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    ____batchEventUpdate = async () => {
        console.log('›››› BATCH OPERATION IN ACTION ››››')

        DB.collection('stories')
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(async doc => {
                    //const storyRef = DB.collection('stories').doc(doc.id)

                    // var userRoleUpdate = {}
                    // userRoleUpdate[`roles.${firebase.auth().currentUser.uid}`] =
                    //     'owner'

                    //return eventsRef.update(userRoleUpdate)
                    const storyRef = DB.collection('stories').doc(doc.id)

                    //const thumbExists = doc.data().videoThumbnail.uri

                    try {
                        // if (thumbExists) {
                        const video = await doc.data().video
                        console.log('video', video)

                        const localPhotoUri = await VideoThumbnails.getThumbnailAsync(
                            video,
                            {
                                time: 1000,
                                compress: 0.5,
                            }
                        )

                        //console.log('localPhotoUri', localPhotoUri.uri)

                        const uri = localPhotoUri.uri

                        console.log('uri', uri)

                        //const remoteImageUri = await this._uploadPhotoAsync(uri)

                        const path = `${STORY_COLLECTION}/${
                            this.uid
                        }/${uuid.v4()}.jpg`

                        const remoteImageUri = await uploadVideo(uri, path)
                        console.log('remoteImageUri', remoteImageUri)

                        storyRef.update({
                            // placeId: 'ChIJOWvrIUuBhYARMEk3vdJP_ic',
                            // visibility: 'public',
                            // uid: 'aOc6jW3KrkMeLYgghaPwTmkC6s82',
                            // user: firebase.firestore.FieldValue.delete(),
                            //flagged: false,
                            // visibility: {
                            //     group: true,
                            //     owner: true,
                            //     private: false,
                            //     public: false,
                            //     venueCurrent: true,
                            // },
                            //theme: 'Originals',
                            videoThumbnail: remoteImageUri,
                        })
                        // } else {
                        //     console.log('We gon skip this one')
                        //     return
                        // }
                    } catch ({ message }) {
                        console.log(message)
                    }
                })
            })
    }

    // Helpers
    get collection() {
        return DB.collection(STORY_COLLECTION)
    }

    get uid() {
        return (firebase.auth().currentUser || {}).uid
    }

    get user() {
        return firebase.auth().currentUser
    }

    get timestamp() {
        return Date.now()
    }

    // get placeId() {
    //     const { placeId } = this._getUserPlaceId()
    //     return placeId
    // }

    /* For signed-in app view */
    _getUserPlaceId = async () => {
        const venueProfileRef = await DB.collection('users').doc(this.uid)

        try {
            const getPlaceId = []
            await venueProfileRef.get().then(doc => {
                if (doc.exists) {
                    getPlaceId.push(doc.data().venueActive.placeId)
                } else {
                    return
                }
            })

            return {
                placeId: getPlaceId[0],
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    venueStoryPaged = async ({ selectedEventId, placeId, size, end }) => {
        // This if statement is in place to kick-start the venueEvents query to populate the story 'where' filter.
        // if (!selectedEventId.length) {
        //   let end = 0;
        //   //await this._venueEventsPaged(end);
        //   await Event._venueEventsPaged(end);
        // }

        //const { placeId } = await this._getUserPlaceId()

        let storyRef = await this.collection
            .orderBy('timestamp', 'desc')
            //.orderBy('timestamp', 'asc') // Normally, venue stories is desc, but I want to try it asc.
            .where('eventId', '==', selectedEventId)
            .where('placeId', '==', placeId)
            .where('visibility.private', '==', false)
            .where('visibility.public', '==', true)
            .limit(size) //  <-- Optimize, once flow works, and play next can auto-load more.

        try {
            if (end) {
                storyRef = storyRef.startAfter(end)
            }

            const venueStory = []

            /* One idea: */
            const setPostIds = [] // Similar to setEventIds

            const setLastVisible = []

            const queryStory = await storyRef.get()

            queryStory.forEach(async story => {
                if (story.exists) {
                    //console.log('####', story.id)
                    const getPostId = story.id || {}
                    setPostIds.push(getPostId)

                    const getLastVisibleTimestamp = story.data().timestamp || {}
                    setLastVisible.push(getLastVisibleTimestamp)

                    // if (
                    //   setLastVisible.includes(getLastVisibleTimestamp) === false
                    // )
                    //   setLastVisible.push(getLastVisibleTimestamp);

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await venueStory.push({
                        storyId: story.id,
                        ...story.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            // const lastVisible =
            //   setLastVisible[setLastVisible.length - 1] || {};

            const lastVisible =
                /* This is the correct equation for this flow */
                setLastVisible[setLastVisible.length - 1] || {} // This selects the "first" item in the batch of results

            // setLastVisible[
            //     (setLastVisible.length - 1) % setLastVisible.length
            // ] || {}

            /* I think this might be causing the issue with resetting the playlist story */
            //setLastVisible[0] || {} // This gets most recent (from story query, since results are 'desc')

            //setLastVisible[setLastVisible.length]

            console.log(
                'story:',
                ': ✓✓✓ :',
                'VENUE-STORY'
                //venueStory,
                // 'VENUE-STORY-LASTVISIBLE',
                // lastVisible,
            )

            return {
                setPostIds,
                venueStory,
                storyCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    venueRecentPostPaged = async ({ placeId, size }) => {
        //const { placeId } = await this._getUserPlaceId()

        let storyRef = await this.collection
            .orderBy('timestamp', 'desc')
            .where('placeId', '==', placeId)
            .where('visibility.private', '==', false)
            //.where('visibility.venue', '==', true)
            .where('visibility.public', '==', true)
            .limit(size)

        try {
            // The story array just updates this incremental view and replace the view above. Doesn't add to larger array.
            const venueStory = []

            //const setStoryLength = []; // Similar to setEventIds

            const setLastVisible = []

            const setPostEventId = []

            const queryStory = await storyRef.get()

            queryStory.forEach(async story => {
                if (story.exists) {
                    //console.log('story.DATA()', story.data());

                    const getLastVisibleTimestamp = story.data().timestamp || {}

                    const getPostEventId = story.data().eventId

                    setPostEventId.push(getPostEventId)

                    setLastVisible.push(getLastVisibleTimestamp)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await venueStory.push({
                        ...story.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible =
                //setLastVisible[setLastVisible.length - 1] || {};
                setLastVisible[
                    (setLastVisible.length - 1) % setLastVisible.length
                ] || {}

            const storyEventId = setPostEventId[0]

            return {
                venueStory,
                storyEventId: storyEventId || '{}',
                storyCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    venueArchiveStoryPaged = async ({ selectedEventId, size, end }) => {
        const { placeId } = await this._getUserPlaceId()

        let storyRef = await this.collection
            .orderBy('timestamp', 'asc')
            .where('eventId', '==', selectedEventId)
            .where('placeId', '==', placeId)
            .where('visibility.private', '==', false)
            .where('visibility.venue', '==', true)
            .limit(size)

        try {
            //console.log('story', 'END ››››››', end)

            if (end) {
                storyRef = storyRef.startAfter(end)
            }

            // The story array just updates this incremental view and replace the view above. Doesn't add to larger array.
            const venueArchiveStory = []

            const setArchivePostIds = [] // Similar to setEventIds

            const setLastVisible = []

            const queryStory = await storyRef.get()

            queryStory.forEach(async story => {
                if (story.exists) {
                    //console.log('story.DATA()', story.data());
                    const getPostId = story.id || {}
                    setArchivePostIds.push(getPostId)

                    const getLastVisibleTimestamp = story.data().timestamp || {}

                    setLastVisible.push(getLastVisibleTimestamp)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await venueArchiveStory.push({
                        storyId: story.id,
                        ...story.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible = setLastVisible[setLastVisible.length - 1] || {}

            return {
                setArchivePostIds,
                venueArchiveStory,
                archiveStoryCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** story: FIREBASE MESSAGE: venueArchiveStoryPaged: *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    /**
     *
     *
     *   UserStoryPaged, is in use.
     *
     *
     * */

    userStoryPaged = async ({ selectedEventId, size, end }) => {
        const { placeId } = await this._getUserPlaceId()

        let storyRef = await this.collection
            .orderBy('timestamp', 'asc')
            .where('eventId', '==', selectedEventId)
            .where('uid', '==', this.uid)
            .where('placeId', '==', placeId)
            .where('visibility.owner', '==', true)
            .limit(size)

        try {
            //console.log('story', 'END ››››››', end)

            // if (size === 1) {
            //   storyRef = storyRef.limit(size++);
            // }

            if (end) {
                storyRef = storyRef.startAfter(end) // <-- This formatting is fine :) Maybe a placement issue?
            }

            // The story array just updates this incremental view and replace the view above. Doesn't add to larger array.
            const userStory = []

            const setPostIds = [] // Similar to setEventIds

            const setLastVisible = []

            const queryStory = await storyRef.get()

            queryStory.forEach(async story => {
                if (story.exists) {
                    //console.log('story.DATA()', story.data());
                    const getPostId = story.id || {}
                    setPostIds.push(getPostId)

                    const getLastVisibleTimestamp = story.data().timestamp || {}

                    setLastVisible.push(getLastVisibleTimestamp)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await userStory.push({
                        storyId: story.id,
                        ...story.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible = setLastVisible[setLastVisible.length - 1] || {}
            // setLastVisible[
            //     (setLastVisible.length - 1) % setLastVisible.length
            // ] || {}

            console.log(
                'story:',
                ': ✓✓✓ :',
                'USER-STORY'
                //userStory,
                // userStory.length,
                // 'USER-STORY-LAST-VISIBLE',
                // lastVisible, // This is correctly putting out updated cursors
            )

            return {
                setPostIds,
                userStory,
                storyCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    userArchiveStoryPaged = async ({ selectedEventId, size, end }) => {
        const { placeId } = await this._getUserPlaceId()

        let storyRef = await this.collection
            .orderBy('timestamp', 'asc')
            .where('eventId', '==', selectedEventId)
            .where('uid', '==', this.uid)
            .where('placeId', '==', placeId)
            .where('visibility.owner', '==', true)
            .limit(size)

        try {
            //console.log('story', 'END ››››››', end)

            // if (size === 1) {
            //   storyRef = storyRef.limit(size++);
            // }

            if (end) {
                storyRef = storyRef.startAfter(end) // <-- This formatting is fine :) Maybe a placement issue?
            }

            // The story array just updates this incremental view and replace the view above. Doesn't add to larger array.
            const userArchiveStory = []

            const setArchivePostIds = [] // Similar to setEventIds

            const setLastVisible = []

            const queryStory = await storyRef.get()

            queryStory.forEach(async story => {
                if (story.exists) {
                    //console.log('story.DATA()', story.data());
                    const getPostId = story.id || {}
                    setArchivePostIds.push(getPostId)

                    const getLastVisibleTimestamp = story.data().timestamp || {}

                    setLastVisible.push(getLastVisibleTimestamp)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await userArchiveStory.push({
                        storyId: story.id,
                        ...story.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible = setLastVisible[setLastVisible.length - 1] || {}

            //const lastVisible = setLastVisible[setLastVisible.length - 1]

            // setLastVisible[
            //     (setLastVisible.length - 1) % setLastVisible.length
            // ] || {}

            console.log(
                'story:',
                ': ✓✓✓ :',
                'USER-STORY'
                //userArchiveStory,
                // userArchiveStory.length,
                // 'USER-STORY-LAST-VISIBLE',
                // lastVisible, // This is correctly putting out updated cursors
            )

            return {
                setArchivePostIds,
                userArchiveStory,
                archiveStoryCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    userRecentPostPaged = async ({ selectedEventId, size, end }) => {
        const { placeId } = await this._getUserPlaceId()

        let storyRef = await this.collection
            .orderBy('timestamp', 'desc')
            .where('uid', '==', this.uid)
            .where('placeId', '==', placeId)
            .where('visibility.owner', '==', true)
            .limit(size)

        try {
            //console.log('story', 'END ››››››', end);

            // if (size === 1) {
            //   storyRef = storyRef.limit(size++);
            // }

            // if (end) {
            //   storyRef = storyRef.startAfter(end); // <-- This formatting is fine :) Maybe a placement issue?
            // }

            // The story array just updates this incremental view and replace the view above. Doesn't add to larger array.
            const userStory = []

            //const setStoryLength = []; // Similar to setEventIds

            const setLastVisible = []

            const setPostEventId = []

            const queryStory = await storyRef.get()

            queryStory.forEach(async story => {
                if (story.exists) {
                    const getLastVisibleTimestamp = story.data().timestamp || {}

                    const getPostEventId = story.data().eventId || {}

                    setPostEventId.push(getPostEventId)

                    setLastVisible.push(getLastVisibleTimestamp)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await userStory.push({
                        storyId: story.id || {},
                        ...(story.data() || {}),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible =
                //setLastVisible[setLastVisible.length - 1] || {};
                setLastVisible[
                    (setLastVisible.length - 1) % setLastVisible.length
                ] || {}

            const storyEventId = setPostEventId[0]

            console.log(
                'story:',
                ': ✓✓✓ :',
                'RECENT-USER-story'
                // userStory,
                // userStory.length,
                // 'RECENT-USER-STORY-LAST-VISIBLE',
                // lastVisible, // This is correctly putting out updated cursors
                // '**********************RECENT-EVENT-ID',
                // storyEventId
            )

            return {
                userStory,
                storyEventId: storyEventId || '{}', // Figuring out the best null string '{}' seems to work for now
                storyCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    //** UPLOAD DATA **//

    /* CREATE VIDEO FUNCTIONS */

    _uploadVideoAsync = async uri => {
        const uuidv4 = require('uuid/v4')
        const uploadUri = `${STORY_COLLECTION}/${this.uid}/${uuidv4()}.mp4`

        try {
            const remoteVideoUri = await uploadVideo(uri, uploadUri)
            return remoteVideoUri
        } catch (e) {
            console.warn(e)
        }
    }

    _generateThumbnail = async localVideo => {
        try {
            const { uri } = await VideoThumbnails.getThumbnailAsync(
                localVideo,
                {
                    time: 1000,
                    compress: 0.5,
                }
            )

            // const androidParse = uri.replace('file://', '')

            // console.log(
            //     'Story.js › _generateThumbnail › uri created ›››',
            //     uri,
            //     androidParse
            // )
            const remoteImageUri = await this._uploadPhotoAsync(uri)

            return remoteImageUri

            //return { uri }
        } catch (e) {
            console.warn(e)
        }
    }

    _uploadPhotoAsync = async uri => {
        console.log('Story.js › _uploadPhotoAsync › uri ›››', uri)

        const uuidv4 = require('uuid/v4')
        const uploadUri = `${STORY_COLLECTION}/${this.uid}/${uuidv4()}.jpg`

        try {
            const remoteImageUri = await uploadPhoto(uri, uploadUri)

            return remoteImageUri
        } catch (e) {
            console.warn(e)
        }
    }

    /* Above 3 functions feed the createUserPost function */

    _createUserPost = async ({
        eventId,
        placeId,
        theme,
        visibility,
        isImage,
        isVideoUpload,
    }) => {
        const storyRef = this.collection
        const localVideo = `${FileSystem.documentDirectory}videos/${eventId}/tmp.mp4`
        const localImage = `${FileSystem.documentDirectory}videos/${eventId}/tmp.jpg`

        console.log(
            'Story.js › _createUserPost › localVideo created ›››',
            localVideo
        )

        try {
            if (isImage === true) {
                const remoteImageUri = await this._uploadPhotoAsync(localImage)

                storyRef.add({
                    uid: this.uid,
                    timestamp: this.timestamp,
                    video: null,
                    videoThumbnail: remoteImageUri,
                    image: remoteImageUri,
                    eventId,
                    placeId,
                    theme,
                    visibility,
                    isImage: true,
                    isVideoUpload: false,
                })
            } else {
                const remoteVideoUri = await this._uploadVideoAsync(localVideo)
                const remoteImageUri = await this._generateThumbnail(localVideo)

                storyRef.add({
                    uid: this.uid,
                    timestamp: this.timestamp,
                    video: remoteVideoUri,
                    videoThumbnail: remoteImageUri,
                    image: null,
                    eventId,
                    placeId,
                    theme,
                    visibility,
                    isImage: false,
                    isVideoUpload,
                })
            }

            //const { uri } = await this._generateThumbnail(localVideo)

            /* Android is crashing at this step */
            // console.log(
            //     'Story.js › _createUserPost › localVideoThumbnail uri created ›››',
            //     uri
            // )

            //const localImage = await localVideoThumbnail.uri

            // console.log(
            //     'Story.js › _createUserPost › localImage created ›››',
            //     localImage
            // )

            //const remoteImageUri = await this._uploadPhotoAsync(uri)
        } catch ({ message }) {
            console.log(
                ':::::: ERROR MESSAGE › _createUserPost ::::::',
                message
            )
        }
    }

    /* DESTRUCTIVE ACTIONS */

    _flagSelectedVideo = async storyId => {
        try {
            let storyRef = await this.collection.doc(storyId)

            storyRef
                .update({
                    flagged: true,
                    flaggedBy: firebase.firestore.FieldValue.arrayUnion(
                        this.uid
                    ),
                })
                .then(() => {
                    console.log('Document successfully flagged!')
                    Alert.alert(
                        'This video has been successfully flagged. Thank you for alerting us to this content.'
                    )
                })
        } catch ({ error }) {
            console.error('Error flagging document: ', error)
            // Alerts.alert(
            //     'This event could not be deleted. If an event is public, it cannot be deleted. Contact Indvstry support for further assistance.'
            // )
        }
    }

    _deleteSelectedVideo = async storyId => {
        try {
            let storyRef = await this.collection

            storyRef
                .doc(storyId)
                .delete()
                .then(() => {
                    console.log('Document successfully deleted!')
                    Alert.alert('Your video has been successfully deleted.')
                })
        } catch ({ error }) {
            console.error('Error removing document: ', error)
            // Alerts.alert(
            //     'This event could not be deleted. If an event is public, it cannot be deleted. Contact Indvstry support for further assistance.'
            // )
        }
    }
}

export default new Story()
