//import './fixtimerbug'
/**
 *
 * Firebase creates timeout issues when grabbing video content. This patch solution helps with that in the meantime.
 * https://github.com/facebook/react-native/issues/12981
 *
 * */
import { AppLoading } from 'expo'
import { Asset } from 'expo-asset'
import * as Font from 'expo-font'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Feather, Foundation, MaterialCommunityIcons } from '@expo/vector-icons'

import AppNavigator from './navigation/AppNavigator'
import { UserProfileProvider } from './context/UserProfileContext'
import { WindowResizeProvider } from './context/WindowResizeContext'
import { VenueProfileProvider } from './context/VenueProfileContext'
import { VenueStoriesProvider } from './context/VenueStoriesContext'

export default function App(props) {
    console.disableYellowBox = true // change to true
    window.disableYellowBox = true

    const [isLoadingComplete, setLoadingComplete] = useState(false)

    if (!isLoadingComplete && !props.skipLoadingScreen) {
        return (
            <AppLoading
                startAsync={loadResourcesAsync}
                onError={handleLoadingError}
                onFinish={() => handleFinishLoading(setLoadingComplete)}
            />
        )
    } else {
        return (
            <WindowResizeProvider>
                <VenueStoriesProvider>
                    <VenueProfileProvider>
                        <UserProfileProvider>
                            <View style={styles.container}>
                                <AppNavigator />
                            </View>
                        </UserProfileProvider>
                    </VenueProfileProvider>
                </VenueStoriesProvider>
            </WindowResizeProvider>
        )
    }
}

async function loadResourcesAsync() {
    await Promise.all([
        Font.loadAsync({
            ...Feather.font,
            ...Foundation.font,
            ...MaterialCommunityIcons.font,

            'neuzeit-s': require('./assets/fonts/NeuzeitS-Book.ttf'),
            'neuzeit-s-bold': require('./assets/fonts/NeuzeitS-Heavy.ttf'),
            'mattone-regular': require('./assets/fonts/Mattone-Regular.otf'),
        }),

        Asset.loadAsync([
            /* Maybe try to run a glob in the future. Might be dated, or Asset from expo better option */
            //https://stackoverflow.com/questions/5364928/node-js-require-all-files-in-a-folder\

            /* Hero images */
            require('./assets/images/icon.png'),
            require('./assets/images/sasha-stories-XVTTK3wcYME-unsplash.jpg'),
            require('./assets/images/shangyou-shi-VdRex9AiBOc-unsplash.jpg'),
            require('./assets/images/slider_one.jpg'),
            require('./assets/images/spencer-davis-ps8zSj_hqjU-unsplash.jpg'),
        ]),
    ])
}

function handleLoadingError(error) {
    // In this case, you might want to report the error to your error reporting
    // service, for example Sentry
    console.warn(error)
}

function handleFinishLoading(setLoadingComplete) {
    setLoadingComplete(true)
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
})
