import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { Colors, TextStyles, Layout } from '../../constants'
import Footer from '../../components/Footer'

export default class PrivacyPolicy extends React.Component {
    render() {
        return (
            <View style={{ flex: 1 }}>
                <ScrollView style={{ flex: 1 }}>
                    <View
                        style={[
                            Layout.projectContainer,
                            {
                                backgroundColor: Colors.gray0,
                                paddingVertical: 0,
                            },
                        ]}
                    >
                        <View
                            style={[
                                Layout.columnOne,
                                {
                                    maxWidth: 720,
                                    paddingVertical: Layout.topNavHeightDesktop,
                                },
                            ]}
                        >
                            <View style={Layout.cardContent}>
                                <Text
                                    style={[
                                        TextStyles.Heading2,
                                        {
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    Privacy Policy
                                </Text>
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    This Privacy Policy describes how your
                                    personal information is collected, used, and
                                    shared when you visit or make a purchase
                                    from indvstry.io or indvstry.app (the
                                    “Site”).
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    PERSONAL INFORMATION WE COLLECT
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            /* Breaking long urls: Come up with tighter solution */
                                            wordBreak: 'break-all',
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    When you visit the Site or App, we
                                    automatically collect certain information
                                    about your device, including information
                                    about your web browser, IP address, time
                                    zone, and some of the cookies that are
                                    installed on your device. Additionally, as
                                    you browse the Site or App, we collect
                                    information about the individual web pages
                                    or products that you view, what websites or
                                    search terms referred you to the Site or
                                    App, and information about how you interact
                                    with the Site or App. We refer to this
                                    automatically-collected information as
                                    “Device Information.” We collect Device
                                    Information using the following
                                    technologies: - “Cookies” are data files
                                    that are placed on your device or computer
                                    and often include an anonymous unique
                                    identifier. For more information about
                                    cookies, and how to disable cookies, visit
                                    http://www.allaboutcookies.org. - “Log
                                    files” track actions occurring on the Site
                                    or App, and collect data including your IP
                                    address, browser type, Internet service
                                    provider, referring/exit pages, and
                                    date/time stamps. - “Web beacons,” “tags,”
                                    and “pixels” are electronic files used to
                                    record information about how you browse the
                                    Site or App. Additionally when you make a
                                    purchase or attempt to make a purchase
                                    through the Site or App, we collect certain
                                    information from you, including your name,
                                    billing address, shipping address, payment
                                    information (including credit card numbers),
                                    email address, and phone number. We refer to
                                    this information as “Order Information.”
                                    When we talk about “Personal Information” in
                                    this Privacy Policy, we are talking both
                                    about Device Information and Order
                                    Information.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    HOW DO WE USE YOUR PERSONAL INFORMATION?
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    We use the Order Information that we collect
                                    generally to fulfill any orders placed
                                    through the Site or App (including
                                    processing your payment information,
                                    arranging for shipping, and providing you
                                    with invoices and/or order confirmations).
                                    Additionally, we use this Order Information
                                    to: Communicate with you; Screen our orders
                                    for potential risk or fraud; and When in
                                    line with the preferences you have shared
                                    with us, provide you with information or
                                    advertising relating to our products or
                                    services. We use the Device Information that
                                    we collect to help us screen for potential
                                    risk and fraud (in particular, your IP
                                    address), and more generally to improve and
                                    optimize our Site or App (for example, by
                                    generating analytics about how our customers
                                    browse and interact with the Site or App,
                                    and to assess the success of our marketing
                                    and advertising campaigns).
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    SHARING YOUR PERSONAL INFORMATION
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            wordBreak: 'break-all',
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    We share your Personal Information with
                                    third parties to help us use your Personal
                                    Information, as described above. For
                                    example, we use an online payments platform
                                    to power our online store--you can read more
                                    about how Shopify uses your Personal
                                    Information here:
                                    https://www.shopify.com/legal/privacy. We
                                    also use Google Analytics to help us
                                    understand how our customers use the Site or
                                    App--you can read more about how Google uses
                                    your Personal Information here:
                                    https://www.google.com/intl/en/policies/privacy/.
                                    You can also opt-out of Google Analytics
                                    here:
                                    https://tools.google.com/dlpage/gaoptout.
                                    Finally, we may also share your Personal
                                    Information to comply with applicable laws
                                    and regulations, to respond to a subpoena,
                                    search warrant or other lawful request for
                                    information we receive, or to otherwise
                                    protect our rights.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    BEHAVIOURAL ADVERTISING
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            wordBreak: 'break-all',
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    As described above, we use your Personal
                                    Information to provide you with targeted
                                    advertisements or marketing communications
                                    we believe may be of interest to you. For
                                    more information about how targeted
                                    advertising works, you can visit the Network
                                    Advertising Initiative’s (“NAI”) educational
                                    page at
                                    http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                                    You can opt out of targeted advertising by:
                                    FACEBOOK -
                                    https://www.facebook.com/settings/?tab=ads
                                    GOOGLE -
                                    https://www.google.com/settings/ads/anonymous
                                    BING -
                                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                                    Additionally, you can opt out of some of
                                    these services by visiting the Digital
                                    Advertising Alliance’s opt-out portal at:
                                    http://optout.aboutads.info/.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    DO NOT TRACK
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    Please note that we do not alter our Site’s
                                    data collection and use practices when we
                                    see a Do Not Track signal from your browser.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    YOUR RIGHTS
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    If you are a European resident, you have the
                                    right to access personal information we hold
                                    about you and to ask that your personal
                                    information be corrected, updated, or
                                    deleted. If you would like to exercise this
                                    right, please contact us through the contact
                                    information below. Additionally, if you are
                                    a European resident we note that we are
                                    processing your information in order to
                                    fulfill contracts we might have with you
                                    (for example if you make an order through
                                    the Site or App), or otherwise to pursue our
                                    legitimate business interests listed above.
                                    Additionally, please note that your
                                    information will be transferred outside of
                                    Europe, including to Canada and the United
                                    States.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    DATA RETENTION
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    When you place an order through the Site or
                                    App, we will maintain your Order Information
                                    for our records unless and until you ask us
                                    to delete this information.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    MINORS
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    The Site or App is not intended for
                                    individuals under the age of 21.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    CHANGES
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    We may update this privacy policy from time
                                    to time in order to reflect, for example,
                                    changes to our practices or for other
                                    operational, legal or regulatory reasons.
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Overline,
                                        {
                                            flex: 1,
                                            paddingBottom: 16,
                                            textTransform: 'uppercase',
                                            lineHeight: 24,
                                        },
                                    ]}
                                >
                                    CONTACT US
                                </Text>

                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            lineHeight: 32,
                                            paddingBottom: Layout.paddingLarge,
                                        },
                                    ]}
                                >
                                    For more information about our privacy
                                    practices, if you have questions, or if you
                                    would like to make a complaint, please
                                    contact us by e-mail at support@indvstry.io.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <Footer navigation={this.props.navigation} />
                </ScrollView>
            </View>
        )
    }
}
