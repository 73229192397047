import React from 'react'
import {
    FlatList,
    StyleSheet,
    Text,
    TextInput,
    View,
    ScrollView,
    Keyboard,
    TouchableOpacity,
} from 'react-native'
import { withNavigation } from 'react-navigation'
import { Linking } from 'expo'
import moment from 'moment'
import { Buttons, Colors, Icons, Layout, TextStyles } from '../constants'
import { EventConsumer } from '../context/EventContext'
import { VenueProfileConsumer } from '../context/VenueProfileContext'
import VenueMemberItem from './VenueMemberItem'
import Button from './Button'
import ButtonIcon from './ButtonIcon'

class EventDetailsScreen extends React.Component {
    onAddStoryPress = async () => {
        const {
            eventId,
            title,
            about,
            placeId,
            venueName,
            venueAddress,
            createdBy,
            visibility,
            startDate,
            endDate,
            contributors,
        } = this.props.navigation.getParam('eventData')

        const setStartDate = moment.utc(startDate.seconds * 1000).toString()
        const setEndDate = moment.utc(endDate.seconds * 1000).toString()

        const selectedEvent = {
            eventId,
            title,
            about,
            placeId,
            venueName,
            venueAddress,
            createdBy,
            visibility,
            startDate: setStartDate,
            endDate: setEndDate,
        }

        await this.context.setSelectedEvent(selectedEvent)

        this.props.navigation.goBack()

        await this.props.navigation.navigate('Create')
    }

    renderHeaderCard = ({ startDateNew, title, venueName }) => {
        const headerDateLabel = moment
            .utc(startDateNew)
            .format('dddd, MMM Do, YYYY')
            .toUpperCase()

        return (
            <View style={styles.headerContainer}>
                <View style={styles.headerContent}>
                    <Text
                        style={[
                            TextStyles.Overline,
                            {
                                color: Colors.primaryText,
                                paddingBottom: Layout.paddingSmall,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {headerDateLabel}
                    </Text>
                    <Text
                        style={[
                            TextStyles.Heading2,
                            {
                                textAlign: 'center',
                                paddingBottom: Layout.paddingSmall,
                                zIndex: 10,
                                fontFamily: 'neuzeit-s-bold',
                            },
                        ]}
                    >
                        {title}
                    </Text>

                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                textAlign: 'center',
                                paddingBottom: Layout.paddingSmall,
                            },
                        ]}
                    >
                        by {venueName}
                    </Text>
                </View>
            </View>
        )
    }

    renderStartDatePicker = ({ startDateNew, sectionTitle }) => {
        const startDateLabel = moment
            .utc(startDateNew)
            .format('ddd, MMM D, h:mm A')

        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: Layout.paddingMedium,
                }}
            >
                <Text
                    style={[
                        TextStyles.Overline,
                        { paddingBottom: Layout.paddingMedium },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <Text style={TextStyles.Paragraph}>{startDateLabel}</Text>
                {this.renderSeparator()}
            </View>
        )
    }

    renderEndDatePicker = ({ sectionTitle, endDateNew }) => {
        const endDateLabel = moment.utc(endDateNew).format('ddd, MMM D, h:mm A')

        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: Layout.paddingMedium,
                }}
            >
                <Text
                    style={[
                        TextStyles.Overline,
                        { paddingBottom: Layout.paddingMedium },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <Text style={TextStyles.Paragraph}>{endDateLabel}</Text>

                {this.renderSeparator()}
            </View>
        )
    }

    renderAbout = ({ sectionTitle, about }) => {
        return (
            <View
                style={{
                    // flex: 1,
                    paddingTop: Layout.paddingLarge,
                    paddingBottom: Layout.paddingMedium,
                }}
            >
                <View style={{ paddingBottom: Layout.paddingMedium }}>
                    <Text style={TextStyles.Overline}>
                        {sectionTitle.toUpperCase()}
                    </Text>
                </View>

                <View
                    style={{
                        //// flex: 1,
                        paddingBottom: Layout.paddingLarge,
                        padding: Layout.paddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: Colors.gray0,
                    }}
                >
                    {about ? (
                        <Text style={TextStyles.Paragraph}>{about}</Text>
                    ) : (
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                { color: Colors.disabled },
                            ]}
                        >
                            Event details
                        </Text>
                    )}
                </View>
            </View>
        )
    }

    renderSeparator() {
        return (
            <View
                style={{
                    //// flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: 24,
                }}
            />
        )
    }

    renderVenueMemberItem = ({ item }) => {
        return (
            <VenueMemberItem
                navigation={this.props.navigation}
                userId={item}
                containerStyle={{ borderBottomWidth: 0 }}
            />
        )
    }

    keyExtractor = item => item

    render() {
        const {
            eventId,
            title,
            about,
            venueName,
            startDate,
            endDate,
            contributors,
            bookingUrl,
        } = this.props.eventData

        const startDateNew = startDate.seconds * 1000
        const endDateNew = endDate.seconds * 1000

        return (
            <VenueProfileConsumer>
                {venueProfileContext => (
                    <View
                        style={{ flex: 1 }}
                        ref={ref => {
                            this.venueProfileContext = venueProfileContext
                        }}
                    >
                        <EventConsumer>
                            {context => (
                                <View
                                    style={{
                                        flex: 1,
                                    }}
                                    ref={ref => {
                                        this.context = context
                                    }}
                                >
                                    <ScrollView
                                        showsVerticalScrollIndicator={false}
                                        style={styles.scrollView}
                                    >
                                        {this.renderHeaderCard({
                                            startDateNew,
                                            title,
                                            venueName,
                                        })}
                                        <View
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            {this.renderStartDatePicker({
                                                eventId,
                                                sectionTitle: 'Start time',
                                                startDate,
                                                startDateNew,
                                            })}

                                            {this.renderEndDatePicker({
                                                eventId,
                                                sectionTitle: 'End time',
                                                startDateNew,
                                                endDateNew,
                                            })}

                                            <View
                                                style={{
                                                    // flex: 1,
                                                    paddingBottom:
                                                        Layout.paddingMedium,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        paddingBottom:
                                                            Layout.paddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={
                                                            TextStyles.Overline
                                                        }
                                                    >
                                                        Location
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <ButtonIcon
                                                        iconName={'map-pin'}
                                                        iconSize={Icons.medium}
                                                        iconColor={
                                                            Colors.tintColor
                                                        }
                                                        buttonStyle={[
                                                            Buttons.menuButtonTransparentBG,
                                                            {
                                                                backgroundColor:
                                                                    Colors.gray0,
                                                                marginRight:
                                                                    Layout.paddingMedium,
                                                            },
                                                        ]}
                                                        navigation={
                                                            this.props
                                                                .navigation
                                                        }
                                                        onPress={() =>
                                                            Linking.openURL(
                                                                `${venueProfileContext.mapsUrl}`
                                                            )
                                                        }
                                                    />
                                                    <Button
                                                        navigation={
                                                            this.props
                                                                .navigation
                                                        }
                                                        onPress={() =>
                                                            Linking.openURL(
                                                                `${venueProfileContext.mapsUrl}`
                                                            )
                                                        }
                                                        buttonStyle={[
                                                            Buttons.buttonUnderline,
                                                            {
                                                                paddingHorizontal: 0,
                                                            },
                                                        ]}
                                                        textStyle={{
                                                            lineHeight: 26,
                                                        }}
                                                        textColor={
                                                            Colors.tintColor
                                                        }
                                                        title={
                                                            venueProfileContext.name +
                                                            '\n' +
                                                            venueProfileContext.streetNumber +
                                                            ' ' +
                                                            venueProfileContext.street +
                                                            '\n' +
                                                            venueProfileContext.city +
                                                            ', ' +
                                                            venueProfileContext.state +
                                                            ' ' +
                                                            venueProfileContext.postalCode
                                                        }
                                                    />
                                                </View>
                                            </View>
                                            {this.renderSeparator()}

                                            <View
                                                style={{
                                                    // flex: 1,
                                                    paddingTop:
                                                        Layout.paddingLarge,
                                                    paddingBottom:
                                                        Layout.paddingMedium,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        paddingBottom:
                                                            Layout.paddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={
                                                            TextStyles.Overline
                                                        }
                                                    >
                                                        Contributors
                                                    </Text>
                                                </View>
                                                <FlatList
                                                    horizontal
                                                    showsHorizontalScrollIndicator={
                                                        false
                                                    }
                                                    showsVerticalScrollIndicator={
                                                        false
                                                    }
                                                    // style={{
                                                    //     // flex: 1,
                                                    // }}
                                                    // contentContainerStyle={{
                                                    //     height: 100,
                                                    //     //// flex: 1,
                                                    //     // paddingBottom:
                                                    //     //     Layout.paddingLarge,
                                                    // }}
                                                    data={contributors}
                                                    keyExtractor={
                                                        this.keyExtractor
                                                    }
                                                    renderItem={
                                                        this
                                                            .renderVenueMemberItem
                                                    }
                                                    scrollEnabled={false}
                                                />
                                            </View>
                                            {this.renderSeparator()}

                                            {this.renderAbout({
                                                sectionTitle: 'About',
                                                about,
                                            })}

                                            <View
                                                style={{
                                                    // flex: 1,
                                                    display: this.props
                                                        .eventData.bookingUrl
                                                        ? 'flex'
                                                        : 'hide',
                                                }}
                                            >
                                                {this.props.eventData
                                                    .bookingUrl ? (
                                                    <Button
                                                        iconRight
                                                        iconName={
                                                            'external-link'
                                                        }
                                                        iconColor={Colors.white}
                                                        navigation={
                                                            this.props
                                                                .navigation
                                                        }
                                                        onPress={() =>
                                                            Linking.openURL(
                                                                `${this.props.eventData.bookingUrl}`
                                                            )
                                                        }
                                                        buttonStyle={[
                                                            {
                                                                marginTop:
                                                                    Layout.paddingLarge,
                                                            },
                                                        ]}
                                                        textColor={Colors.white}
                                                        textStyle={
                                                            TextStyles.Button
                                                        }
                                                        title={
                                                            'Booking options'
                                                        }
                                                    />
                                                ) : (
                                                    <View />
                                                )}
                                            </View>
                                        </View>
                                    </ScrollView>
                                </View>
                            )}
                        </EventConsumer>
                    </View>
                )}
            </VenueProfileConsumer>
        )
    }
}

export default withNavigation(EventDetailsScreen)

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
        borderTopLeftRadius: Layout.isMediumDevice ? Layout.borderRadiusXL : 0,
        borderTopRightRadius: Layout.isMediumDevice ? Layout.borderRadiusXL : 0,
        backgroundColor: Colors.white,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.paddingLarge
            : Layout.paddingXL,
        paddingBottom: Layout.isMediumDevice
            ? Layout.paddingLarge
            : Layout.paddingXL,
        overflow: 'hidden',
    },

    headerContainer: {
        //// flex: 1,
        paddingTop: Layout.isMediumDevice
            ? Layout.paddingLarge
            : Layout.topNavHeightDesktop,
        paddingBottom: Layout.paddingXL,
    },

    headerContent: {
        paddingTop: Layout.paddingLarge,
        paddingBottom: Layout.paddingLarge,
        paddingHorizontal: Layout.paddingLarge,
        borderRadius: Layout.borderRadiusSmall,

        borderColor: Colors.gray0,
        borderWidth: 1,
        backgroundColor: Colors.white,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
        elevation: Colors.elevationDepth,
    },
})
