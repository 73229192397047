import React from 'react'
import { Alert } from 'react-native'
import moment from 'moment'
import * as firebase from 'firebase'
import 'firebase/firestore'
import '../firebase.config'

const DB = firebase.firestore()
const AUTH = firebase.auth()
const EVENTS_COLLECTION = 'events'
const TODAY = new Date(moment().startOf('day'))

class Event extends React.Component {
    /* SPECIAL BATCH FUNCTION: TO UPDATE EVENTS DB WITH PLACEID AND RENAME */
    _batchEventUpdate = async () => {
        console.log('›››› BATCH OPERATION IN ACTION ››››')

        DB.collection(EVENTS_COLLECTION)
            .get()
            .then(async function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    let eventsRef = DB.collection(EVENTS_COLLECTION).doc(doc.id)

                    // var userRoleUpdate = {}
                    // userRoleUpdate[`roles.${firebase.auth().currentUser.uid}`] =
                    //     'owner'

                    // const uid = firebase.auth().currentUser.uid

                    // const timestamp = Date.now()

                    // return eventsRef.set(
                    //     { editedBy: { user: uid, timestamp } },
                    //     { merge: true }
                    // )

                    //return eventsRef.update(userRoleUpdate)
                    return eventsRef.update({
                        // placeId: 'ChIJOWvrIUuBhYARMEk3vdJP_ic',
                        //venueName: 'Indvstry',
                        // privateEvent: false,
                        // createdBy: {
                        //     uid: 'aOc6jW3KrkMeLYgghaPwTmkC6s82',
                        //     timestamp: '',
                        // },
                        // editedBy: {
                        //     uid: 'aOc6jW3KrkMeLYgghaPwTmkC6s82',
                        //     timestamp: '',
                        // },
                        //'editedBy.mojo': 'rojo,
                        //'editedBy.mojo': firebase.firestore.FieldValue.delete(),
                        // roles: firebase.firestore.FieldValue.delete(),
                        // privateEvent: firebase.firestore.FieldValue.delete(),
                        // lastEditBy: firebase.firestore.FieldValue.delete(),
                        // editedBy: firebase.firestore.FieldValue.delete(),
                        // 'roles.kitaro': 'reader',
                        // 'roles.aldo': 'writer',
                        // 'roles.ada': 'commenter',
                        //'roles.dev@indvstry': firebase.firestore.FieldValue.delete(),
                        //visibility: 'team', //private, team == 'placeId', public
                        //uid: 'aOc6jW3KrkMeLYgghaPwTmkC6s82',
                        // visibility: {
                        //     private: false,
                        //     owner: true,
                        //     venue: true,
                        //     group: true,
                        //     public: true,
                        // },
                        // createdBy: {
                        //     uid: 'aOc6jW3KrkMeLYgghaPwTmkC6s82',
                        //     fullName: 'Kenji Clarke',
                        //     avatarImage:
                        //         'https://firebasestorage.googleapis.com/v0/b/indvstry-app-dev.appspot.com/o/users%2FaOc6jW3KrkMeLYgghaPwTmkC6s82%2F3b4b0634-f975-4010-ab8a-10805da7da73.jpg?alt=media&token=748fb7db-5e37-4c7f-9e16-3436915efd85',
                        // },
                        //disabled: false,
                        // contributors: firebase.firestore.FieldValue.arrayUnion(
                        //     'aOc6jW3KrkMeLYgghaPwTmkC6s82'
                        // ),
                        visibility: {
                            owner: true,
                            venue: true,
                            group: true,
                            public: false,
                            private: false,
                        },
                    })
                })
            })
    }

    /* EXPERIMENTAL FUNCTIONS */

    // Can be implemented for adding new users to edit history, as well as new venues to a user profile. Allows switching, etc.
    _updateNestedObject = async () => {
        console.log('›››› BATCH OPERATION IN ACTION ››››')

        DB.collection('events')
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    var eventsRef = DB.collection('events').doc(doc.id)

                    var userRoleUpdate = {}
                    userRoleUpdate[
                        `roles.${firebase.auth().currentUser.email}`
                    ] = 'owner'

                    return eventsRef.update(userRoleUpdate)
                })
            })
    }

    /* FUNCTIONS */

    _getUserPlaceId = async () => {
        const venueProfileRef = await DB.collection('users').doc(this.uid)

        try {
            const getPlaceId = []
            await venueProfileRef.get().then(doc => {
                if (doc.exists) {
                    getPlaceId.push(doc.data().venueActive.placeId)
                } else {
                    return
                }
            })

            return {
                placeId: getPlaceId[0],
            }
        } catch ({ message }) {
            console.log(
                '***************** _getUserPlaceId: EVENT: FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    //** DOWNLOAD DATA **//
    _venueEventsPaged = async ({ size, end, placeId }) => {
        //const { placeId } = await this._getUserPlaceId()

        let eventsRef = await this.collection
            .orderBy('startDate', 'asc')
            .where('startDate', '>=', TODAY)
            .where('placeId', '==', placeId)
            .where('disabled', '==', false)
            .where('visibility.private', '==', false)
            .where('visibility.public', '==', true)
            .limit(size)

        try {
            if (end) {
                eventsRef = eventsRef.startAfter(end)
            }

            const venueEvents = []

            const setEventIds = []

            const setLastVisible = []

            // UPDATING TO MATCH SECURITY RULES
            //https://firebase.google.com/docs/firestore/security/rules-query#secure_and_query_documents_based_on_authuid

            const queryEvents = await eventsRef.get()

            // var user = AUTH.currentUser
            // const queryEvents = await eventsRef
            //     .where('owner', '==', user.uid)
            //     .get()

            queryEvents.forEach(async event => {
                if (event.exists) {
                    const getEventId = event.id //|| {}
                    setEventIds.push(getEventId)

                    const getLastVisibleStartDate = event.data().startDate || {}

                    setLastVisible.push(getLastVisibleStartDate)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await venueEvents.push({
                        eventId: event.id,
                        ...event.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/
            /* This is the correct equation for this flow */

            const lastVisible = setLastVisible[setLastVisible.length - 1] || {}

            // setLastVisible[
            //     (setLastVisible.length - 1) % setLastVisible.length
            // ] || {}

            // console.log(
            //     'EVENT:',
            //     ': ✓✓✓ :',
            //     setEventIds,
            //     'SIZE',
            //     size,
            //     'END',
            //     end,
            //     'VENUE-EVENTS',
            //     venueEvents
            //     // 'EVENT-SET-LAST-VISIBLE',
            //     // setLastVisible,
            //     // 'EVENT-LAST-VISIBLE',
            //     // lastVisible,
            // )

            return {
                setEventIds,
                venueEvents,
                eventsCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    _venueRecentEventsPaged = async ({ eventId }) => {
        /* TODO: Add privacy protection. This might be resolved simply by the Stories rules. */
        let eventsRef = await this.collection.doc(eventId).get()

        try {
            //const eventsRef = await eventsCollection.doc(eventId).get()

            const venueRecentEvent = []
            //await venueRecentEvent.push(eventsRef.data())

            await venueRecentEvent.push({
                eventId,
                ...eventsRef.data(),
            })

            return {
                venueRecentEvent,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    _venueArchiveEventsPaged = async ({ size, end }) => {
        const { placeId } = await this._getUserPlaceId()

        // Needs work: Each parameter seems to send multiple requests for same items.
        let eventsRef = await this.collection
            .orderBy('startDate', 'desc')
            .where('startDate', '<=', TODAY)
            .where('placeId', '==', placeId)
            .where('disabled', '==', false)
            .where('visibility.private', '==', false)
            .where('visibility.venue', '==', true)
            .where('visibility.owner', '==', true)
            .limit(size) //<-- On first query (of pagesize: 6), since it's an empty set, it counts the empty request as a request. I set the follow-up query to be pagesize 5. Now, gets 5 at a time. Actually, this does includes events with no story. so going backward, it becomes inconsistent. only way to control is to control displayed results.

        try {
            if (end) {
                eventsRef = eventsRef.startAfter(end)
            }
            // if (end) {
            //     eventsRef = eventsRef.startAfter(end).limit(size - 1) //<--  This allows the query to get 5 at a time.
            // }

            const venueArchiveEvents = []

            const setArchiveEventIds = []

            const setLastVisible = []

            const queryEvents = await eventsRef.get()

            queryEvents.forEach(async event => {
                if (event.exists) {
                    const getEventId = event.id //|| {}
                    setArchiveEventIds.push(getEventId)

                    const getLastVisibleStartDate = event.data().startDate || {}

                    setLastVisible.push(getLastVisibleStartDate)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await venueArchiveEvents.push({
                        eventId: event.id,
                        ...event.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible = setLastVisible[setLastVisible.length - 1] || {}
            // setLastVisible[
            //     (setLastVisible.length - 1) % setLastVisible.length
            // ] || {}

            return {
                setArchiveEventIds,
                venueArchiveEvents,
                archiveEventsCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** EVENT: FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    //** DOWNLOAD DATA **//
    _userArchiveEventsPaged = async ({ size, end }) => {
        const { placeId } = await this._getUserPlaceId()

        // Needs work: Each parameter seems to send multiple requests for same items.
        let eventsRef = await this.collection
            .orderBy('startDate', 'desc')
            .where('startDate', '<=', TODAY)
            //.where('uid', '==', this.uid) // Original way to only see one's own posts
            .where('placeId', '==', placeId)
            .where('contributors', 'array-contains', this.uid)
            .where('disabled', '==', false)
            .where('visibility.private', '==', false)
            .where('visibility.venue', '==', true)
            .where('visibility.owner', '==', true)
            .limit(size) //<-- On first query (of pagesize: 6), since it's an empty set, it counts the empty request as a request. I set the follow-up query to be pagesize 5. Now, gets 5 at a time. Actually, this does includes events with no story. so going backward, it becomes inconsistent. only way to control is to control displayed results.

        try {
            if (end) {
                eventsRef = eventsRef.startAfter(end)
            }
            // if (end) {
            //     eventsRef = eventsRef.startAfter(end).limit(size - 1) //<--  This allows the query to get 5 at a time.
            // }

            const venueArchiveEvents = []

            const setArchiveEventIds = []

            const setLastVisible = []

            const queryEvents = await eventsRef.get()

            queryEvents.forEach(async event => {
                if (event.exists) {
                    const getEventId = event.id //|| {}
                    setArchiveEventIds.push(getEventId)

                    const getLastVisibleStartDate = event.data().startDate || {}

                    setLastVisible.push(getLastVisibleStartDate)

                    // This function has to be the last in sequence, or it blocks the others from executing.
                    await venueArchiveEvents.push({
                        eventId: event.id,
                        ...event.data(),
                    })
                }
            })

            /**
             * The query sets the last item in the array as the startDate object.
             * This empty object {} keeps the function from returning an invalid object when it reaches the end of the array of exisiting events.
             **/

            const lastVisible = setLastVisible[setLastVisible.length - 1] || {}

            // setLastVisible[
            //     (setLastVisible.length - 1) % setLastVisible.length
            // ] || {}

            return {
                setArchiveEventIds,
                venueArchiveEvents,
                archiveEventsCursor: lastVisible,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            alert(message)
        }
    }

    // TODO: Group items with same startDate into one array; see filter, startAt, etc.
    // https://firebase.google.com/docs/firestore/query-data/order-limit-data

    _agendaEventsPaged = async ({ size, end }) => {
        /// Sorting and grouping into shared array will allow for multidate
        const { placeId } = await this._getUserPlaceId()

        let eventsRef = await this.collection
            .orderBy('startDate', 'asc')
            .where('startDate', '>=', TODAY)
            .where('placeId', '==', placeId)
            .where('disabled', '==', false)
            .where('visibility.private', '==', false)
            .where('visibility.venue', '==', true)
            .where('visibility.owner', '==', true)
            .limit(size)

        /* Great function to learn to implement later :) */
        // const observer = eventsRef.onSnapshot(
        //     querySnapshot => {
        //         console.log(
        //             `Received query snapshot of size ${querySnapshot.size}`
        //         )
        //     },
        //     err => {
        //         console.log(`Encountered error: ${err}`)
        //     }
        // )

        try {
            // if (end) {
            //     eventsRef = eventsRef.startAfter(end)
            // }

            const agendaEvents = []

            const setLastVisible = []

            const query = await eventsRef.get()

            query.forEach(doc => {
                if (doc.exists) {
                    /* Gets user roles */
                    //const roles = doc.data().roles
                    /* Sets cursor */
                    const getLastVisibleStartDate = doc.data().startDate
                    setLastVisible.push(getLastVisibleStartDate)

                    /* Sets event data */
                    const eventId = doc.id
                    const event = doc.data() || {}

                    const calendarDate = moment
                        .utc(event.startDate.seconds * 1000)
                        .format('YYYY[-]MM[-]DD')

                    const startDate = event.startDate.seconds * 1000
                    const endDate = event.endDate.seconds * 1000

                    agendaEvents.push({
                        eventId,
                        calendarDate,
                        startDate,
                        endDate,
                        title: event.title,
                        about: event.about,
                        venueName: event.venueName,
                        venueAddress: event.venueAddress,
                        visibility: event.visibility,
                        createdBy: event.createdBy,
                        fullName: event.createdBy.fullName,
                        avatarImage: event.createdBy.avatarImage,
                        contributors: event.contributors,
                    })
                }
            })

            const lastVisible =
                //setLastVisible[setLastVisible.length - 1] || {};
                setLastVisible[
                    (setLastVisible.length - 1) % setLastVisible.length
                ] || {}

            /**
             *  FYI — ALL EVENTS  COMING THROUGH HERE.
             */

            console.log('EVENTS ::: GET-AGENDA-EVENTS ::: done', agendaEvents)

            return { agendaEvents, agendaCursor: lastVisible }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
            //alert(message)
        }
    }

    // DOWNLOAD DATA -- SINGLE EVENT
    _getEventInfo = async ({ eventId }) => {
        let eventsRef = await this.collection.doc(eventId)

        try {
            const getSelectedEventData = []
            await eventsRef.get().then(doc => {
                if (doc.exists) {
                    //console.log('Document data:', doc.data())
                    const eventId = doc.id

                    const event = doc.data()

                    const calendarDate = moment
                        .utc(event.startDate.seconds * 1000)
                        .format('YYYY[-]MM[-]DD')

                    const startDate = event.startDate.seconds * 1000
                    const endDate = event.endDate.seconds * 1000

                    getSelectedEventData.push({
                        eventId,
                        calendarDate,
                        startDate,
                        endDate,
                        title: event.title,
                        about: event.about,
                        venueName: event.venueName,
                        venueAddress: event.venueAddress,
                        visibility: event.visibility,
                        createdBy: event.createdBy,
                        contributors: event.contributors,
                    })
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!')
                    return
                }
            })

            // console.log(
            //     'Document data in my special array',
            //     getSelectedEventData[0]
            // )

            return { selectedEventData: getSelectedEventData[0] }
        } catch ({ message }) {
            alert(message)
        }
    }

    //** UPLOAD DATA **//

    _createEvent = async ({
        title,
        about,
        startDate,
        endDate,
        placeId,
        venueName,
        venueAddress,
        visibility,
        bookingUrl,
    }) => {
        try {
            //const uid = this.uid
            // const timestamp = this.timestamp
            // const createdBy = {}
            // createdBy[`${this.timestamp}`] = this.uid

            let eventsRef = await this.collection

            eventsRef.add({
                title,
                about,
                startDate,
                endDate,
                placeId,
                venueName,
                venueAddress,
                visibility,
                bookingUrl,
                disabled: false,
                contributors: [this.uid],
                uid: this.uid,
                createdBy: {
                    uid: this.uid,
                    fullName: this.user.displayName,
                    avatarImage: this.user.photoURL,
                    timestamp: this.timestamp,
                },
            })

            //console.log('event api create event', title)
        } catch ({ message }) {
            console.log(message)
        }
    }

    _updateEvent = async ({
        eventId,
        title,
        about,
        startDate,
        endDate,
        visibility,
        bookingUrl,
    }) => {
        try {
            // const editedBy = {}
            // editedBy[`${this.timestamp}`] = this.uid

            //const timestamp = FieldValue.serverTimestamp()
            // const timestamp = this.timestamp
            // const uid = this.uid

            // const lastEditedBy = {}
            // lastEditedBy[`${this.timestamp}`] = this.uid

            let eventsRef = await this.collection

            eventsRef.doc(eventId).update({
                title,
                about,
                startDate,
                endDate,
                visibility,
                bookingUrl,
                contributors: firebase.firestore.FieldValue.arrayUnion(
                    this.uid
                ),
            })

            // this.collection
            //     .doc(eventId)
            //     .set({ 'edited.by': { timestamp: uid } }, { merge: true })
        } catch ({ message }) {
            //alert(message)
            console.log(message)
        }
    }

    _updateEventContributors = async ({ eventId }) => {
        try {
            let eventsRef = await this.collection

            eventsRef.doc(eventId).update({
                contributors: firebase.firestore.FieldValue.arrayUnion(
                    this.uid
                ),
            })
        } catch ({ message }) {
            //alert(message)
            console.log(message)
        }
    }

    _disableEvent = async eventId => {
        try {
            let eventsRef = await this.collection.doc(eventId)

            eventsRef.update({ disabled: true }).then(() => {
                console.log('Document successfully removed!')
                Alert.alert('Your event has been successfully removed.')
            })
        } catch ({ error }) {
            console.error('Error removing document: ', error)
            Alert.alert(
                'This event could not be removed. If an event you created has contributors who edited or added story content, it cannot be removed at this time.'
            )
        }
    }

    _deleteEvent = async eventId => {
        try {
            let eventsRef = await this.collection

            eventsRef
                .doc(eventId)
                .delete()
                .then(() => {
                    console.log('Document successfully deleted!')
                    Alert.alert('Your event has been successfully deleted.')
                })
        } catch ({ error }) {
            console.error('Error removing document: ', error)
            Alert.alert(
                'This event could not be removed. If an event you created has contributors who edited or added story content, it cannot be removed at this time.'
            )
        }
    }

    // Helpers
    get collection() {
        return DB.collection(EVENTS_COLLECTION)
    }

    get uid() {
        return AUTH.currentUser.uid
    }

    // get uid() {
    //     return (AUTH.currentUser || {}).uid
    // }

    get user() {
        return AUTH.currentUser
    }

    get timestamp() {
        return Date.now()
    }
}

export default new Event()
