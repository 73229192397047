import React from 'react'
import { FlatList, RefreshControl, StyleSheet, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Layout, Buttons, Colors, TextStyles } from '../constants'
import StoriesItem from './StoriesItem'
import ButtonIcon from './ButtonIcon'

export default class StoriesPlaylist extends React.Component {
    renderSeparator = () => {
        return (
            <View
                style={{
                    width: 8,
                }}
            />
        )
    }

    renderItem = ({ item }) => {
        if (this.props.eventData.eventId === item.eventId) {
            const video = item.video || null
            const videoThumbnail = item.videoThumbnail || null

            return (
                <StoriesItem
                    onPress={this.props.setSelectedVideo}
                    eventId={item.eventId}
                    userId={item.uid}
                    video={video}
                    videoThumbnail={videoThumbnail}
                    timestamp={item.timestamp}
                    theme={item.theme}
                    storyId={item.storyId}
                />
            )
        }
    }

    // Original key extractor
    keyExtractor = item => item.timestamp.toString()

    render() {
        const style = [styles.active]
        if (this.props.disabled) {
            style.push(styles.disabled)
        }

        return (
            <View style={[style, this.props.style]}>
                {this.props.disabled ? (
                    <View />
                ) : (
                    <LinearGradient
                        colors={[
                            'transparent',
                            'rgba(0,0,0,.6)',
                            'rgba(0,0,0,.8)',
                        ]}
                        style={styles.gradient}
                    >
                        <FlatList
                            ListHeaderComponent={
                                <View
                                    style={{
                                        width: Layout.paddingMedium,
                                    }}
                                />
                            }
                            setSelectedVideo={this.props.setSelectedVideo}
                            data={this.props.storyData}
                            renderItem={this.renderItem}
                            keyExtractor={this.keyExtractor}
                            inverted={this.props.isInverted}
                            style={styles.list}
                            showsHorizontalScrollIndicator={false}
                            horizontal
                            ItemSeparatorComponent={this.renderSeparator}
                            ListFooterComponent={footerProps => (
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: Layout.paddingMedium,
                                    }}
                                >
                                    <ButtonIcon
                                        {...footerProps}
                                        iconName={
                                            this.props.isInverted
                                                ? 'arrow-left'
                                                : 'arrow-right'
                                        }
                                        iconColor={Colors.white}
                                        buttonStyle={[
                                            Buttons.menuButtonTransparentBG,
                                            { alignSelf: 'center' },
                                        ]}
                                        onPress={this.props.onPressFooter}
                                    />
                                </View>
                            )}
                            refreshControl={
                                <RefreshControl
                                    refreshing={false}
                                    onRefresh={this.props.onRefresh}
                                />
                            }
                        />
                    </LinearGradient>
                )}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    active: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        zIndex: 10,
    },

    disabled: {
        position: 'absolute',
        bottom: 0,
        height: 0,
        opacity: 0,
        width: '100%',
    },

    list: {
        flexGrow: 0,
        zIndex: 10,
        paddingTop: 16,
    },

    gradient: {
        flex: 1,
        zIndex: 0,
        paddingBottom: 96,
    },
})
