import React from 'react'
import {
    ImageBackground,
    Image,
    RefreshControl,
    ActivityIndicator,
    FlatList,
    ScrollView,
    Text,
    View,
} from 'react-native'
import { Linking } from 'expo'
import moment from 'moment'
import { Layout, Buttons, Colors, TextStyles, Icons } from '../../constants'
import { WindowResizeConsumer } from '../../context/WindowResizeContext'
import { VenueProfileConsumer } from '../../context/VenueProfileContext'
import { VenueStoriesConsumer } from '../../context/VenueStoriesContext'
import VenueStoryItem from '../../components/VenueStoryItem'
import VenueStoryEmptyItem from '../../components/VenueStoryEmptyItem'
import VenueMemberItem from '../../components/VenueMemberItem'

import RequestInfoComponent from '../../components/RequestInfo'

import Button from '../../components/Button'
import ButtonIcon from '../../components/ButtonIcon'
import Footer from '../../components/Footer'
import ImageCarouselHero from '../../components/ImageCarouselHero'

export default class VenueProfile extends React.Component {
    state = {
        heroImage: 'https://',
    }

    componentDidMount = async () => {
        this.clearUrlToken()
        await this.props.navigation.setParams({
            active: false,
            //transparentHeader: true,
        })

        /* Set Venue */

        const placeId = await this.props.navigation.getParam('placeId')

        await this.venueProfileContext.initializeVenueProfile(placeId)
        await this.venueStoriesContext.setVenuePlaceId(placeId)

        await this.props.navigation.setParams({
            indvstryUrl: this.venueProfileContext.indvstryUrl,
            placeId: placeId,
            //active: true,
        })

        // this.setState({
        //     heroImage: this.props.navigation.getParam('heroImage'),
        // })

        /* Stories functions */
        this.onRefresh(placeId)

        // await this.venueStoriesContext.initializeVenueStories()

        // const TODAY = new Date(moment().startOf('day'))
        // await this.venueStoriesContext.setEventsCursor(TODAY)

        // const size = await 1
        // await this.venueStoriesContext.venueEventsRemoteRequest(size, placeId)

        this.clearUrlToken()
    }

    clearUrlToken = () => {
        const { origin, pathname } = window.location
        window.history.replaceState(
            history.state,
            window.document.title,
            `${origin}${pathname}`
        )
    }

    _____onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= Layout.SCREEN_HEIGHT * 0.236) {
            this.props.navigation.setParams({ active: false })
        } else {
            this.props.navigation.setParams({ active: true })
        }
    }

    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y
        // const topNavHeight = Layout.isMediumDevice
        //     ? Layout.topNavHeightMobile
        //     : Layout.topNavHeightDesktop

        if (pageYOffset >= 4) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }

        //this.clearUrlToken()
    }

    /* Story content functions */

    onRefresh = async placeId => {
        //await this.profileContext.initializeUserAccount()
        await this.venueStoriesContext.initializeVenueStories()

        const TODAY = new Date(moment().startOf('day'))
        await this.venueStoriesContext.setEventsCursor(TODAY)

        const size = await 1
        await this.venueStoriesContext.venueEventsRemoteRequest(size, placeId)

        await this.venueStoriesContext.venueMostRecentStoriesRemoteRequest(
            placeId,
            6
        )
    }

    // onPressFooter = async () => {
    //     const size = await 1
    //     await this.venueStoriesContext.venueEventsRemoteRequest(size)
    // }

    onScrollToEnd = async () => {
        const placeId = await this.props.navigation.getParam('placeId')

        const size = await 1
        await this.venueStoriesContext.venueEventsRemoteRequest(size, placeId)
    }

    renderVenuePostItem = ({ item }) => {
        return (
            <VenueStoryItem
                navigation={this.props.navigation}
                eventData={item}
                viewabilityConfig={this.viewabilityConfig}
                isCreator={false}
            />
        )
    }

    keyExtractor = item => item.eventId

    renderVenueMemberItem = ({ item }) => {
        return (
            <VenueMemberItem navigation={this.props.navigation} userId={item} />
        )
    }

    keyExtractorTeam = item => item

    /* Page elements */
    renderSeparator = () => {
        return (
            <View
                style={{
                    width: '100%',
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.paddingLarge,
                    marginBottom: Layout.paddingLarge,
                }}
            />
        )
    }

    renderColumnSeparator = () => {
        return (
            <View
                style={{
                    backgroundColor: Colors.white,
                    width: Layout.isMediumDevice ? '100%' : 1,
                    height: Layout.isMediumDevice ? 1 : null,
                }}
            />
        )
    }

    renderLoading = () => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ActivityIndicator
                    size="small"
                    style={{ alignSelf: 'center' }}
                    color={Colors.tintColor}
                />

                {/* <Text style={[TextStyles.Logotype, { padding: 16 }]}>
                    Indvstry
                </Text> */}
                <View
                    style={{
                        overflow: 'hidden',
                        borderRadius: 60,
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: Layout.paddingSmall,
                        padding: Layout.paddingSmall,
                    }}
                >
                    <Image
                        source={require('../../assets/images/icon.png')}
                        style={{
                            width: Layout.isMediumDevice ? Icons.XL : Icons.XXL,
                            height: Layout.isMediumDevice
                                ? Icons.XL
                                : Icons.XXL,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
            </View>
        )
    }

    render() {
        return (
            <WindowResizeConsumer>
                {windowContext => (
                    <View
                        style={{ flex: 1 }}
                        ref={ref => {
                            this.windowContext = windowContext
                        }}
                    >
                        <VenueStoriesConsumer>
                            {venueStoriesContext => {
                                const venueStory =
                                    venueStoriesContext.venueStory[0]

                                return (
                                    <View
                                        style={{ flex: 1 }}
                                        ref={ref => {
                                            this.venueStoriesContext = venueStoriesContext
                                        }}
                                    >
                                        <VenueProfileConsumer>
                                            {venueProfileContext => {
                                                return (
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                        }}
                                                        ref={ref => {
                                                            this.venueProfileContext = venueProfileContext
                                                        }}
                                                    >
                                                        {venueProfileContext.loading ? (
                                                            this.renderLoading()
                                                        ) : (
                                                            <View
                                                                style={{
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                {/* <ImageBackground
                                                                    source={
                                                                        this
                                                                            .state
                                                                            .heroImage
                                                                    }
                                                                    // source={this.props.navigation.getParam(
                                                                    //     'heroImage'
                                                                    // )}
                                                                    style={[
                                                                        Layout.bannerHeaderContainer,
                                                                        {
                                                                            position:
                                                                                'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            right: 0,
                                                                            bottom: 0,
                                                                            zIndex: -100,

                                                                            minHeight: 480,
                                                                            height:
                                                                                Layout.SCREEN_HEIGHT *
                                                                                0.236,

                                                                            backgroundColor:
                                                                                'transparent',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <View
                                                                        style={[
                                                                            Layout.contentOverlayHero,
                                                                            {
                                                                                minHeight: 480,
                                                                                paddingHorizontal: 0,
                                                                            },
                                                                        ]}
                                                                    />
                                                                </ImageBackground> */}
                                                                {/* <ImageCarouselHero
                                                                    imageData={
                                                                        imageData.neighborhoods
                                                                    }
                                                                    navigation={
                                                                        this
                                                                            .props
                                                                            .navigation
                                                                    }
                                                                /> */}

                                                                <ScrollView
                                                                    style={{
                                                                        flex: 1,
                                                                    }}
                                                                    onScroll={event =>
                                                                        this.onScroll(
                                                                            event
                                                                        )
                                                                    }
                                                                    scrollEventThrottle={
                                                                        16
                                                                    }
                                                                    // decelerationRate={
                                                                    //     'fast'
                                                                    // }
                                                                >
                                                                    {/* <View
                                                                        style={[
                                                                            Layout.bannerHeaderContainer,
                                                                            {
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                minHeight: 360,
                                                                                height:
                                                                                    Layout.SCREEN_HEIGHT *
                                                                                    0.246,
                                                                            },
                                                                        ]}
                                                                    /> */}
                                                                    {venueStoriesContext
                                                                        .venueMostRecentStories
                                                                        .length ? (
                                                                        <ImageCarouselHero
                                                                            imageData={
                                                                                venueStoriesContext.venueMostRecentStories
                                                                            }
                                                                            navigation={
                                                                                this
                                                                                    .props
                                                                                    .navigation
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <View
                                                                            style={
                                                                                Layout.bannerHeaderContainer
                                                                            }
                                                                        >
                                                                            <Image
                                                                                // source={
                                                                                //     this
                                                                                //         .state
                                                                                //         .heroImage
                                                                                // }
                                                                                source={this.props.navigation.getParam(
                                                                                    'heroImage'
                                                                                )}
                                                                                style={{
                                                                                    height:
                                                                                        '100%',
                                                                                    //maxWidth: 1440,
                                                                                    width: Layout.isMediumDevice
                                                                                        ? Layout.SCREEN_WIDTH
                                                                                        : Layout.SCREEN_WIDTH -
                                                                                          Layout.paddingXL,
                                                                                    borderRadius: Layout.isMediumDevice
                                                                                        ? 0
                                                                                        : Layout.borderRadiusSmall,
                                                                                }}
                                                                            />
                                                                        </View>
                                                                    )}

                                                                    <View
                                                                        style={
                                                                            Layout.projectContainer
                                                                        }
                                                                    >
                                                                        <View
                                                                            style={
                                                                                Layout.columnContainer
                                                                            }
                                                                        >
                                                                            <View
                                                                                style={
                                                                                    Layout.columnTwo
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Heading1,
                                                                                        {
                                                                                            paddingBottom:
                                                                                                Layout.paddingSmall,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    {
                                                                                        venueProfileContext.name
                                                                                    }
                                                                                </Text>
                                                                                <View
                                                                                    style={{
                                                                                        flexDirection:
                                                                                            'row',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        justifyContent:
                                                                                            'flex-start',
                                                                                        paddingBottom:
                                                                                            Layout.paddingLarge,
                                                                                    }}
                                                                                >
                                                                                    <Text
                                                                                        style={[
                                                                                            TextStyles.Paragraph,
                                                                                        ]}
                                                                                    >
                                                                                        {
                                                                                            venueProfileContext.city
                                                                                        }

                                                                                        ,{' '}
                                                                                        {
                                                                                            venueProfileContext.state
                                                                                        }

                                                                                        ・
                                                                                    </Text>
                                                                                    <Text
                                                                                        style={[
                                                                                            TextStyles.OverlineLight,
                                                                                        ]}
                                                                                    >
                                                                                        {
                                                                                            venueProfileContext
                                                                                                .types[0]
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Paragraph,
                                                                                        {
                                                                                            paddingBottom:
                                                                                                Layout.paddingLarge,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    {
                                                                                        venueProfileContext.about
                                                                                    }
                                                                                </Text>
                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Overline,
                                                                                        {
                                                                                            display: venueProfileContext
                                                                                                .members
                                                                                                .length
                                                                                                ? 'flex'
                                                                                                : 'none',
                                                                                            paddingBottom:
                                                                                                Layout.paddingSmall,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    Our
                                                                                    team
                                                                                </Text>

                                                                                <FlatList
                                                                                    style={{
                                                                                        flexGrow: 0,
                                                                                        width: Layout.isMediumDevice
                                                                                            ? Layout.SCREEN_WIDTH
                                                                                            : '100%',
                                                                                        alignSelf: Layout.isMediumDevice
                                                                                            ? 'center'
                                                                                            : 'flex-start',
                                                                                    }}
                                                                                    horizontal={
                                                                                        Layout.isMediumDevice
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    numColumns={
                                                                                        Layout.isMediumDevice
                                                                                            ? 1
                                                                                            : 2
                                                                                    }
                                                                                    contentContainerStyle={{
                                                                                        paddingBottom: Layout.isMediumDevice
                                                                                            ? 0
                                                                                            : Layout.paddingLarge,
                                                                                    }}
                                                                                    data={
                                                                                        venueProfileContext.members
                                                                                    }
                                                                                    keyExtractor={
                                                                                        this
                                                                                            .keyExtractorTeam
                                                                                    }
                                                                                    renderItem={
                                                                                        this
                                                                                            .renderVenueMemberItem
                                                                                    }
                                                                                    showsHorizontalScrollIndicator={
                                                                                        false
                                                                                    }
                                                                                    showsVerticalScrollIndicator={
                                                                                        false
                                                                                    }
                                                                                />

                                                                                {Layout.isMediumDevice ? (
                                                                                    this.renderSeparator()
                                                                                ) : (
                                                                                    <View />
                                                                                )}

                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Heading2,
                                                                                        {
                                                                                            paddingBottom:
                                                                                                Layout.paddingMedium,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    Hours
                                                                                    and
                                                                                    location
                                                                                </Text>
                                                                                <View
                                                                                    style={{
                                                                                        flexDirection: Layout.isMediumDevice
                                                                                            ? 'column'
                                                                                            : 'row',
                                                                                    }}
                                                                                >
                                                                                    <View
                                                                                        style={{
                                                                                            flex: 1,
                                                                                        }}
                                                                                    >
                                                                                        <Button
                                                                                            navigation={
                                                                                                this
                                                                                                    .props
                                                                                                    .navigation
                                                                                            }
                                                                                            onPress={() =>
                                                                                                Linking.openURL(
                                                                                                    `${venueProfileContext.mapsUrl}`
                                                                                                )
                                                                                            }
                                                                                            buttonStyle={[
                                                                                                Buttons.buttonUnderline,
                                                                                                {
                                                                                                    alignSelf:
                                                                                                        'flex-start',
                                                                                                    paddingHorizontal: 0,
                                                                                                },
                                                                                            ]}
                                                                                            textStyle={{
                                                                                                lineHeight: 26,
                                                                                            }}
                                                                                            textColor={
                                                                                                Colors.tintColor
                                                                                            }
                                                                                            title={
                                                                                                venueProfileContext.name +
                                                                                                '\n' +
                                                                                                venueProfileContext.streetNumber +
                                                                                                ' ' +
                                                                                                venueProfileContext.street +
                                                                                                '\n' +
                                                                                                venueProfileContext.city +
                                                                                                ', ' +
                                                                                                venueProfileContext.state +
                                                                                                ' ' +
                                                                                                venueProfileContext.postalCode
                                                                                            }
                                                                                        />
                                                                                        <Button
                                                                                            navigation={
                                                                                                this
                                                                                                    .props
                                                                                                    .navigation
                                                                                            }
                                                                                            onPress={() =>
                                                                                                Linking.openURL(
                                                                                                    `tel:${venueProfileContext.phoneIntl}`
                                                                                                )
                                                                                            }
                                                                                            buttonStyle={[
                                                                                                Buttons.buttonUnderline,
                                                                                                {
                                                                                                    alignSelf:
                                                                                                        'flex-start',
                                                                                                    paddingHorizontal: 0,
                                                                                                },
                                                                                            ]}
                                                                                            textColor={
                                                                                                Colors.tintColor
                                                                                            }
                                                                                            // textStyle={{
                                                                                            //     textDecorationLine:
                                                                                            //         'underline',
                                                                                            // }}
                                                                                            title={
                                                                                                venueProfileContext.phoneIntl
                                                                                            }
                                                                                        />

                                                                                        <Button
                                                                                            navigation={
                                                                                                this
                                                                                                    .props
                                                                                                    .navigation
                                                                                            }
                                                                                            onPress={() =>
                                                                                                Linking.openURL(
                                                                                                    `${venueProfileContext.website}`
                                                                                                )
                                                                                            }
                                                                                            buttonStyle={[
                                                                                                Buttons.buttonUnderline,
                                                                                                {
                                                                                                    alignSelf:
                                                                                                        'flex-start',
                                                                                                    paddingHorizontal: 0,
                                                                                                },
                                                                                            ]}
                                                                                            textColor={
                                                                                                Colors.tintColor
                                                                                            }
                                                                                            textStyle={{
                                                                                                textDecorationLine:
                                                                                                    'underline',
                                                                                            }}
                                                                                            title={
                                                                                                venueProfileContext.website
                                                                                            }
                                                                                        />
                                                                                        <View
                                                                                            style={{
                                                                                                paddingTop:
                                                                                                    Layout.paddingMedium,
                                                                                                paddingBottom: Layout.isMediumDevice
                                                                                                    ? Layout.paddingLarge
                                                                                                    : 0,
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                                // alignItems:
                                                                                                //     'center',
                                                                                                flexGrow: 0,
                                                                                            }}
                                                                                        >
                                                                                            <ButtonIcon
                                                                                                iconName={
                                                                                                    'instagram'
                                                                                                }
                                                                                                iconSize={
                                                                                                    Icons.medium
                                                                                                }
                                                                                                iconColor={
                                                                                                    Colors.tintColor
                                                                                                }
                                                                                                buttonStyle={[
                                                                                                    Buttons.menuButtonTransparentBG,
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            Colors.gray0,
                                                                                                        marginRight:
                                                                                                            Layout.paddingMedium,
                                                                                                    },
                                                                                                ]}
                                                                                                navigation={
                                                                                                    this
                                                                                                        .props
                                                                                                        .navigation
                                                                                                }
                                                                                                onPress={() =>
                                                                                                    Linking.openURL(
                                                                                                        `${venueProfileContext.social.instagram}`
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <ButtonIcon
                                                                                                iconName={
                                                                                                    'facebook'
                                                                                                }
                                                                                                iconSize={
                                                                                                    Icons.medium
                                                                                                }
                                                                                                iconColor={
                                                                                                    Colors.tintColor
                                                                                                }
                                                                                                buttonStyle={[
                                                                                                    Buttons.menuButtonTransparentBG,
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            Colors.gray0,
                                                                                                        marginRight:
                                                                                                            Layout.paddingMedium,
                                                                                                    },
                                                                                                ]}
                                                                                                navigation={
                                                                                                    this
                                                                                                        .props
                                                                                                        .navigation
                                                                                                }
                                                                                                onPress={() =>
                                                                                                    Linking.openURL(
                                                                                                        `${venueProfileContext.social.facebook}`
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </View>
                                                                                    </View>

                                                                                    <View
                                                                                        style={{
                                                                                            flex: 1,
                                                                                            paddingLeft: Layout.isMediumDevice
                                                                                                ? 0
                                                                                                : Layout.paddingXL,
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={[
                                                                                                TextStyles.Overline,
                                                                                                {
                                                                                                    paddingBottom:
                                                                                                        Layout.paddingSmall,
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            Hours
                                                                                        </Text>

                                                                                        <Text
                                                                                            style={[
                                                                                                TextStyles.Caption,
                                                                                            ]}
                                                                                        >
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[0]
                                                                                            }
                                                                                            {
                                                                                                '\n'
                                                                                            }
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[1]
                                                                                            }
                                                                                            {
                                                                                                '\n'
                                                                                            }
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[2]
                                                                                            }
                                                                                            {
                                                                                                '\n'
                                                                                            }
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[3]
                                                                                            }
                                                                                            {
                                                                                                '\n'
                                                                                            }
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[4]
                                                                                            }
                                                                                            {
                                                                                                '\n'
                                                                                            }
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[5]
                                                                                            }
                                                                                            {
                                                                                                '\n'
                                                                                            }
                                                                                            {
                                                                                                venueProfileContext
                                                                                                    .openingHours[6]
                                                                                            }
                                                                                        </Text>
                                                                                    </View>
                                                                                </View>

                                                                                {this.renderSeparator()}

                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.ParagraphBold,
                                                                                    ]}
                                                                                >
                                                                                    Private
                                                                                    Events
                                                                                </Text>

                                                                                <Text
                                                                                    style={[
                                                                                        TextStyles.Caption,
                                                                                        {
                                                                                            paddingBottom:
                                                                                                Layout.paddingMedium,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    If
                                                                                    you
                                                                                    are
                                                                                    interested
                                                                                    in
                                                                                    learning
                                                                                    more
                                                                                    about
                                                                                    our
                                                                                    offerings,
                                                                                    please
                                                                                    email
                                                                                    us
                                                                                    and
                                                                                    we
                                                                                    will
                                                                                    contact
                                                                                    you
                                                                                    to
                                                                                    discuss
                                                                                    details.
                                                                                </Text>
                                                                                <Button
                                                                                    onPress={() =>
                                                                                        Linking.openURL(
                                                                                            `mailto:${venueProfileContext.email}`
                                                                                        )
                                                                                    }
                                                                                    textColor={
                                                                                        Colors.tintColor
                                                                                    }
                                                                                    buttonStyle={[
                                                                                        Buttons.buttonMini,
                                                                                        {
                                                                                            alignSelf:
                                                                                                'flex-start',
                                                                                            marginBottom:
                                                                                                Layout.paddingMedium,
                                                                                        },
                                                                                    ]}
                                                                                    title={
                                                                                        'Request information'
                                                                                    }
                                                                                />

                                                                                {Layout.isMediumDevice ? (
                                                                                    this.renderSeparator()
                                                                                ) : (
                                                                                    <View />
                                                                                )}
                                                                            </View>

                                                                            <FlatList
                                                                                style={
                                                                                    Layout.columnTwo
                                                                                }
                                                                                contentContainerStyle={[
                                                                                    // Layout.columnTwo,
                                                                                    {
                                                                                        alignItems: Layout.isMediumDevice
                                                                                            ? null
                                                                                            : 'flex-end',
                                                                                        // maxWidth: Layout.isMediumDevice
                                                                                        //     ? Layout.SCREEN_WIDTH
                                                                                        //     : 480,
                                                                                        // backgroundColor:
                                                                                        //     'red',
                                                                                    },
                                                                                ]}
                                                                                ListHeaderComponent={
                                                                                    <View
                                                                                        style={{
                                                                                            flex: 1,
                                                                                            maxWidth: Layout.isMediumDevice
                                                                                                ? Layout.SCREEN_WIDTH
                                                                                                : 360,
                                                                                            minWidth: Layout.isMediumDevice
                                                                                                ? '100%'
                                                                                                : 360,
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={[
                                                                                                TextStyles.Heading2,
                                                                                                {
                                                                                                    textAlign:
                                                                                                        'left',
                                                                                                    paddingBottom:
                                                                                                        Layout.paddingLarge,
                                                                                                    alignSelf:
                                                                                                        'flex-start',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            Upcoming
                                                                                            events
                                                                                        </Text>
                                                                                    </View>
                                                                                }
                                                                                // onScroll={async event => {
                                                                                //     await this.onScroll(
                                                                                //         event
                                                                                //     )
                                                                                // }}
                                                                                scrollEventThrottle={
                                                                                    16
                                                                                }
                                                                                data={
                                                                                    venueStoriesContext.venueEvents
                                                                                }
                                                                                renderItem={
                                                                                    this
                                                                                        .renderVenuePostItem
                                                                                }
                                                                                keyExtractor={
                                                                                    this
                                                                                        .keyExtractor
                                                                                }
                                                                                showsVerticalScrollIndicator={
                                                                                    false
                                                                                }
                                                                                numColumns={
                                                                                    1
                                                                                }
                                                                                onEndReachedThreshold={
                                                                                    0.5
                                                                                }
                                                                                onEndReached={
                                                                                    this
                                                                                        .onScrollToEnd
                                                                                }
                                                                                viewabilityConfig={
                                                                                    this
                                                                                        .viewabilityConfig
                                                                                }
                                                                                ListFooterComponent={
                                                                                    <View
                                                                                        style={[
                                                                                            Layout.cardContent,
                                                                                            {
                                                                                                borderColor:
                                                                                                    Colors.gray0,
                                                                                                borderWidth: 1,
                                                                                                maxWidth: 360,
                                                                                                paddingBottom:
                                                                                                    Layout.paddingLarge,
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <RequestInfoComponent
                                                                                            buttonIcon={
                                                                                                false
                                                                                            }
                                                                                            placeId={
                                                                                                venueProfileContext.placeId
                                                                                            }
                                                                                            title={`Follow ${venueProfileContext.name}`}
                                                                                            message={
                                                                                                'Sign up to receive the latest news about our upcoming events.'
                                                                                            }
                                                                                        />
                                                                                    </View>
                                                                                }
                                                                            />
                                                                        </View>
                                                                    </View>

                                                                    <Footer
                                                                        navigation={
                                                                            this
                                                                                .props
                                                                                .navigation
                                                                        }
                                                                    />
                                                                </ScrollView>
                                                            </View>
                                                        )}
                                                    </View>
                                                )
                                            }}
                                        </VenueProfileConsumer>
                                    </View>
                                )
                            }}
                        </VenueStoriesConsumer>
                    </View>
                )}
            </WindowResizeConsumer>
        )
    }
}
