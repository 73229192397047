import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Colors, Icons } from '../constants'
import { Feather } from '@expo/vector-icons'

export default class ButtonIcon extends React.Component {
    render() {
        let style = [styles.button]
        if (this.props.disabled) {
            style.push(styles.disabledButton)
        }
        return (
            <View
                style={[style, this.props.buttonStyle]}
                disabled={this.props.disabled}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {this.props.loading ? (
                        <ActivityIndicator
                            size="small"
                            color={
                                this.props.activityColor
                                    ? this.props.activityColor
                                    : Colors.white
                            }
                        />
                    ) : (
                        <Feather
                            name={this.props.iconName}
                            style={{
                                color: this.props.disabled
                                    ? 'transparent'
                                    : this.props.iconStyle
                                    ? this.props.iconStyle
                                    : Colors.tintColor,
                            }}
                            size={
                                this.props.iconSize
                                    ? this.props.iconSize
                                    : Icons.medium
                            }
                            color={this.props.iconColor}
                        />
                    )}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    button: {
        position: 'absolute',
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 16,
    },
    disabledButton: {
        position: 'absolute',
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 16,
    },
})
