import React from 'react'
import * as firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import '../firebase.config'

const DB = firebase.firestore()
const AUTH = firebase.auth()
const VENUES_COLLECTION = 'venues'
const USERS_COLLECTION = 'users'

class Venue extends React.Component {
    // Helpers
    get collection() {
        return DB.collection(VENUES_COLLECTION)
    }

    get uid() {
        return AUTH.currentUser.uid
    }

    get user() {
        return AUTH.currentUser
    }

    get timestamp() {
        return Date.now()
    }

    /* CREATE */
    _newVenue = async ({
        placeId,
        name,
        formattedAddress,
        plusCode,
        types,
        mapsUrl,
        phoneIntl,
        website,
        priceLevel,
    }) => {
        await this.collection.doc(placeId).set({
            //placeId,
            name,
            formattedAddress,
            plusCode,
            types,
            mapsUrl,
            phoneIntl,
            website,
            priceLevel,

            // add user to list

            groupName: name + ' Group',

            visibility: {
                group: true,
                owner: true,
                public: true,
                team: true,
            },

            createdBy: {
                uid: this.uid,
                timestamp: this.timestamp,
            },

            members: [this.uid],
        })
    }

    _createVenue = async ({
        placeId,
        name,
        formattedAddress,
        plusCode,
        types,
        mapsUrl,
        phoneIntl,
        website,
        priceLevel,
    }) => {
        const placesRef = await this.collection.doc(placeId)

        const placesDoc = placesRef
            .get()
            .then(doc => {
                if (!doc.exists) {
                    console.log('New place document!')
                    this._newVenue({
                        placeId,
                        name,
                        formattedAddress,
                        plusCode,
                        types,
                        mapsUrl,
                        phoneIntl,
                        website,
                        priceLevel,
                    })
                } else {
                    console.log('Document data:', doc.data())
                    console.log(
                        '›››››››››››››››››› This venue exists ››››››››››››››››››'
                    )
                    this._addVenueMember(placeId)
                }
            })
            .catch(err => {
                console.log('Error getting document', err)
            })

        // try {
        //     if (!placesDoc) {
        //         this._newVenue({
        //             placeId,
        //             name,
        //             formattedAddress,
        //             plusCode,
        //             types,
        //             mapsUrl,
        //             phoneIntl,
        //             website,
        //             priceLevel,
        //         })
        //     } else {
        //         this._addVenueMember(placeId)
        //         console.log(
        //             '›››››››››››››››››› This venue exists ››››››››››››››››››'
        //         )
        //     }
        // } catch ({ message }) {
        //     //console.log(message)
        //     alert(message)
        // }
    }

    _addVenueMember = async placeId => {
        let placesRef = await this.collection.doc(placeId)

        await placesRef.update({
            members: firebase.firestore.FieldValue.arrayUnion(this.uid),
        })
    }

    _removeVenueMember = async placeId => {
        let placesRef = await this.collection.doc(placeId)

        await placesRef.update({
            members: firebase.firestore.FieldValue.arrayRemove(this.uid),
        })
    }

    _addAbout = async ({ placeId, aboutNew }) => {
        let placesRef = await this.collection.doc(placeId)
        await placesRef.update({
            about: aboutNew,
        })
    }

    /* GET */

    /**
     *
     *    rating: data.rating,
     *    userRatingsTotal: data.user_ratings_total,
     *
     */

    _getUserProfile = async () => {
        const userProfileRef = await DB.collection(USERS_COLLECTION).doc(
            this.uid
        )

        try {
            const placeId = []

            await userProfileRef.get().then(doc => {
                if (doc.exists) {
                    placeId.push(doc.data().placeId)
                }
            })

            return placeId[0]
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    _getVenueProfile = async placeId => {
        //const placeId = await this._getUserProfile()

        /* TODO: Add private mode protection */
        const venueProfileRef = await this.collection.doc(placeId)

        try {
            const venueProfile = []
            await venueProfileRef.get().then(doc => {
                if (doc.exists) {
                    venueProfile.push(doc.data())
                }
            })

            console.log('VENUE: GET-VENUE-PROFILE', '›››', venueProfile)

            return venueProfile[0]
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    /* SET */

    /* UPDATE */
}

export default new Venue()
