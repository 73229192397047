export default {
    apiKey: 'AIzaSyD3wZnmjdvjaJ5vMgX6BvKkLfuTty0o7rw',
    databaseURL: 'https://indvstry-io.firebaseio.com',
    storageBucket: 'indvstry-io.appspot.com',
    authDomain: 'indvstry-io.firebaseapp.com',
    messagingSenderId: '476333196534',
    projectId: 'indvstry-io',
    appId: '1:476333196534:web:afdc95a436a624822598d7',
    measurementId: 'G-94537EDR6D',
}
