import React from 'react'
import { Alert } from 'react-native'
import User from '../api/User'
import getUrlParam from '../utils/getUrlParam'
import uploadVideo from '../utils/uploadVideo'
import uploadPhoto from '../utils/uploadPhoto'

import * as firebase from 'firebase'
import 'firebase/firestore'
import '../firebase.config'
import * as VideoThumbnails from 'expo-video-thumbnails'

export const UserProfileContext = React.createContext()
export const UserProfileConsumer = UserProfileContext.Consumer

export class UserProfileProvider extends React.Component {
    state = {
        /* ACCESS TOKENS */
        tokenExists: false,
        /* USER ACCOUNT EXISTS */
        userExists: '',

        /* REQUEST INFO FORM */
        requestFormValid: false,

        /* PRIVACY SETTINGS */
        visibility: {},

        /* COMPONENT STATE MANAGEMENT */
        loading: false,
        textInputFocused: false,

        /* USER PROFILE */
        fullName: '',
        fullNameValid: false,
        fullNameCurrent: '',

        email: '',
        emailVerified: false,
        emailNew: '',
        emailNewValid: false,
        emailValid: false,

        password: '',
        passwordNew: '',
        passwordConfirm: '',
        passwordValid: false,
        passwordConfirmValid: false,
        passwordFormValid: false,
        secureEntry: false,
    }

    /**
     *
     *  SPECIAL FUNCTION:
     *
     **/

    _uploadPhotoAsync = async uri => {
        // const uuidv4 = require('uuid/v4')
        // const uploadUri = `${STORY_COLLECTION}/${this.uid}/${uuidv4()}.jpg`

        const uploadUri = `${'videos'}/RPReplay_Final1568171159.jpg`

        try {
            const remoteImageUri = await uploadPhoto(uri, uploadUri)
            return remoteImageUri
        } catch (e) {
            console.warn(e)
        }
    }

    _generateThumbnail = async localVideo => {
        try {
            const { uri } = await VideoThumbnails.getThumbnailAsync(
                localVideo,
                {
                    time: 1000,
                    compress: 0.5,
                }
            )

            const remoteImageUri = await this._uploadPhotoAsync(uri)

            return remoteImageUri
        } catch (e) {
            console.warn(e)
        }
    }

    _uploadVideoAsync = async uri => {
        const uploadUri = `${'videos'}/RPReplay-Final1568171159.mp4`

        try {
            const remoteVideoUri = await uploadVideo(uri, uploadUri)
            return remoteVideoUri
        } catch (e) {
            console.warn(e)
        }
    }

    // _uploadVideoToFirestore = async () => {
    //     console.log('Special function in action')
    //     const videoRef = await firebase.firestore().collection('videos')
    //     //const localVideo = require('')

    //     try {
    //         const remoteVideoUri = await this._uploadVideoAsync(localVideo)
    //         //const remoteImageUri = await this._generateThumbnail(localVideo)

    //         videoRef.add({
    //             video: remoteVideoUri,
    //             //videoThumbnail: remoteImageUri,
    //         })
    //     } catch ({ message }) {
    //         console.log(
    //             '***************** FIREBASE MESSAGE *****************',
    //             message,
    //             '***************** FIREBASE END *****************'
    //         )
    //     }
    // }

    // _uploadVideoToFirestore = async () => {
    //     console.log('Special function in action')
    //     const videoRef = await firebase.firestore().collection('videos')
    //     const localVideo = require('../assets/videos/RPReplay_Final1568171159.mp4')

    //     try {
    //         //const remoteVideoUri = await this._uploadVideoAsync(localVideo)
    //         //const remoteImageUri = await this._generateThumbnail(localVideo)

    //         videoRef.add({
    //             video: localVideo,
    //         })
    //     } catch ({ message }) {
    //         console.log(
    //             '***************** FIREBASE MESSAGE *****************',
    //             message,
    //             '***************** FIREBASE END *****************'
    //         )
    //     }
    // }

    /* STANDARD FUNCTIONS */

    setAuthInitialState = async () => {
        await this.setState({
            userExists: false,
            loading: false,
            secureEntry: false,

            /* FORM */
            fullName: '',
            fullNameValid: false,
            fullNameCurrent: '',

            email: '',
            emailValid: false,

            password: '',
            passwordValid: false,
            passwordFormValid: false,
        })
    }

    resetUserForms = async () => {
        await this.setState({
            textInputFocused: false,

            /* FORM */

            fullName: '',
            fullNameValid: false,

            emailNew: '',
            emailNewValid: false,

            password: '',
            passwordValid: false,
            passwordNew: '',
            passwordConfirm: '',
            passwordConfirmValid: false,
            passwordFormValid: false,
            secureEntry: false,
        })
    }

    initializeUrlParams = async () => {
        // const tokenExists = await getUrlParam('token')
        // if (tokenExists) {
        //     this.setState({ tokenExists: true })
        // }

        // const fullName = await getUrlParam('fullName')
        // if (fullName) {
        //     this.setState({ fullName, fullNameValid: true })
        // }

        const email = await getUrlParam('email')
        if (email) {
            this.setState({ email, emailValid: true })
        }

        const password = await getUrlParam('passcode')
        if (password) {
            this.setState({
                password,
                passwordValid: true,
                passwordFormValid: true,
            })
        }
    }

    initializeUserAccount = async () => {
        const {
            uid,
            email,
            emailVerified,
            //fullName,
            //fullNameCurrent,
        } = await User._getUserAccount()

        this.setState({
            userExists: uid,
            email,
            emailVerified,
            //fullName,
            //fullNameCurrent,
        })

        // const { visibility } = await User._getUserProfile()

        // this.setState({
        //     visibility,
        // })

        //await this.initializeUrlParams()
    }

    /* LOG-IN & FORGOT PASSWORD */
    onClearUrlToken = async () => {
        await User._clearUrlToken()
    }

    onSubmitRequestForm = async placeId => {
        this.setState({ loading: true })

        await User._createRequestEntry({
            fullName: this.state.fullName,
            email: this.state.emailNew,
            placeId: placeId,
        })

        this.resetUserForms()
        this.setState({ loading: false })
    }

    // onSignUpEmailPassword = async () => {
    //     this.setState({ loading: true })

    //     await User._signInWithTokenEmailAndPassword(
    //         this.state.email,
    //         this.state.password,
    //         getUrlParam('token')
    //     )

    //     this.setState({ loading: false })
    // }

    onLoginEmailPassword = async () => {
        await this.setState({ loading: true })

        await User._signInWithEmailAndPassword(
            this.state.email,
            this.state.password
        )

        await this.setState({ loading: false })
    }

    onLoginWithEmailLink = async () => {
        this.setState({ loading: true })

        await User._sendSignInLinkToEmail(
            this.state.email
            //this.state.password,
            //getUrlParam('token')
        )

        this.setState({ loading: false })
    }

    onPasswordResetPress = async () => {
        this.setState({ loading: true })

        await User._passwordReset(this.state.email)
        await this.setState({ email: '', emailValid: false })
        this.setState({ loading: false })
    }

    /**
     *
     * SIGN-UP FUNCTION
     *
     **/

    createUserAccount = async () => {
        this.setState({ loading: true })

        await User._createUserWithEmailAndPassword({
            email: this.state.email,
            password: this.state.password,
            fullName: this.state.fullName,
            receiveMarketingEmail: this.state.receiveMarketingEmail,
        })
        this.setState({ loading: false })
    }

    saveUsernameUpdate = async () => {
        this.setState({ loading: true })

        await User._usernameUpdate({
            username: this.state.username,
        })

        this.setState({ loading: false })
    }

    /* SET COMPONENT STATE */
    setLoading = loading => {
        this.setState({ loading: loading })
    }

    /* SET OPT-IN/OUT OPTIONS  */
    setTokenExists = () => {
        {
            this.state.tokenExists
                ? this.setState({
                      tokenExists: false,
                  })
                : this.setState({
                      tokenExists: true,
                  })
        }
    }

    requestFormValid = () => {
        const requestCreator = this.state.requestCreator
        const requestExplorer = this.state.requestExplorer
        const requestInvestor = this.state.requestInvestor

        if (
            requestCreator === true ||
            requestExplorer === true ||
            requestInvestor === true
        ) {
            this.setState({ requestFormValid: true })
        } else {
            this.setState({ requestFormValid: false })
        }
    }

    setTextInputFocused = () => {
        {
            this.state.textInputFocused
                ? this.setState({
                      textInputFocused: false,
                  })
                : this.setState({
                      textInputFocused: true,
                  })
        }
    }

    setSecureEntry = () => {
        {
            this.state.secureEntry
                ? this.setState({
                      secureEntry: false,
                  })
                : this.setState({
                      secureEntry: true,
                  })
        }
    }

    setFromHome = fromHome => {
        this.setState({ fromHome: fromHome })
    }

    /* EMAIL */
    setEmail = email => {
        this.setState({ email: email })
    }

    setEmailNew = emailNew => {
        this.setState({ emailNew: emailNew })
    }

    setEmailVerified = () => {
        this.setState({ emailVerified: true })
    }

    /* PASSWORD */
    setPassword = password => {
        this.setState({ password: password })
    }

    setPasswordNew = passwordNew => {
        this.setState({ passwordNew: passwordNew })
    }

    setPasswordConfirm = passwordConfirm => {
        this.setState({ passwordConfirm: passwordConfirm })
    }

    /* FULL NAME */
    setFullName = fullName => {
        this.setState({ fullName: fullName })
    }

    setFullNameNew = fullNameNew => {
        this.setState({ fullNameNew: fullNameNew })
    }

    /* SIGN-UP FLOW */
    validatePassword = () => {
        const reg = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,24}$/
        if (
            this.state.password.length < 8 ||
            reg.test(this.state.password) === false
        ) {
            this.setState({ passwordValid: false })
            //  console.log('Password format is wrong')
        } else {
            this.setState({ passwordValid: true })
            //  console.log('Password format is correct')
        }
    }

    /* USER AUTHENTICATION ACCOUNT */

    validatePasswordNew = () => {
        const reg = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,24}$/
        if (
            this.state.passwordNew.length < 8 ||
            reg.test(this.state.passwordNew) === false
        ) {
            this.setState({ passwordValid: false })
            //  console.log('Password format is wrong')
        } else if (this.state.passwordNew !== this.state.password) {
            this.setState({ passwordValid: true })
            //  console.log('Password format is correct')
        } else {
            Alert.alert('You can not use a previous password.')
            window.alert('You can not use a previous password.')
            //  console.log('Password confirm SHOULD NOT MATCH password new.')
        }
    }

    validatePasswordConfirm = () => {
        const reg = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,24}$/
        if (
            this.state.passwordConfirm.length < 8 ||
            reg.test(this.state.passwordConfirm) === false
        ) {
            //  console.log('Password confirm format is wrong')
            this.setState({ passwordConfirmValid: false })
        } else if (this.state.passwordConfirm === this.state.passwordNew) {
            this.setState({ passwordConfirmValid: true })
            //  console.log('Password confirm format is correct')
        } else {
            Alert.alert('You did not retype the new password correctly.')
            //  console.log('Password confirm does NOT match password new.')
        }
    }

    validatePasswordForm = () => {
        const passwordNew = this.state.passwordNew
        const passwordConfirm = this.state.passwordConfirm
        const passwordValid = this.state.passwordValid
        const passwordConfirmValid = this.state.passwordConfirmValid

        if (passwordValid === true && passwordConfirmValid === true) {
            this.setState({ passwordFormValid: true })
        } else {
            this.setState({ passwordFormValid: false })
        }
    }

    // Changes user's password
    changePassword = () => {
        User._reauthenticate(this.state.password)
            .then(() => {
                //const user = firebase.auth().currentUser
                User._passwordUpdate(this.state.passwordNew)
                    .then(() => {
                        Alert.alert('Your password was changed')
                    })
                    .catch(error => {
                        Alert.alert(error.message)
                        //  console.log(error.message)
                    })
            })
            .catch(error => {
                //  console.log(error.message)
            })
    }

    onPasswordResetPress = async () => {
        this.setState({ loading: true })

        await User._passwordReset(this.state.email)

        await this.setState({ email: '', emailValid: false })
        this.setState({ loading: false })
    }

    /* EMAIL */
    validateEmail = text => {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
        if (
            this.state.email.length <= 5 ||
            (reg.test(this.state.email) === false && reg.test(text) === false)
        ) {
            this.setState({
                emailValid: false,
            })
            //  console.log('Email format is wrong')
            //return false
        } else {
            this.setState({
                emailValid: true,
            })
            //  console.log('Email format is correct')
            //return true
        }
    }

    validateEmailNew = () => {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
        if (
            this.state.emailNew.length <= 5 ||
            reg.test(this.state.emailNew) === false
        ) {
            this.setState({
                emailNewValid: false,
            })
            //  console.log('Email format is wrong')
            //return false
        } else {
            this.setState({
                emailNewValid: true,
            })
            //  console.log('Email format is correct')
            //return true
        }
    }

    // Changes user's email
    changeEmail = () => {
        User._reauthenticate(this.state.password)
            .then(() => {
                User._emailUpdate(this.state.emailNew)
                    .then(() => {
                        Alert.alert('Your email was changed.')
                    })
                    .catch(error => {
                        Alert.alert(error.message)
                        //  console.log(error.message)
                    })
            })
            .catch(error => {
                //Alert.alert(error.message)
                //  console.log(error.message)
            })
    }

    resendEmailVerification = () => {
        User._sendEmailVerification()
    }

    validateFullName = text => {
        const reg = /^([a-z\u00C0-\u02AB'´`]{1,}\.?\s?)([a-z\u00C0-\u02AB'´`]?\.?\s?)+$/i

        if (
            !this.state.fullName.length ||
            (reg.test(this.state.fullName) === false &&
                reg.test(text) === false)
        ) {
            this.setState({
                fullNameValid: false,
            })
            //  console.log('Please input your name')
            //return false
        } else {
            this.setState({
                fullNameValid: true,
            })
            //  console.log('Name format is correct')
            //return true
        }
    }

    userSignOut = async () => {
        await User._signOut()
        this.setAuthInitialState()
    }

    render() {
        return (
            <UserProfileContext.Provider
                value={{
                    /* Special functions */

                    uploadVideoToFirestore: this._uploadVideoToFirestore,
                    /**
                     *
                     *  PRIMARY ACTIONS
                     *
                     **/

                    setTextInputFocused: this.setTextInputFocused,
                    textInputFocused: this.state.textInputFocused,
                    setTokenExists: this.setTokenExists,
                    onClearUrlToken: this.onClearUrlToken,
                    initializeUrlParams: this.initializeUrlParams,
                    onLoginWithEmailLink: this.onLoginWithEmailLink,

                    onSignUpEmailPassword: this.onSignUpEmailPassword,

                    /* REQUEST FORMS */
                    requestFormValid: this.state.requestFormValid,
                    onSubmitRequestForm: this.onSubmitRequestForm,

                    /* AUTH */

                    tokenExists: this.state.tokenExists,
                    checkAuthStateChanged: this.checkAuthStateChanged,
                    userExists: this.state.userExists,
                    userSignOut: this.userSignOut,
                    setAuthInitialState: this.setAuthInitialState,
                    onLoginEmailPassword: this.onLoginEmailPassword,
                    onPasswordResetPress: this.onPasswordResetPress,
                    createUserAccount: this.createUserAccount,
                    createUserProfile: this.createUserProfile,

                    /* USER ACCOUNT & PROFILE */
                    initializeUserAccount: this.initializeUserAccount,
                    setInitialState: this.setInitialState,
                    setCurrentUser: this.setCurrentUser,
                    saveUserProfileUpdates: this.saveUserProfileUpdates,
                    saveUserProfileVenueActive: this.saveUserProfileVenueActive,
                    saveUserProfileVenueActive: this.saveUserProfileVenueActive,
                    changeEmail: this.changeEmail,
                    resendEmailVerification: this.resendEmailVerification,
                    changePassword: this.changePassword,

                    /**
                     *
                     *  FORM STATE MANAGEMENT
                     *
                     **/

                    resetUserForms: this.resetUserForms,

                    loading: this.state.loading,
                    setLoading: this.setLoading,

                    /* EMAIL */
                    email: this.state.email,
                    setEmail: this.setEmail,
                    emailValid: this.state.emailValid,

                    emailVerified: this.state.emailVerified,
                    setEmailVerified: this.setEmailVerified,

                    emailNew: this.state.emailNew,
                    setEmailNew: this.setEmailNew,

                    emailNewValid: this.state.emailNewValid,
                    validateEmail: this.validateEmail,
                    validateEmailNew: this.validateEmailNew,

                    /* PASSWORD */
                    password: this.state.password,
                    setPassword: this.setPassword,

                    passwordNew: this.state.passwordNew,
                    setPasswordNew: this.setPasswordNew,

                    passwordConfirm: this.state.passwordConfirm,
                    setPasswordConfirm: this.setPasswordConfirm,

                    passwordValid: this.state.passwordValid,
                    //setPasswordValid: this.setPasswordValid,
                    secureEntry: this.state.secureEntry,
                    setSecureEntry: this.setSecureEntry,

                    passwordConfirmValid: this.state.passwordConfirmValid,
                    //setPasswordConfirmValid: this.setPasswordConfirmValid,

                    passwordFormValid: this.state.passwordFormValid,
                    //setpasswordFormValid: this.setPasswordFormValid,

                    validatePassword: this.validatePassword,
                    validatePasswordNew: this.validatePasswordNew,
                    validatePasswordConfirm: this.validatePasswordConfirm,
                    validatePasswordForm: this.validatePasswordForm,

                    /* FULL NAME */
                    fullName: this.state.fullName,
                    setFullName: this.setFullName,
                    fullNameValid: this.state.fullNameValid,
                    validateFullName: this.validateFullName,
                    fullNameCurrent: this.state.fullNameCurrent,

                    fullNameNew: this.state.fullNameNew,
                    setFullNameNew: this.setFullNameNew,

                    visibility: this.state.visibility,
                }}
            >
                {this.props.children}
            </UserProfileContext.Provider>
        )
    }
}
